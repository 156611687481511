import { gql } from "graphql-request";

export default gql`
  query GetCustomersPickList {
    customers(
      args: {
        pagination: { take: 10000000, skip: 0 }
      }
    ) {
        data {
            id
            firstName
            lastName
            email
            }
        metadata {
            pagination {
                total
                take
                skip
            }
        }
    }
  }
`;
