// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  PlusOutlined,
  LoadingOutlined,
  MehOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
// UI KIT

// Globals

// Helpers
import { useListViewParams } from "utils/useListViewParams";
import { useListViewState } from "utils/useListViewState";
import { useListViewAPI } from "utils/useListViewAPI";

// Components
import ListView from "components/listView/ListView";
import ListViewRow from "components/listView/ListViewRow";
import ListViewCol from "components/listView/ListViewCol";
import ListViewHeader from "components/listView/ListViewHeader";
import ListViewAddButton from "components/listView/ListViewAddButton";
import ListViewContainer from "components/listView/ListViewContainer";

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import HomeSliderImagesQuery from "graphql/query/homeslider/list";

// Styles

// Ad-Hoc Components

/**
 * @name HomeSliderImages
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const HomeSliderImages = ({ setPageTitle }) => {
  // Theme & Style Hooks
  const history = useHistory();
  // Global State Hooks

  // State Hooks
  const {
    page,
    perPage,
    search,
    filter,
    nextPage,
    prevPage,
    handlePerPageChange,
    handleSearchChange,
    handleStatusChange,
  } = useListViewState({
    filterDefault: { status: "ALL" },
  });

  // Effect Hooks
  useEffect(() => setPageTitle("Home Slider Images"), []);

  // Other Hooks

  // Event Handlers
  const goToAdd = () => history.push("/portal/homeslider/new");

  // Other
  const { status, data, error, isFetching, refetch } = useListViewAPI(
    "homeSliderImages",
    {
      perPage,
      page,
      search,
      filter,
      query: HomeSliderImagesQuery,
      queryFilterEvaluation: (filter) => ({
        isActive:
          filter.status === "Active"
            ? "true"
            : filter.status === "Inactive"
            ? "false"
            : null,
      }),
    }
  );

  const viewData = data?.data;
  const viewMetadata = data?.metadata;

  const {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
  } = useListViewParams(page, perPage, viewMetadata, viewData?.length);

  const headers = [{ text: "Image" }, { text: "Order" }, { text: "Status" }];

  const listViewParams = {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
    headers,
    prevPage,
    nextPage,
  };

  const RowComponent = ({ id, order, image, isActive }) => {
    return (
      <ListViewRow>
        <ListViewCol>
          <div className="flex items-center">
            <div className="flex-shrink-0 w-40 ">
              <img
                className="w-full h-full rounded object-cover"
                src={image}
                alt=""
              />
            </div>
          </div>
        </ListViewCol>

        <ListViewCol>
          <div className="">
            <p className="text-gray-900 whitespace-no-wrap">
              <Link
                to={`/portal/homeslider/${id}`}
                className="text-blue-500 underline"
              >
                List Order {order}
              </Link>
            </p>
          </div>
        </ListViewCol>

        <ListViewCol>
          <span
            className={
              "relative inline-block px-3 py-1 font-semibold leading-tight " +
              (isActive ? "text-green-900" : "text-red-900")
            }
          >
            <span
              aria-hidden
              className={
                "absolute inset-0 opacity-50 rounded-full " +
                (isActive ? "bg-green-200" : "bg-red-200")
              }
            ></span>
            <span className="relative">
              {isActive ? "Active" : "Inactive"}
              {/* <select
                className="bg-transparent font-semibold"
                value=
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select> */}
            </span>
          </span>
        </ListViewCol>
      </ListViewRow>
    );
  };

  // Component Render
  return (
    <ListViewContainer
      {...{
        isSuccess: status === "success",
        isLoading: status === "loading",
        isError: status === "error",
        refetch,
        header: (
          <>
            <ListViewAddButton onClick={goToAdd}>Add Slider Image</ListViewAddButton>

            <ListViewHeader
              {...{
                perPage,
                handlePerPageChange,
                search,
                handleSearchChange,
                filters: [
                  {
                    state: filter.status,
                    handleStateChange: handleStatusChange,
                    values: {
                      ALL: "All Statuses",
                      Active: "Active",
                      Inactive: "Inactive",
                    },
                  },
                ],
              }}
              hideSearch
            />
          </>
        ),
      }}
    >
      <ListView {...listViewParams}>
        {viewData?.map(
          ({ id, order, isActive, media: { path: image } }) => (
            <RowComponent {...{ id, order, isActive, image }} />
          )
        )}
      </ListView>
    </ListViewContainer>
  );
};

HomeSliderImages.propTypes = {
  /**
   *
   */
};

export default HomeSliderImages;
