import { gql } from "graphql-request";

export default gql`
  mutation updateProduct(
    $id: String!
    $productMeta: ProductMatrixMetaInput!
    $productSpecs: [ProductMatrixSpecsInput]!
    $productVariations: [ProductMatrixVariationInput]!
    $categoryId: String!
    $brandId: String!   
  ) {
    updateProductMatrix(
      productId: $id
      input: {
        productMeta: $productMeta
        productSpecs: $productSpecs
        productVariations: $productVariations
        categoryId: $categoryId
        brandId: $brandId
      }
    ) {
      productMeta {
        id
        nameEn
        nameAr
        descriptionEn
        descriptionAr
      }
      productSpecs {
        id
        nameEn
        nameAr
        valueEn
        valueAr
      }
      category {
        id
        nameAr
        nameEn
      }
      brand {
        id
        nameAr
        nameEn
        media {
          id
          name
          path
        }
      }
      productVariations {
        id
        media {
          id
          name
          path
        }
        price
        offerPrice
        isSearchable
        isActive
        stock
        isFeatured
        variations {
          nameEn
          nameAr
          valueEn
          valueAr
        }
      }
    }
  }
`;
