import getStringFromBoolean from "utils/getStringFromBoolean";
import * as uuid from "uuid";

const transformVariants = (productVariations) => {
  let colors = [];
  let sizes = [];
  const variants = productVariations.map(
    (
      {
        id,
        media,
        isFeatured,
        isSearchable,
        price,
        offerPrice,
        stock,
        isActive,
        variations,
      },
      index
    ) => {
      variations.forEach((variation, index) => {
        if (variation.nameEn === "Color") {
          const colorIndex = colors.findIndex(
            (item) => item.valueEn === variation.valueEn
          );
          if (colorIndex < 0) {
            const newColorId = uuid.v4();
            const color = {
              id: newColorId,
              valueEn: variation.valueEn,
              valueAr: variation.valueAr,
            };
            colors.push(color);
            variation.id = newColorId;
          } else {
            variation.id = colors[colorIndex].id;
          }
        }
        if (variation.nameEn === "Size") {
          const sizeIndex = sizes.findIndex(
            (item) => item.valueEn === variation.valueEn
          );
          if (sizeIndex < 0) {
            const newSizeId = uuid.v4();
            const size = {
              id: newSizeId,
              valueEn: variation.valueEn,
              valueAr: variation.valueAr,
            };
            sizes.push(size);
            variation.id = newSizeId;
          } else {
            variation.id = sizes[sizeIndex].id;
          }
        }
      });

      console.log(variations);

      const color = variations.find((item) => item.nameEn === "Color");
      const size = variations.find((item) => item.nameEn === "Size");

      return {
        id,
        isFeatured: getStringFromBoolean(isFeatured, ["Yes", "No"]),
        isSearchable: getStringFromBoolean(isSearchable, ["Yes", "No"]),
        status: getStringFromBoolean(isActive, ["Active", "Inactive"]),
        media: media,
        price: price,
        offerPrice: offerPrice,
        stock: stock,
        color: color.id,
        size: size.id,
      };
    }
  );

  return { colors, sizes, variants };
};

export default transformVariants;
