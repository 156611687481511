import { gql } from "graphql-request";

export default gql`
  mutation addArea(
    $name: String!
    $countryId: String!
  ) {
    addArea(
      input: { name: $name,  countryId: $countryId, isActive: true }
    ) {
      id
      name
      country {
        id
        name
      }
    }
  }
`;
