import { gql } from "graphql-request";

export default gql`
  query SingleSocial($id: String!) {
    social(socialId: $id) {
      id
      type
      value
    }
  }
`;
