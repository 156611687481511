import { gql } from "graphql-request";

export default gql`
  query SingleRole($id: String!) {
    role(input : {
        id: $id
    }) {
        id
        name
        permissions {
            id
            model
            operation
        }
    }
  }
`;
