import { gql } from "graphql-request";

export default gql`
  query UsersList(
    $take: Int!
    $skip: Int!
    $search: String
    $isActive: String
  ) {
    users(
      args: {
        pagination: { take: $take, skip: $skip }
        search: { name: $search }
        sort: { name: 1 }
        filter: { isActive: $isActive }
      }
    ) {
      data {
        id
        name
        email
        isActive
        role {
          id
          name
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        search
        filter
        sort
      }
    }
  }
`;
