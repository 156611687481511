// React
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  PlusOutlined,
  LoadingOutlined,
  MehOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
// UI KIT

// Globals

// Helpers
import { useListViewParams } from "utils/useListViewParams";
import { useListViewState } from "utils/useListViewState";
import { useListViewAPI } from "utils/useListViewAPI";

// Components
import ListView from "components/listView/ListView";
import ListViewRow from "components/listView/ListViewRow";
import ListViewCol from "components/listView/ListViewCol";
import ListViewHeader from "components/listView/ListViewHeader";
import ListViewAddButton from "components/listView/ListViewAddButton";
import ListViewContainer from "components/listView/ListViewContainer";
import { usePicklistAPI } from "utils/usePicklistAPI";
// Factories

// Screens

// Assets

// Third Parties

import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import DeliveryFeesListQuery from "graphql/query/deliveryFees/list";
import AreasPickListQuery from "graphql/query/areas/picklist";
// Styles

// Ad-Hoc Components

/**
 * @name DeliveryFees
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const DeliveryFees = ({ setPageTitle }) => {
  // Theme & Style Hooks
  const history = useHistory();
  // Global State Hooks

  // State Hooks
  const {
    page,
    perPage,
    filter,
    nextPage,
    prevPage,
    handlePerPageChange,
    handleAreaChange,
  } = useListViewState({
    filterDefault: { area: "ALL" },
  });
  const { data: AllAreas } = usePicklistAPI("areas", {
    query: AreasPickListQuery,
  });
  const areas = useMemo(() => AllAreas?.data, [AllAreas]);
  // Effect Hooks
  useEffect(() => setPageTitle("Delivery Fees"), []);

  // Other Hooks

  // Event Handlers
  const goToAdd = () => history.push("/portal/deliveryFees/new");

  // Other
  const { status, data, error, isFetching, refetch } = useListViewAPI(
    "deliveryFees",
    {
      perPage,
      page,
      filter,
      query: DeliveryFeesListQuery,
      queryFilterEvaluation: (filter) => {
        return {
          area: filter.area === "ALL" ? null : filter.area,
        };
      },
    }
  );

  const viewData = data?.data;
  const viewMetadata = data?.metadata;

  const {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
  } = useListViewParams(page, perPage, viewMetadata, viewData?.length);

  const headers = [{ text: "Delivery Fee" }, { text: "Area" }];

  const listViewParams = {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
    headers,
    prevPage,
    nextPage,
  };

  const RowComponent = ({ id, fees, areaName = "No Area" }) => {
    return (
      <ListViewRow>
        <ListViewCol>
          <div className="flex items-center">
            <div>
              <p className="text-gray-900 whitespace-no-wrap">{areaName}</p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div>
              <p className="text-gray-900 whitespace-no-wrap">
                <Link
                  to={`/portal/deliveryFees/${id}`}
                  className="text-blue-500 underline"
                >
                  {fees}
                </Link>
              </p>
            </div>
          </div>
        </ListViewCol>
      </ListViewRow>
    );
  };

  // Component Render
  return (
    <ListViewContainer
      {...{
        isSuccess: status === "success",
        isLoading: status === "loading",
        isError: status === "error",
        refetch,
        header: (
          <>
            <ListViewAddButton onClick={goToAdd}>
              Add Delivery Fee
            </ListViewAddButton>

            <ListViewHeader
              {...{
                perPage,
                handlePerPageChange,
                filters: [
                  {
                    state: filter.area,
                    handleStateChange: handleAreaChange,
                    values: areas
                      ? areas.reduce(
                          (a, c) => ({
                            ...a,
                            [c.id]: c.name,
                          }),
                          { 'ALL': "All Areas" }
                        )
                      : {},
                  },
                ],
              }}
              hideSearch
            />
          </>
        ),
      }}
    >
      <ListView {...listViewParams}>
        {viewData?.map(({ id, area, fees }) => (
          <RowComponent {...{ id, areaName: area?.name, fees }} />
        ))}
      </ListView>
    </ListViewContainer>
  );
};

DeliveryFees.propTypes = {
  /**
   *
   */
};

export default DeliveryFees;
