// React
import React, { useEffect, useMemo, useContext } from "react";

import {
  CloseOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";
import Select from "components/form/SelectField";

// Globals
import GraphQLClient from "services/api";

// Helpers
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import { AbilityContext } from "utils/ability";

// Components

import StateBlock from "components/StateBlock";

// Factories

// Screens

// Assets
import SingleAreaQuery from "graphql/query/areas/single";

// Third Parties
import ReactTooltip from "react-tooltip";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { ToastProvider, useToasts } from "react-toast-notifications";

// Styles

// Ad-Hoc Components
import AddAreaMutation from "graphql/mutation/area/add";
import UpdateAreaMutation from "graphql/mutation/area/update";
import DeleteAreaMutation from "graphql/mutation/area/delete";
import CountriesListQuery from "graphql/query/countries/list";
import { usePicklistAPI } from "utils/usePicklistAPI";

/**
 * @name AreaDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const AreaDetails = ({ setPageTitle, setPageActionsMenu }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);
  const isAddForm = () => id === "new";

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {},
  });

  let { data, status, refetch } = useSingleEntityAPI(
    "area",
    {
      id,
      query: SingleAreaQuery,
    },
    {
      onSuccess: ({ data }) => {
        setValue("id", data?.id);
        setValue("name", data.name);
        setValue("country", data.country?.id);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled: !isAddForm(),
    }
  );

  if (isAddForm()) status = "success";
  const { data: countriesList } = usePicklistAPI("countries", {
    query: CountriesListQuery,
  });
  const countries = useMemo(() => countriesList?.data || [], [countriesList]);
  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);

  const { mutate: submitRequest, isLoading: isSubmitting } = useMutation(
    ({ id, name, country }) =>
      GraphQLClient.request(
        isAddForm() ? AddAreaMutation : UpdateAreaMutation,
        {
          id,
          name,
          countryId: country,
        }
      ),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("areas");
        queryClient.invalidateQueries("area");
        if (isAddForm()) {
          addToast("Area added successfully!", {
            appearance: "success",
          });
          history.push("/portal/areas");
        } else {
          addToast("Area updated successfully!", {
            appearance: "success",
          });
        }
      },
    }
  );

  const { mutate: deleteRequest, isLoading: isDeleting } = useMutation(
    ({ id }) =>
      GraphQLClient.request(DeleteAreaMutation, {
        id,
      }),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("areas");
        queryClient.invalidateQueries("area");

        addToast("Area deleted successfully!", {
          appearance: "success",
        });
        history.push("/portal/areas");
      },
    }
  );

  // State Hooks
  useEffect(() => {
    setPageTitle("Area Details");
  }, []);

  useEffect(() => {
    if (currentDataObject?.id) {
      setValue("id", currentDataObject.id);
      setValue("name", currentDataObject.name);
      setValue("country", currentDataObject.country?.id);
    }
  }, [currentDataObject]);

  useEffect(() => {
    if (!isAddForm() && currentDataObject.id) {
      if (ability.can('areaDelete','areas')) {
        setPageActionsMenu([
          {
            text: "Delete Area",
            isDanger: true,
            callback: () => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm("Are you sure to delete. This action can't be undone!")
              ) {
                deleteRequest({ id });
              }
            },
          },
        ]);
      }
    }

    return () => {
      setPageActionsMenu([]);
    };
  }, [id, currentDataObject]);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/customers");
    }
  };

  // Other

  // Component Render
  return (
    <div className="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined className="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
        }}
      >
        <form
          onSubmit={handleSubmit(submitRequest)}
          className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y"
        >
          <input type="hidden" {...register("id")} />
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
            </div>
            <div className="p-4 px-6 flex flex-col">
              <hr className="color-gray-500 my-4" />
              <div className="flex">
                <div className="mb-4 pr-2 flex-col w-1/2">
                  <div className="mb-4">
                    <Input
                      name="name"
                      label="Name"
                      placeholder="Name"
                      {...register("name", {
                        required: "Area Name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      {...register("country", {
                        required: "Country Is Required is required!",
                      })}
                      name="country"
                      label="Country"
                      placeholder="Country"
                      options={
                        countries
                          ? countries.reduce(
                              (a, c) => ({
                                ...a,
                                [c.id]: c.name,
                              }),
                              {}
                            )
                          : {}
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <Button
              text="Save"
              className="w-full"
              color="text-white bg-pink-600 hover:bg-pink-700"
              SuffixIcon={!isSubmitting ? CheckCircleOutlined : LoadingOutlined}
              type="submit"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </StateBlock>
    </div>
  );
};

AreaDetails.propTypes = {
  /**
   *
   */
};

export default AreaDetails;
