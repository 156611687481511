// React
import React from "react";
import PropTypes from "prop-types";

// UI KIT
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
// Globals

// Helpers

// Components
import Button from "components/form/Button";

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name Modal
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Modal = ({
  title,
  ok,
  cancel,
  onOk,
  disableOk = false,
  onCancel,
  children,
}) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div className="absolute top-0 left-0 w-full h-full z-50">
      <div className="flex justify-center h-screen items-center bg-gray-200 antialiased bg-opacity-70 fixed top-0 left-0 w-full">
        <div className="flex flex-col w-11/12 sm:w-5/6 lg:w-3/4 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl">
          <div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
            <p className="font-semibold text-gray-800">{title}</p>
            {cancel && (
              <span className="cursor-pointer">
                <CloseOutlined onClick={onCancel} />
              </span>
            )}
          </div>
          <div className="flex flex-col px-6 py-5 bg-gray-50 max-h-96 overflow-scroll">
            {children}
          </div>
          <div className="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg">
            {cancel && (
              <p
                className="font-semibold text-gray-600 cursor-pointer"
                onClick={onCancel}
              >
                {cancel}
              </p>
            )}
            {ok && (
              <Button
                text={ok}
                color="text-white bg-pink-600 hover:bg-pink-700 px-2"
                onClick={onOk}
                disabled={disableOk}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  /**
   *
   */
};

export default Modal;
