import { gql } from "graphql-request";

export default gql`
  mutation AddHomeBannerImage(
    $mediaId: String!
    $isActive: Boolean!
  ) {
    addHomeBannerImage(
      input: {
        mediaId: $mediaId
        isActive: $isActive
      }
    ) {
      id
      media {
        id
        name
        path
      }
    }
  }
`;
