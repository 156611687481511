import { gql } from "graphql-request";

export default gql`
  query countries {
    countries(args: { pagination: { take: 10000000, skip: 0 } }) {
      data {
        id
        name
        abbr
        areas {
          id
          name
        }
      }
    }
  }
`;
