import { gql } from "graphql-request";

export default gql`
  query checkoutNotes(
    $take: Int!
    $skip: Int!
    $search: String
    $country: String
  ) {
    checkoutNotes(
      args: {
        pagination: { take: $take, skip: $skip }
        search: { noteEn: $search }
        sort: { noteEn: 1 }
        filter: { country: $country }
      }
    ) {
      data {
        id
        noteAr
        noteEn
        country {
          id
          name
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        search
        filter
        sort
      }
    }
  }
`;
