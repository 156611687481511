// React
import React, { useEffect, useMemo } from "react";
// UI KIT

// Globals

// Helpers
import ListView from "components/listView/ListView";
import ListViewRow from "components/listView/ListViewRow";
import ListViewCol from "components/listView/ListViewCol";
import ProductsListQuery from "graphql/query/products/list";
import CategoriesPicklistQuery from "graphql/query/categories/picklist.all";
import BrandsPicklistQuery from "graphql/query/brands/picklist";
// Components

// Factories

// Screens

// Assets

// Third Parties
import { Link, useHistory } from "react-router-dom";
import numeral from "numeral";

import { useListViewState } from "utils/useListViewState";
import { useListViewAPI } from "utils/useListViewAPI";
import { useListViewParams } from "utils/useListViewParams";
import ListViewContainer from "components/listView/ListViewContainer";
import ListViewHeader from "components/listView/ListViewHeader";
import ListViewAddButton from "components/listView/ListViewAddButton";
import { usePicklistAPI } from "utils/usePicklistAPI";

// Styles

// Ad-Hoc Components
const headers = [
  { text: "Product" },
  { text: "BRAND" },
  { text: "CATEGORY" },
  { text: "PRICE" },
  { text: "STOCK" },
  { text: "STATUS" },
];
/**
 * @name Products
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Products = ({ setPageTitle }) => {
  const history = useHistory();
  // Theme & Style Hooks

  const { data: AllCategories } = usePicklistAPI("categories", {
    query: CategoriesPicklistQuery,
  });
  const categories = useMemo(
    () =>
      AllCategories?.data?.flatMap((category) => {
        if (category.subCategories.length > 0) {
          return category.subCategories;
        }
        if (category.isCustomized) {
          return [category];
        }
        return [];
      }),
    [AllCategories]
  );
  console.log(`categories`, categories);
  const { data: AllBrands } = usePicklistAPI("brands", {
    query: BrandsPicklistQuery,
  });
  const brands = useMemo(() => AllBrands?.data, [AllBrands]);
  console.log(`brands`, brands);
  const {
    page,
    perPage,
    search,
    filter,
    nextPage,
    prevPage,
    handlePerPageChange,
    handleSearchChange,
    handleCategoryChange,
    handleBrandChange,
  } = useListViewState({
    filterDefault: { category: "all", brand: "all" },
  });
  // Global State Hooks
  const { status, data, error, isFetching, refetch } = useListViewAPI(
    "products",
    {
      perPage,
      page,
      search,
      filter,
      query: ProductsListQuery,
      queryFilterEvaluation: (filter) => {
        return {
          category:
            filter.category === "all" || filter.category === null
              ? null
              : filter.category,
          brand:
            filter.brand === "all" || filter.brand === null
              ? null
              : filter.brand,
        };
      },
    }
  );
  console.log(`filter`, filter);
  // State Hooks
  useEffect(() => setPageTitle("Products"), []);
  const viewData = data?.data;
  console.log(`viewData`, viewData);
  const viewMetadata = data?.metadata;
  const {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
  } = useListViewParams(page, perPage, viewMetadata, viewData?.length);

  const listViewParams = {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
    headers,
    prevPage,
    nextPage,
  };
  // Effect Hooks

  // Event Handlers

  const RowComponent = ({
    id,
    nameEn,
    nameAr,
    image,
    isActive,
    stock,
    price,
    brandName,
    categoryName,
  }) => {
    return (
      <ListViewRow>
        <ListViewCol>
          <div className="flex items-center">
            <div className="flex-shrink-0 w-40 ">
              <img
                className="w-full h-full rounded object-cover"
                src={image}
                alt=""
              />
            </div>
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">
                <Link
                  to={`/portal/products/${id}`}
                  className="text-blue-500 underline"
                >
                  {nameEn}<br />({nameAr})
                </Link>
              </p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">{brandName}</p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">{categoryName}</p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">{price}</p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <span
            className={
              "relative inline-block px-3 py-1 font-semibold leading-tight " +
              (stock > 0 ? "text-green-900" : "text-red-900")
            }
          >
            <span
              aria-hidden
              className={
                "absolute inset-0 opacity-50 rounded-full " +
                (stock > 0 ? "bg-green-200" : "bg-red-200")
              }
            ></span>
            <span className="relative">
              {stock > 0 ? stock : "Out Of Stock"}
            </span>
          </span>
        </ListViewCol>
        <ListViewCol>
          <span
            className={
              "relative inline-block px-3 py-1 font-semibold leading-tight " +
              (isActive ? "text-green-900" : "text-red-900")
            }
          >
            <span
              aria-hidden
              className={
                "absolute inset-0 opacity-50 rounded-full " +
                (isActive ? "bg-green-200" : "bg-red-200")
              }
            ></span>
            <span className="relative">{isActive ? "Active" : "Inactive"}</span>
          </span>
        </ListViewCol>
      </ListViewRow>
    );
  };
  // Other

  const goToAdd = () => history.push("/portal/products/new");
  return (
    <ListViewContainer
      {...{
        isSuccess: status === "success",
        isLoading: status === "loading",
        isError: status === "error",
        refetch,
        header: (
          <>
            <ListViewAddButton onClick={goToAdd}>Add Product</ListViewAddButton>

            <ListViewHeader
              {...{
                perPage,
                handlePerPageChange,
                search,
                handleSearchChange,
                filters: [
                  {
                    state: filter.category,
                    handleStateChange: handleCategoryChange,
                    values: categories
                      ? categories.reduce(
                          (a, c) => ({
                            ...a,
                            [c.id]: c.nameEn,
                          }),
                          { all: "All Categories" }
                        )
                      : {},
                  },
                  {
                    state: filter.brand,
                    handleStateChange: handleBrandChange,
                    values: brands
                      ? brands.reduce(
                          (a, c) => ({
                            ...a,
                            [c.id]: c.nameEn,
                          }),
                          { all: "All Brands" }
                        )
                      : {},
                  },
                ],
              }}
            />
          </>
        ),
      }}
    >
      <ListView {...listViewParams}>
        {viewData?.map(
          ({
            id,
            productMeta: { nameEn, nameAr },
            media,
            isActive,
            stock,
            price,
            brand: { nameEn: brandName },
            category: { nameEn: categoryName },
          }) => (
            <RowComponent
              {...{
                id,
                nameEn,
                nameAr,
                isActive,
                stock,
                price: `${numeral(price).format("$ 0.000")}`,
                categoryName,
                brandName,
                image: media[0]?.path,
              }}
            />
          )
        )}
      </ListView>
    </ListViewContainer>
  );
};

Products.propTypes = {
  /**
   *
   */
};

export default Products;
