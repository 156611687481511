// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// UI KIT

// Globals
import ListView from "components/listView/ListView";
import ListViewRow from "components/listView/ListViewRow";
import ListViewCol from "components/listView/ListViewCol";
import ListViewHeader from "components/listView/ListViewHeader";
import ListViewAddButton from "components/listView/ListViewAddButton";
import ListViewContainer from "components/listView/ListViewContainer";

import { useSimpleQueryAPI } from "utils/useSimpleQueryAPI";
import Input from "components/form/InputField";
import Button from "components/form/Button";
import moment from "moment-timezone";
import { useForm } from "react-hook-form";

// Helpers
import QuantitySoldByProductQuery from "graphql/query/reports/quantity.sold.by.product";
import ReportingPeriodForm from "./ReportingPeriodForm";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name QuantitySoldByProduct
 * @summary
 * @product
 * @component
 * @description
 * >
 */
const QuantitySoldByProduct = ({}) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks
  const [dateRange, setDateRange] = useState({
    from: moment().tz("Asia/Kuwait").startOf("month"),
    to: moment().tz("Asia/Kuwait").endOf("month"),
  });

  const { data, status, refetch } = useSimpleQueryAPI(
    "quantitySoldByProduct",
    QuantitySoldByProductQuery,
    {
      from: (dateRange.from / 1000).toString(),
      to: (dateRange.to / 1000).toString(),
    }
  );
  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const headers = [{ text: "Product" }, { text: "Total Sold Products" }];

  const RowComponent = ({ quantitySold, nameEn, nameAr }) => {
    return (
      <ListViewRow>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">
                {nameEn} ({nameAr})
              </p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div>
              <p className="text-gray-900 whitespace-no-wrap">{quantitySold}</p>
            </div>
          </div>
        </ListViewCol>
      </ListViewRow>
    );
  };

  // Component Render
  return (
    <ListViewContainer
      {...{
        isSuccess: status === "success",
        isLoading: status === "loading",
        isError: status === "error",
        refetch,
        header: (
          <ReportingPeriodForm
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        ),
      }}
    >
      <ListView showFooter={false} headers={headers}>
        {data?.map(({ quantitySold, nameEn, nameAr }) => (
          <RowComponent {...{ quantitySold, nameEn, nameAr }} />
        ))}
      </ListView>
    </ListViewContainer>
  );
};

QuantitySoldByProduct.propTypes = {
  /**
   *
   */
};

export default QuantitySoldByProduct;
