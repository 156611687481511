// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  CheckCircleOutlined,
  ApartmentOutlined,
  AppleOutlined,
  HomeOutlined,
  SkinOutlined,
  DisconnectOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";
import Select from "components/form/SelectField";

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import ReactTooltip from "react-tooltip";
import SoldProductsByCategory from "./SoldProductsByCategory";
import SoldProductsByBrand from "./SoldProductsByBrand";
import SoldProductsByArea from "./SoldProductsByArea";
import QuantitySoldByProduct from "./QuantitySoldByProduct";

// Styles

// Ad-Hoc Components

/**
 * @name Reports
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Reports = ({ setPageTitle }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const [report, setReport] = useState(null);
  // State Hooks
  useEffect(() => setPageTitle("Reports"), []);

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div className="flex flex-col justify-center my-6">
      {/* <ReactTooltip /> */}
      {report === null && (
        <>
          <button
            className="flex flex-row w-full my-2 p-6 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y "
            onClick={() => setReport("SoldProductsByCategory")}
          >
            <h2 class="text-lg">
              <ApartmentOutlined /> Sold Products By Category
            </h2>
          </button>

          <button
            className="flex flex-row w-full my-2 p-6 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y "
            onClick={() => setReport("SoldProductsByBrand")}
          >
            <h2 class="text-lg">
              <AppleOutlined /> Sold Products By Brand
            </h2>
          </button>

          <button
            className="flex flex-row w-full my-2 p-6 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y "
            onClick={() => setReport("SoldProductsByArea")}
          >
            <h2 class="text-lg">
              <HomeOutlined /> Sold Products By Area
            </h2>
          </button>

          <button
            className="flex flex-row w-full my-2 p-6 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y "
            onClick={() => setReport("QuantitySoldByProduct")}
          >
            <h2 class="text-lg">
              <SkinOutlined /> Quantity Sold By Product
            </h2>
          </button>

          <button className="flex flex-row w-full my-2 p-6 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y ">
            <h2 class="text-lg">
              <DisconnectOutlined /> Products Out of Stock
            </h2>
          </button>
        </>
      )}

      {report !== null && (
        <button
          onClick={() => setReport(null)}
          data-tip="Close"
          className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
        >
          <CloseOutlined className="p-0" />
        </button>
      )}

      {report === "SoldProductsByCategory" && <SoldProductsByCategory />}
      {report === "SoldProductsByBrand" && <SoldProductsByBrand />}
      {report === "SoldProductsByArea" && <SoldProductsByArea />}
      {report === "QuantitySoldByProduct" && <QuantitySoldByProduct />}
    </div>
  );
};

Reports.propTypes = {
  /**
   *
   */
};

export default Reports;
