import { gql } from "graphql-request";

export default gql`
  mutation DeletePermission($id: String!) {
    deleteRole(roleId: $id) {
        id
        name
        permissions {
            id
            model
            operation
        }
    }
  }
`;
