// React
import React from "react";
import {
  TeamOutlined,
  ContainerOutlined,
  SkinOutlined,
  DollarOutlined,
  AppleOutlined,
  AppstoreOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT

// Globals
import StaticTotalsQuery from "graphql/query/dashboard/static.totals";
import { useSimpleQueryAPI } from "utils/useSimpleQueryAPI";

// Helpers

// Components
import StatisticsCard from "components/StatisticsCard";
// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";

// Styles

// Ad-Hoc Components

/**
 * @name StatisticCards
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const StatisticCards = ({ className }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks
  const { data, isLoading, isError, refetch } = useSimpleQueryAPI(
    "staticTotals",
    StaticTotalsQuery
  );
  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div className={`flex ${className}`}>
      <div className="py-2 w-full">
        <div className="grid grid-cols-6 gap-4">
          <div
            className="col-span-12 sm:col-span-6 md:col-span-3"
            title={data?.totalBrands}
          >
            <StatisticsCard
              title="Brands"
              count={
                isLoading ? (
                  <LoadingOutlined />
                ) : isError ? (
                  <button onClick={refetch}>Retry</button>
                ) : (
                  numeral(data.totalBrands).format("0 a")
                )
              }
              color="bg-blue-100 text-blue-500"
              Icon={AppleOutlined}
            />
          </div>
          <div
            className="col-span-12 sm:col-span-6 md:col-span-3"
            title={data?.totalCategories}
          >
            <StatisticsCard
              title="Categories"
              count={
                isLoading ? (
                  <LoadingOutlined />
                ) : isError ? (
                  <button onClick={refetch}>Retry</button>
                ) : (
                  numeral(data.totalCategories).format("0 a")
                )
              }
              color="bg-red-100 text-red-500"
              Icon={AppstoreOutlined}
            />
          </div>
          <div
            className="col-span-12 sm:col-span-6 md:col-span-3"
            title={data?.totalProducts}
          >
            <StatisticsCard
              title="Products"
              count={
                isLoading ? (
                  <LoadingOutlined />
                ) : isError ? (
                  <button onClick={refetch}>Retry</button>
                ) : (
                  numeral(data.totalProducts).format("0 a")
                )
              }
              color="bg-yellow-100 text-yellow-500"
              Icon={SkinOutlined}
            />
          </div>
          <div
            className="col-span-12 sm:col-span-6 md:col-span-3"
            title={data?.totalDeliveredOrders}
          >
            <StatisticsCard
              title="Delivered Orders"
              count={
                isLoading ? (
                  <LoadingOutlined />
                ) : isError ? (
                  <button>Retry</button>
                ) : (
                  numeral(data.totalDeliveredOrders).format("0 a")
                )
              }
              color="bg-green-100 text-green-500"
              Icon={ContainerOutlined}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

StatisticCards.propTypes = {
  /**
   *
   */
};

export default StatisticCards;
