import { gql } from "graphql-request";

export default gql`
  mutation DeleteHomeSliderImage($id: String!) {
    deleteHomeSliderImage(homeSliderImageId: $id) {
      id
      order
      media {
        id
        name
        path
      }
    }
  }
`;
