import { gql } from "graphql-request";

export default gql`
  query RolesList(
    $take: Int!
    $skip: Int!
  ) {
    roles(
      args: {
        pagination: { take: $take, skip: $skip }
      }
    ) {
      data {
        id
        name
        permissions {
            id
            model
            operation
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
      }
    }
  }
`;
