// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// UI KIT

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets
import Error404SVG from "assets/404.svg";

// Third Parties
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

// Styles

// Ad-Hoc Components

/**
 * @name Error404
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Error404 = ({ setPageTitle }) => {
  const history = useHistory();

  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    typeof setPageTitle === "function" && setPageTitle("Page Not Found");
  }, []);

  // Other Hooks

  // Event Handlers

  // Other
  const goBack = () => history.goBack();

  // Component Render
  return (
    <div className="container mx-auto w-full flex items-center py-2 overflow-hidden relative">
      <div className="flex-1 min-h-full min-w-full rounded-xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left">
        <div className="w-full md:w-1/2">
          <div className="mb-10 lg:mb-20"></div>
          <div className="mb-10 md:mb-20 text-gray-600 font-light">
            <h1 className="font-black uppercase text-3xl lg:text-5xl text-pink-500 mb-10">
              You seem to be lost!
            </h1>
            <p>The page you're looking for isn't available.</p>
            <p>Try searching again or use the Go Back button below.</p>
          </div>
          <div className="mb-20 md:mb-0">
            <button
              className="flex flex-row items-center text-lg font-light outline-none focus:outline-none transform transition-all hover:scale-110 text-pink-500 hover:text-pink-600"
              onClick={goBack}
            >
              <ArrowLeftOutlined className="mr-2" />
              <span>Go Back</span>
            </button>
          </div>
        </div>
        <div className="w-full md:w-1/2 text-center">
          <img src={Error404SVG} />
        </div>
      </div>
    </div>
  );
};
Error404.propTypes = {
  /**
   *
   */
};

export default Error404;
