import GraphQLClient from "services/api";
import { useQuery, useQueryClient } from "react-query";

export const usePicklistAPI = (
  key,
  { query, filter, queryFilterEvaluation = () => ({}) },
  options = {}
) => {
  const fetchData = async ({ queryKey }) => {
    const [_key, { filter }] = queryKey;
    const {
      [key]: { data, metadata },
    } = await GraphQLClient.request(query, {
      ...queryFilterEvaluation(filter),
    });
    return { data, metadata };
  };

  const queryClient = useQueryClient();

  return useQuery([key, { filter }], fetchData, { ...options });
};
