import { gql } from "graphql-request";

export default gql`
  query SingleHomeSliderImage($id: String!) {
    homeSliderImage(homeSliderImageId: $id) {
      id
      order
      isActive
      media {
        id
        path
        name
      }
    }
  }
`;
