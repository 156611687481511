import { gql } from "graphql-request";

export default gql`
  query BrandsList(
    $take: Int!
    $skip: Int!
    $search: String
    $isActive: String
  ) {
    brands(
      args: {
        pagination: { take: $take, skip: $skip }
        search: { nameEn: $search, nameAr: $search }
        sort: { nameEn: 1 }
        filter: { isActive: $isActive }
      }
    ) {
      data {
        id
        nameEn
        nameAr
        isActive
        media {
          id
          name
          path
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        search
        filter
        sort
      }
    }
  }
`;
