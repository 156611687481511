import { gql } from "graphql-request";

export default gql`
  query SingleCategory($id: String!) {
    category(categoryId: $id) {
      id
      nameEn
      nameAr
      isCustomized
      isHot
      isActive
      bulkDiscountPercentage
      category {
        id
      }
      media {
        id
        path
      }
    }
  }
`;
