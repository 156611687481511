export const useListViewParams = (
  page,
  perPage,
  metadata,
  currentDataLength
) => {
  const firstRecordIndex = parseInt(perPage) * (page - 1) + 1;
  const lastRecordIndex = parseInt(perPage) * (page - 1) + currentDataLength;
  const isFirstPage = firstRecordIndex === 1;
  const isLastPage = lastRecordIndex === metadata?.pagination?.total;
  const totalRecords = metadata?.pagination?.total;

  return {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
  };
};
