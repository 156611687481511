import { gql } from "graphql-request";

export default gql`
  query deliveryFees($take: Int!, $skip: Int!, $search: String, $area: String) {
    deliveryFees(
      args: {
        pagination: { take: $take, skip: $skip }
        # to be checked area search
        search: { fees: $search }
        filter: { area: $area }
        sort: null
      }
    ) {
      data {
        id
        fees
        area {
          id
          name
          country {
            id
            name
            areas {
              id
              name
            }
          }
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        search
        filter
        sort
      }
    }
  }
`;
