import { createStore, createHook } from "react-sweet-state";
import GraphQLClient from "services/api";
import JWT from "utils/jwt";

const actions = {
  setAuthToken: (authToken) => ({ setState, getState }) => {
    localStorage.setItem("authToken", authToken);
    setState({
      authToken,
    });
    GraphQLClient.setHeader("Authorization", `Bearer ${authToken}`);
  },
  setRefreshToken: (refreshToken) => ({ setState, getState }) => {
    localStorage.setItem("refreshToken", refreshToken);
    setState({
      refreshToken,
    });
  },
  setTokens: ({ authToken, refreshToken }) => ({ dispatch }) => {
    dispatch(actions.setAuthToken(authToken));
    dispatch(actions.setRefreshToken(refreshToken));
  },
};

const Store = createStore({
  initialState: {
    authToken: (() => {
      const token = localStorage.getItem("authToken");
      GraphQLClient.setHeader("Authorization", `Bearer ${token}`);
      return token;
    })(),
    refreshToken: localStorage.getItem("refreshToken"),
  },
  actions,
  name: "Auth Tokens",
});

export const useAuthTokens = createHook(Store, {
  selector: (state) => ({
    authToken: new JWT(state.authToken),
    refreshToken: new JWT(state.refreshToken),
  }),
});
