// React
import React from "react";

// UI KIT
import {
  HistoryOutlined,
  SendOutlined,
  LikeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
// Globals
import OrderTotalsQuery from "graphql/query/dashboard/order.totals";
import { useSimpleQueryAPI } from "utils/useSimpleQueryAPI";
// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties
import { Line, Pie } from "react-chartjs-2";
import numeral from "numeral";

// Styles

// Ad-Hoc Components

/**
 * @name OrdersChart
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const OrdersChart = () => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks
  const { data, isLoading, isError, refetch } = useSimpleQueryAPI(
    "orderTotals",
    OrderTotalsQuery
  );
  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div className="flex items-center justify-center py-2">
      <div className="rounded shadow-xl overflow-hidden w-full md:flex">
        <div className="flex w-full md:w-1/2 p-2 bg-white text-white items-center">
          <Pie
            data={{
              labels: ["Pending", "On The Way"],
              datasets: [
                {
                  label: null,
                  data: [data?.totalPending, data?.totalOnTheWay],
                  backgroundColor: ["rgb(255, 205, 86)", "rgb(54, 162, 235)"],
                  hoverOffset: 4,
                },
              ],
            }}
            options={{
              legend: {
                display: true,
                position: "right",
              },
            }}
            className="w-full"
          />
        </div>
        <div className="flex w-full md:w-1/2 px-10 py-5 bg-gray-100 text-gray-600 items-center">
          <div className="w-full flex flex-col">
            <h5 className="text-lg font-semibold leading-tight text-gray-800 flex-grow py-2">
              <span>Order Totals:</span>
            </h5>
            <h5
              className="text-base font-semibold leading-tight text-gray-800 flex-grow py-2"
              title={data?.totalPending}
            >
              <span>
                <HistoryOutlined /> Pending Orders:{" "}
                {isLoading ? (
                  <LoadingOutlined />
                ) : isError ? (
                  <button onClick={refetch}>Retry</button>
                ) : (
                  numeral(data.totalPending).format("0 a")
                )}
              </span>
            </h5>
            <h5
              className="text-base font-semibold leading-tight text-gray-800 flex-grow py-2"
              title={data?.totalOnTheWay}
            >
              <span>
                <SendOutlined /> On The Way:{" "}
                {isLoading ? (
                  <LoadingOutlined />
                ) : isError ? (
                  <button onClick={refetch}>Retry</button>
                ) : (
                  numeral(data.totalOnTheWay).format("0 a")
                )}
              </span>
            </h5>
            <h5
              className="text-base font-semibold leading-tight text-gray-800 flex-grow py-2"
              title={data?.totalDelivered}
            >
              <span>
                <LikeOutlined /> Delivered:{" "}
                {isLoading ? (
                  <LoadingOutlined />
                ) : isError ? (
                  <button onClick={refetch}>Retry</button>
                ) : (
                  numeral(data.totalDelivered).format("0 a")
                )}
              </span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

OrdersChart.propTypes = {
  /**
   *
   */
};

export default OrdersChart;
