import { gql } from "graphql-request";

export default gql`
  mutation AddBrand(
    $nameEn: String!
    $nameAr: String!
    $mediaId: String!
    $isActive: Boolean!
  ) {
    addBrand(
      input: {
        nameEn: $nameEn
        nameAr: $nameAr
        mediaId: $mediaId
        isActive: $isActive
      }
    ) {
      id
      nameEn
      nameAr
      media {
        id
        name
        path
      }
    }
  }
`;
