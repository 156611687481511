import { gql } from "graphql-request";

export default gql`
  query ContactUsMessagesList($take: Int!, $skip: Int!, $status: String) {
    contactUsMessages(
      args: {
        pagination: { take: $take, skip: $skip }
        sort: { datetime: -1 }
        filter: { status: $status }
      }
    ) {
      data {
        id
        name
        email
        message
        countryCode
        mobileNumber
        datetime
        status
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        filter
        sort
        other
      }
    }
  }
`;
