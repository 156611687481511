// React
import React from "react";
import {
  LockOutlined,
  LoginOutlined,
  MailOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";

// Globals

// Helpers

// Components

// Factories

// Screens
import Layout from "./PageLayout";

// Assets

// Third Parties
import { useForm } from "react-hook-form";
import {
  useHistory,
} from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import GraphQLClient from "services/api";
import {
  useMutation,
} from "react-query";

import loginMutation from "graphql/mutation/login";

import { useAuthTokens } from "services/tokens/auth";

// Styles

// Ad-Hoc Components

/**
 * @name Login
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Login = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { addToast } = useToasts();

  const [, { setAuthToken, setRefreshToken }] = useAuthTokens();

  const { mutate: loginRequest, isLoading } = useMutation(
    ({ email, password }) =>
      GraphQLClient.request(loginMutation, {
        email,
        password,
      }),
    {
      onError: () => {
        addToast("Invalid Credentials", { appearance: "error" });
      },
      onSuccess: (data) => {
        const { token, refreshToken } = data.loginUser;
        setAuthToken(token);
        setRefreshToken(refreshToken);
        history.replace("/portal");
      },
    }
  );

  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const goToForgetpassword = () => {
    history.push("/auth/forgetpassword");
  };

  // const login = (data) => {
  //   const { email, password } = data;
  //   loginRequest(email, password);
  //   addToast(JSON.stringify(data), { appearance: "error" });
  //   // history.replace("/portal");
  // };

  // Component Render
  return (
    <Layout heading="Login To Your Account">
      <form onSubmit={handleSubmit(loginRequest)}>
        <Input
          name="email"
          type="email"
          label="Email Address"
          placeholder="Email Address"
          className="mb-6"
          PrefixIcon={MailOutlined}
          {...register("email", { required: "Email Address is required!" })}
          errors={errors}
          disabled={isLoading}
        />
        <Input
          name="password"
          type="password"
          label="Password"
          placeholder="Password"
          className="mb-6"
          PrefixIcon={LockOutlined}
          {...register("password", { required: "Password is required!" })}
          errors={errors}
          disabled={isLoading}
        />
        <div className="flex items-center mb-6 -mt-4">
          <Button
            text="Forgot Your Password?"
            color="text-pink-500 hover:text-pink-700"
            basic
            small
            className="ml-auto"
            onClick={goToForgetpassword}
          />
        </div>

        <Button
          text="Login"
          type="submit"
          className="w-full"
          color="text-white bg-pink-600 hover:bg-pink-700"
          SuffixIcon={!isLoading ? LoginOutlined : LoadingOutlined}
          // onClick={handleSubmit(loginRequest)}
          disabled={isLoading}
        />
      </form>
    </Layout>
  );
};

Login.propTypes = {
  /**
   *
   */
};

export default Login;
