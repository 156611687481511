// React
import React, { useEffect } from "react";

// UI KIT

// Globals

// Helpers

// Components
import ListView from "components/listView/ListView";
import ListViewRow from "components/listView/ListViewRow";
import ListViewCol from "components/listView/ListViewCol";
import OrdersListQuery from "graphql/query/orders/list";
// Factories

// Screens

// Assets

// Third Parties
import { BrowserRouter, Link, useRouteMatch } from "react-router-dom";
import moment from 'moment-timezone';

// Styles

// Data

import { useListViewState } from "utils/useListViewState";
import { useListViewAPI } from "utils/useListViewAPI";
import { useListViewParams } from "utils/useListViewParams";
import ListViewContainer from "components/listView/ListViewContainer";
import ListViewHeader from "components/listView/ListViewHeader";

// Ad-Hoc Components
const headers = [
  { text: "Order Number" },
  { text: "Customer" },
  { text: "Order Total" },
  { text: "Order Date" },
  // { text: "Sign Up Date" },
  { text: "Status" },
];

/**
 * @name Orders
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Orders = ({ setPageTitle }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const {
    page,
    perPage,
    search,
    filter,
    nextPage,
    prevPage,
    handlePerPageChange,
    handleSearchChange,
    handleStatusChange,
  } = useListViewState({
    filterDefault: { status: "ALL" },
  });
  // State Hooks
  const { status, data, error, isFetching, refetch } = useListViewAPI(
    "orders",
    {
      perPage,
      page,
      search,
      filter,
      query: OrdersListQuery,
      queryFilterEvaluation: (filter) => ({
        status: filter.status === "ALL" ? null : filter.status,
      }),
    }
  );
  useEffect(() => setPageTitle("Orders"), []);
  const viewData = data?.data;
  console.log(`viewData`, viewData);
  const viewMetadata = data?.metadata;

  const {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
  } = useListViewParams(page, perPage, viewMetadata, viewData?.length);

  const listViewParams = {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
    headers,
    prevPage,
    nextPage,
  };
  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const RowComponent = ({
    id,
    number,
    customer = {},
    status,
    createdAt,
    total,
  }) => {
    let routerMatch = useRouteMatch();

    return (
      <ListViewRow>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">
                <Link
                  to={`/portal/orders/${id}`}
                  className="text-blue-500 underline"
                >
                  {number}
                </Link>
              </p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-nowrap">
                <Link
                  to={`/portal/customers/${customer.id}`}
                  className="text-blue-500 underline"
                >
                  {customer?.firstName} {customer?.lastName}
                </Link>
                <br />
                {customer?.email}
                <br />
                {customer.mobileCountryCode}
                {customer?.mobileNumber}
              </p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <p className="text-gray-900 whitespace-nowrap">{total} KWD</p>
          </div>
        </ListViewCol>
        <ListViewCol>
          <p className="text-gray-900 whitespace-no-wrap">
            {" "}
            {moment(parseInt(createdAt)).tz("Asia/Kuwait").format("DD/MM/YYYY, hh:MM A")}
          </p>
        </ListViewCol>

        <ListViewCol>
          {status === "PENDING" ? (
            <span className="relative inline-block px-3 py-1 font-semibold text-yellow-900 leading-tight">
              <span
                aria-hidden
                className="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"
              ></span>
              <span className="relative">
                Pending Delivery
              </span>
            </span>
          ) : status === "ON_THE_WAY" ? (
            <span className="relative inline-block px-3 py-1 font-semibold text-blue-900 leading-tight">
              <span
                aria-hidden
                className="absolute inset-0 bg-blue-200 opacity-50 rounded-full"
              ></span>
              <span className="relative">
              On The Way
              </span>
            </span>
          ) : (
            <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
              <span
                aria-hidden
                className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
              ></span>
              <span className="relative">
              Delivered
              </span>
            </span>
          )}
        </ListViewCol>
      </ListViewRow>
    );
  };

  // Component Render

  return (
    <ListViewContainer
      {...{
        isSuccess: status === "success",
        isLoading: status === "loading",
        isError: status === "error",
        refetch,
        header: (
          <>
            <ListViewHeader
              {...{
                perPage,
                handlePerPageChange,
                search,
                handleSearchChange,
                filters: [
                  {
                    state: filter.status,
                    handleStateChange: handleStatusChange,
                    values: {
                      ALL: "All Statuses",
                      PENDING: "Pending",
                      ON_THE_WAY: "On The Way",
                      DELIVERED: "Delivered",
                    },
                  },
                ],
              }}
            />
          </>
        ),
      }}
    >
      <ListView {...listViewParams}>
        {viewData?.map(({ id, createdAt, number, status, total, customer }) => (
          <RowComponent
            {...{
              id,
              number,
              status,
              total,
              createdAt,
              customer,
            }}
          />
        ))}
      </ListView>
    </ListViewContainer>
  );
};

Orders.propTypes = {
  /**
   *
   */
};

export default Orders;
