import { gql } from "graphql-request";

export default gql`
  query orderTotals {
    orderTotals {
      totalPending
      totalOnTheWay
      totalDelivered
    }
  }
`;
