import GraphQLClient from "services/api";
import { useQuery, useQueryClient } from "react-query";

export const useListViewAPI = (
  key,
  { perPage, page, search, filter, query, queryFilterEvaluation = () => ({}) }
) => {
  const fetchData = async ({ queryKey }) => {
    const [_key, { perPage, page, search, filter }] = queryKey;
    const skip = perPage * (page - 1);
    const take = perPage;
    const {
      [key]: { data, metadata },
    } = await GraphQLClient.request(query, {
      take: parseInt(take),
      skip: parseInt(skip),
      search,
      ...queryFilterEvaluation(filter),
    });
    return { data, metadata };
  };

  const queryClient = useQueryClient();
  queryClient.prefetchQuery(
    [key, { perPage, page: page + 1, search, filter }],
    fetchData
  );

  return useQuery([key, { perPage, page, search, filter }], fetchData);
};
