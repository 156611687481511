import { gql } from "graphql-request";

export default gql`
  query Media($take: Int!, $skip: Int!, $search: String!) {
    medias(
      args: {
        pagination: { take: $take, skip: $skip }
        sort: { createdAt: -1 }
        search: { name: $search }
      }
    ) {
      data {
        id
        name
        path
        createdAt
      }
      metadata {
        pagination {
          take
          skip
          total
        }
        sort
        filter
        search
      }
    }
  }
`;
