import { gql } from "graphql-request";

export default gql`
  mutation DeleteHomeBannerImage($id: String!) {
    deleteHomeBannerImage(homeBannerImageId: $id) {
      id
      media {
        id
        name
        path
      }
    }
  }
`;
