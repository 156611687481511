// React
import React, { useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";
import Select from "components/form/SelectField";

// Globals
import GraphQLClient from "services/api";

// Helpers
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import { usePicklistAPI } from "utils/usePicklistAPI";
import { AbilityContext } from "utils/ability";

// Components
import Media from "components/form/Media";
import StateBlock from "components/StateBlock";

// Factories

// Screens

// Assets
import SingleRoleQuery from "graphql/query/roles/single";

// Third Parties
import ReactTooltip from "react-tooltip";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { ToastProvider, useToasts } from "react-toast-notifications";

// Styles

// Ad-Hoc Components
import AddRoleMutation from "graphql/mutation/roles/add";
import UpdateRoleMutation from "graphql/mutation/roles/update";
import DeleteRoleMutation from "graphql/mutation/roles/delete";

/**
 * @name RoleDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const RoleDetails = ({ setPageTitle, setPageActionsMenu }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);
  const isAddForm = () => id === "new";

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
  });

  const interfaceRolesMap = [
    {
      name: "Brands",
      roleName: "brands",
      actions: {
        view: "brandsView",
        add: "brandsAdd",
        edit: "brandsEdit",
        delete: "brandsDelete",
      },
    },
    {
      name: "Areas",
      roleName: "areas",
      actions: {
        view: "areasView",
        add: "areasAdd",
        edit: "areasEdit",
        delete: "areasDelete",
      },
    },
    {
      name: "Categories",
      roleName: "categories",
      actions: {
        view: "categoriesView",
        add: "categoriesAdd",
        edit: "categoriesEdit",
        delete: "categoriesDelete",
      },
    },
    {
      name: "Products",
      roleName: "products",
      actions: {
        view: "productsView",
        add: "productsAdd",
        edit: "productsEdit",
        delete: "productsDelete",
      },
    },
    {
      name: "Checkout Notes",
      roleName: "checkoutNotes",
      actions: {
        view: "checkoutNotesView",
        add: "checkoutNotesAdd",
        edit: "checkoutNotesEdit",
        delete: "checkoutNotesDelete",
      },
    },
    {
      name: "Contact Us",
      roleName: "contactUs",
      actions: {
        view: "contactUsView",
        add: "",
        edit: "contactUsMarkAsSeen",
        delete: "contactUsDelete",
      },
    },
    {
      name: "Customers",
      roleName: "customers",
      actions: {
        view: "customersView",
        add: "customersAdd",
        edit: "customersEdit",
        delete: "customersDelete",
      },
    },
    {
      name: "Dashboard",
      roleName: "dashboard",
      actions: {
        view: "dashboardView",
        add: "",
        edit: "",
        delete: "",
      },
    },
    {
      name: "Delivery Fees",
      roleName: "deliveryFees",
      actions: {
        view: "deliveryFeesView",
        add: "deliveryFeesAdd",
        edit: "deliveryFeesEdit",
        delete: "deliveryFeesDelete",
      },
    },
    {
      name: "Home Banner",
      roleName: "homeBanner",
      actions: {
        view: "homeBannerView",
        add: "homeBannerAdd",
        edit: "homeBannerEdit",
        delete: "homeBannerDelete",
      },
    },
    {
      name: "Home Slider",
      roleName: "homeSlider",
      actions: {
        view: "homeSliderView",
        add: "homeSliderAdd",
        edit: "homeSliderEdit",
        delete: "homeSliderDelete",
      },
    },
    {
      name: "Media",
      roleName: "media",
      actions: {
        view: "",
        add: "mediaAdd",
        edit: "",
        delete: "",
      },
    },
    {
      name: "Notifications",
      roleName: "notifications",
      actions: {
        view: "",
        add: "notificationSend",
        edit: "",
        delete: "",
      },
    },
    {
      name: "Orders",
      roleName: "orders",
      actions: {
        view: "ordersView",
        add: "",
        edit: "ordersEdit",
        delete: "",
      },
    },
    {
      name: "Pages",
      roleName: "pages",
      actions: {
        view: "pagesView",
        add: "",
        edit: "pagesEdit",
        delete: "",
      },
    },
    {
      name: "Promo Codes",
      roleName: "promoCodes",
      actions: {
        view: "promoCodesView",
        add: "promoCodesAdd",
        edit: "promoCodesEdit",
        delete: "promoCodesDelete",
      },
    },
    {
      name: "Social",
      roleName: "social",
      actions: {
        view: "socialView",
        add: "",
        edit: "socialEdit",
        delete: "",
      },
    },
  ];

  let { data, status, refetch } = useSingleEntityAPI(
    "role",
    {
      id,
      query: SingleRoleQuery,
    },
    {
      onSuccess: ({ data }) => {
        setValue("id", data.id);
        setValue("name", data.name);
        data.permissions.forEach((permission) => {
          setValue(`${permission.model}.${permission.operation}`, true);
        });
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled: !isAddForm(),
    }
  );

  if (isAddForm()) status = "success";

  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);

  const { mutate: submitRequest, isLoading: isSubmitting } = useMutation(
    ({ id, name, permissions }) =>
      GraphQLClient.request(
        isAddForm() ? AddRoleMutation : UpdateRoleMutation,
        {
          id,
          name,
          permissions,
        }
      ),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("roles");
        queryClient.invalidateQueries("role");
        if (isAddForm()) {
          addToast("Role added successfully!", { appearance: "success" });
          history.push("/portal/roles");
        } else {
          addToast("Role updated successfully!", { appearance: "success" });
        }
      },
    }
  );

  const { mutate: deleteRequest, isLoading: isDeleting } = useMutation(
    ({ id }) =>
      GraphQLClient.request(DeleteRoleMutation, {
        id,
      }),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("roles");
        queryClient.invalidateQueries("role");

        addToast("Role deleted successfully!", { appearance: "success" });
        history.push("/portal/roles");
      },
    }
  );

  // State Hooks
  useEffect(() => {
    setPageTitle("Role Details");
    //   if (currentDataObject) {
    //     setValue("nameEn", currentDataObject.nameEn);
    //     setValue("nameAr", currentDataObject.nameAr);
    //     setValue("media", currentDataObject.media);
    //     setValue("status", currentDataObject.isActive ? "Active" : "Inactive");
    //   }
  }, []);

  useEffect(() => {
    if (currentDataObject.id) {
      setValue("id", currentDataObject.id);
      setValue("name", currentDataObject.name);
      currentDataObject.permissions.forEach((permission) => {
        setValue(`${permission.model}.${permission.operation}`, true);
      });
    }
  }, [currentDataObject]);

  useEffect(() => {
    if (!isAddForm() && currentDataObject.id) {
      if (ability.can("rolesDelete", "roles")) {
        setPageActionsMenu([
          {
            text: "Delete Role",
            isDanger: true,
            callback: () => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm("Are you sure to delete. This action can't be undone!")
              ) {
                deleteRequest({ id });
              }
            },
          },
        ]);
      }
    }

    return () => {
      setPageActionsMenu([]);
    };
  }, [id, currentDataObject]);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/roles");
    }
  };

  const mapFormToRequest = (formData) => {
    const permissions = [];
    Object.keys(formData).forEach((key) => {
      if (key !== "id" && key !== "name") {
        Object.keys(formData[key]).forEach((permission) => {
          if (formData[key][permission]) {
            permissions.push({
              model: key,
              operation: permission,
            });
          }
        });
      }
    });
    submitRequest({
      id: formData.id,
      name: formData.name,
      permissions: permissions,
    });
  };

  // Other

  // Component Render
  return (
    <div className="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined className="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
        }}
      >
        <form
          onSubmit={handleSubmit(mapFormToRequest)}
          className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y"
        >
          <input type="hidden" {...register("id")} />
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
            </div>
            <div className="p-4 px-6 flex flex-col">
              <div className="mb-4 w-1/2 flex-col">
                <Input
                  name="name"
                  label="Role Name"
                  placeholder="Role Name"
                  {...register("name", {
                    required: "Role name is required!",
                  })}
                  errors={errors}
                />
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Permissions</h1>
            </div>
            <div className="p-4 px-6 flex flex-col">
              <div className="mb-4 w-full flex-col">
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="py-2">&nbsp;</th>
                      <th className="border py-2">View</th>
                      <th className="border py-2">Add</th>
                      <th className="border py-2">Edit</th>
                      <th className="border py-2">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {interfaceRolesMap.map((role) => (
                      <tr>
                        <td className="border pl-2 py-2 text-lg">
                          {role.name}
                        </td>
                        {role.actions.view !== "" ? (
                          <td className="border text-center">
                            <input
                              name={role.actions.view}
                              type="checkbox"
                              {...register(
                                `${role.roleName}.${role.actions.view}`
                              )}
                            />
                          </td>
                        ) : (
                          <td className="border text-center"></td>
                        )}
                        {role.actions.add !== "" ? (
                          <td className="border text-center">
                            <input
                              name={role.actions.add}
                              type="checkbox"
                              {...register(
                                `${role.roleName}.${role.actions.add}`
                              )}
                            />
                          </td>
                        ) : (
                          <td className="border text-center"></td>
                        )}
                        {role.actions.edit !== "" ? (
                          <td className="border text-center">
                            <input
                              name={role.actions.edit}
                              type="checkbox"
                              {...register(
                                `${role.roleName}.${role.actions.edit}`
                              )}
                            />
                          </td>
                        ) : (
                          <td className="border text-center"></td>
                        )}
                        {role.actions.delete !== "" ? (
                          <td className="border text-center">
                            <input
                              name={role.actions.delete}
                              type="checkbox"
                              {...register(
                                `${role.roleName}.${role.actions.delete}`
                              )}
                            />
                          </td>
                        ) : (
                          <td className="border text-center"></td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <Button
              text="Save"
              className="w-full"
              color="text-white bg-pink-600 hover:bg-pink-700"
              SuffixIcon={!isSubmitting ? CheckCircleOutlined : LoadingOutlined}
              type="submit"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </StateBlock>
    </div>
  );
};

RoleDetails.propTypes = {
  /**
   *
   */
};

export default RoleDetails;
