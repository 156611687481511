import { gql } from "graphql-request";

export default gql`
  mutation deleteArea($id: String!) {
    deleteArea(areaId: $id) {
      id
      name
      country {
        id
        name
      }
    }
  }
`;
