import { gql } from "graphql-request";

export default gql`
  query SinglePromoCode($id: String!) {
    promoCode(promoCodeId: $id) {
      id
      name
      discountType
      value
      isPrivate
      isActive
      isUsed
      validFrom
      validTo
      customer {
          id
          firstName
          lastName
          email
          isActive
          isVerified
      }
    }
  }
`;
