import { gql } from "graphql-request";

export default gql`
  mutation DeleteBrand($id: String!) {
    deleteBrand(brandId: $id) {
      id
      nameEn
      nameAr
      media {
        id
        name
        path
      }
    }
  }
`;
