import GraphQLClient from "services/api";
import { useQuery, useQueryClient } from "react-query";

export const useSimpleQueryAPI = (
  key,
  query,
  args = {},
  options = {}
) => {
  const fetchData = async ({ queryKey }) => {
    const [_key, { args }] = queryKey;
    const {
      [key]: data,
    } = await GraphQLClient.request(query, {
      ...args
    });
    return data;
  };

  const queryClient = useQueryClient();

  return useQuery([key, { args }], fetchData, { ...options });
};
