import _ from "lodash";

const func = (className) =>
  className
    ? _.isString(className)
      ? className.split(" ")
      : [...className]
    : [];

export default func;
