import { upperFirst } from "lodash";
import { useState, useCallback } from "react";
export const useListViewState = ({
  perPageDefault = "10",
  pageDefault = 1,
  searchDefault = "",
  filterDefault = {},
}) => {
  const [perPage, setPerPage] = useState(perPageDefault);
  const [page, setPage] = useState(pageDefault);
  const [search, setSearch] = useState(searchDefault);
  const [filter, setFilter] = useState(filterDefault);

  const firstPage = (event) => {
    setPage(1);
  };

  const nextPage = (event) => {
    setPage((p) => ++p);
  };

  const prevPage = (event) => {
    setPage((p) => --p);
  };

  const handlePerPageChange = (event) => {
    // event.preventDefaults();
    firstPage();
    setPerPage(event.target.value);
  };

  const handleSearchChange = (event) => {
    // event.preventDefaults();
    firstPage();
    setSearch(event.target.value);
  };

  let filterHandlers = {};

  Object.keys(filterDefault).forEach((filterName) => {
    filterHandlers[`handle${upperFirst(filterName)}Change`] = (event) => {
      firstPage();
      setFilter((f) => ({ ...f, [filterName]: event.target.value }));
    };
  });

  console.log(filterHandlers);

  return {
    ...filterHandlers,
    nextPage,
    prevPage,
    handleSearchChange,
    handlePerPageChange,
    perPage,
    page,
    filter,
    search,
  };
};
