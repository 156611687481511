import { gql } from "graphql-request";

export default gql`
  mutation AddRole(
    $name: String!
    $permissions: [PermissionInput]
  ) {
    addRole(
      input: {
        name: $name
        permissions: $permissions
      }
    ) {
        id
        name
        permissions {
            id
            model
            operation
        }
    }
  }
`;
