import { gql } from "graphql-request";

export default gql`
  mutation DeleteDeliveryFees($id: String!) {
    deleteDeliveryFees(deliveryFeesId: $id) {
      id
      fees
      area {
        id
        name
      }
    }
  }
`;
