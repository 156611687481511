import { gql } from "graphql-request";

export default gql`
  mutation AssignPromoToCustomer(
    $promoCodeId: String!
    $customerId: String!
  ) {
    assignPromCodeToCustomer(
      promoCodeId: $promoCodeId
      customerId: $customerId
    ) {
      id
      name
      discountType
      value
      isPrivate
      isActive
    }
  }
`;
