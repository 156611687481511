import { gql } from "graphql-request";

export default gql`
  query GetCategoriesPicklist {
    categories(
      args: {
        pagination: { take: 10000000, skip: 0 }
        sort: { nameEn: 1 }
        filter: { category: null }
      }
    ) {
      data {
        id
        nameEn
        nameAr
        media {
          id
          path
          name
        }
        isCustomized
        isHot
        isActive
        category {
          id
        }
        subCategories {
          id
          nameEn
          nameAr
          media {
            id
            path
            name
          }
          isCustomized
          isHot
          isActive
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        filter
        sort
        search
      }
    }
  }
`;
