import { gql } from "graphql-request";

export default gql`
  query PromoCodesList(
    $take: Int!
    $skip: Int!
    $search: String
    $isActive: String
  ) {
    promoCodes(
      args: {
        pagination: { take: $take, skip: $skip }
        search: { name: $search }
        sort: { validTo: 1 }
        filter: { isActive: $isActive }
      }
    ) {
      data {
        id
        name
        value
        isActive
        isPrivate
        discountType
        validFrom
        validTo
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        search
        filter
        sort
      }
    }
  }
`;
