import { gql } from "graphql-request";

export default gql`
  query SingleCustomer($id: String) {
    customer(customerId: $id) {
      id
      firstName
      lastName
      mobileCountryCode
      mobileNumber
      isActive
      isVerified
      pushNotificationsEnabled
      email
      addresses {
        id
        name
        area {
          id
          name
        }
        country {
          id
          name
          abbr
        }
        addressLine_1
        addressLine_2
        streetNumber
        postalCode
        block
        buildingNumber
        floorNumber
        apartmentNumber
        isDefault
        otherInstructions
      }
    }
  }
`;
