// React
import React from "react";
import PropTypes from "prop-types";

// UI KIT
import {
  LoadingOutlined,
  MehOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name StateBlock
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const StateBlock = ({ isLoading, isError, isSuccess, refetch, children }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <>
      {isSuccess && children}
      {isLoading && (
        <div className="my-4 flex flex-row w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y items-center">
          <LoadingOutlined />
          <span className="mx-2">Loading...</span>
        </div>
      )}
      {isError && (
        <div className="my-4 flex flex-row w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y items-center">
          <MehOutlined />
          <span className="mx-2">Error Something went wrong</span>
          <button className="ml-auto" onClick={refetch}>
            <ReloadOutlined /> Retry
          </button>
        </div>
      )}
    </>
  );
};

StateBlock.propTypes = {
  /**
   *
   */
};

export default StateBlock;
