// React
import React, { useEffect, useMemo, useContext } from "react";

import {
  CloseOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";
import Select from "components/form/SelectField";

// Globals
import GraphQLClient from "services/api";

// Helpers
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import { AbilityContext } from "utils/ability";

// Components

import StateBlock from "components/StateBlock";

// Factories

// Screens

// Assets
import SingleDeliveryFee from "graphql/query/deliveryFees/single";
import AreasListQuery from "graphql/query/areas/list";
import AreasPickListQuery from "graphql/query/areas/picklist";
// Third Parties
import ReactTooltip from "react-tooltip";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { ToastProvider, useToasts } from "react-toast-notifications";

// Styles

// Ad-Hoc Components
import AddDeliveryFeesMutation from "graphql/mutation/deliveryFees/add";
import UpdateDeliveryFeesMutation from "graphql/mutation/deliveryFees/update";
import DeleteDeliveryFeesMutation from "graphql/mutation/deliveryFees/delete";
import { usePicklistAPI } from "utils/usePicklistAPI";

/**
 * @name DeliveryFeesDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const DeliveryFeesDetails = ({ setPageTitle, setPageActionsMenu }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);
  const isAddForm = () => id === "new";

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {},
  });

  let { data, status, refetch } = useSingleEntityAPI(
    "deliveryFeesDetails",
    {
      id,
      query: SingleDeliveryFee,
    },
    {
      onSuccess: ({ data }) => {
        setValue("id", data.id);
        setValue("fees", data.fees);
        setValue("area", data?.area?.id);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled: !isAddForm(),
    }
  );

  if (isAddForm()) status = "success";
  const { data: areasList } = usePicklistAPI("areas", {
    query: AreasPickListQuery,
  });

  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);
  const areas = useMemo(() => (areasList ? areasList.data : []), [areasList]);
  const { mutate: submitRequest, isLoading: isSubmitting } = useMutation(
    ({ id, fees, area }) =>
      GraphQLClient.request(
        isAddForm() ? AddDeliveryFeesMutation : UpdateDeliveryFeesMutation,
        {
          id,
          fees: parseFloat(fees),
          areaId: area,
        }
      ),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("deliveryFees");
        queryClient.invalidateQueries("deliveryFeesDetails");
        if (isAddForm()) {
          addToast("Delivery Fees added successfully!", {
            appearance: "success",
          });
          history.push("/portal/deliveryFees");
        } else {
          addToast("Delivery Fees updated successfully!", {
            appearance: "success",
          });
        }
      },
    }
  );

  const { mutate: deleteRequest, isLoading: isDeleting } = useMutation(
    ({ id }) =>
      GraphQLClient.request(DeleteDeliveryFeesMutation, {
        id,
      }),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("deliveryFees");
        queryClient.invalidateQueries("deliveryFeesDetails");

        addToast("Delivery Fees deleted successfully!", {
          appearance: "success",
        });
        history.push("/portal/deliveryFees");
      },
    }
  );

  // State Hooks
  useEffect(() => {
    setPageTitle("Delivery Fees Details");
    //   if (currentDataObject) {
    //     setValue("noteEn", currentDataObject.noteEn);
    //     setValue("noteAr", currentDataObject.noteAr);
    //     setValue("media", currentDataObject.media);
    //     setValue("status", currentDataObject.isActive ? "Active" : "Inactive");
    //   }
  }, []);

  useEffect(() => {
    if (currentDataObject.id) {
      setValue("id", currentDataObject.id);
      setValue("fees", currentDataObject.fees);
      setValue("area", currentDataObject?.area?.id);
    }
  }, [currentDataObject]);

  useEffect(() => {
    if (!isAddForm() && currentDataObject.id) {
      if (ability.can('deliveryFeesDelete','deliveryFees')) {
        setPageActionsMenu([
          {
            text: "Delete Delivery Fees",
            isDanger: true,
            callback: () => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm("Are you sure to delete. This action can't be undone!")
              ) {
                deleteRequest({ id });
              }
            },
          },
        ]);
      }
    }

    return () => {
      setPageActionsMenu([]);
    };
  }, [id, currentDataObject]);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/customers");
    }
  };

  // Other

  // Component Render
  return (
    <div className="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined className="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
        }}
      >
        <form
          onSubmit={handleSubmit(submitRequest)}
          className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y"
        >
          <input type="hidden" {...register("id")} />
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
            </div>
            <div className="p-4 px-6 flex flex-col">
              <hr className="color-gray-500 my-4" />
              <div className="flex">
                <div className="mb-4 pr-2 flex-col w-1/2">
                  <div className="mb-4">
                    <Input
                      name="fees"
                      label="Fees"
                      placeholder="Fees"
                      {...register("fees", {
                        required: "Fees Value  is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      {...register("area", {
                        required: "Area Is Required is required!",
                      })}
                      name="area"
                      label="Area"
                      placeholder="Area"
                      optionGroups={
                        areas
                          ? areas.map((area) => ({
                              country: area.country.name,
                              key: area.id,
                              label: area.name
                          })).reduce(function(ac, c) {
                            (ac[c["country"]] = ac[c["country"]] || []).push(c);
                            return ac;
                          }, {}) : {}
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <Button
              text="Save"
              className="w-full"
              color="text-white bg-pink-600 hover:bg-pink-700"
              SuffixIcon={!isSubmitting ? CheckCircleOutlined : LoadingOutlined}
              type="submit"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </StateBlock>
    </div>
  );
};

DeliveryFeesDetails.propTypes = {
  /**
   *
   */
};

export default DeliveryFeesDetails;
