import { gql } from "graphql-request";

export default gql`
  mutation DeletePromoCode($id: String!) {
    deletePromoCode(promoCodeId: $id) {
        id
        name
        discountType
        value
        isPrivate
        isActive
    }
  }
`;
