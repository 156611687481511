// React
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  CloseOutlined,
  ContainerOutlined,
  SkinOutlined,
  DollarOutlined,
  LoadingOutlined
} from "@ant-design/icons";

// UI KIT
import { useMutation, useQueryClient } from "react-query";


// Globals

// Helpers

// Components

// Factories

// Screens
import SingleOrderQuery from "graphql/query/orders/single";
// Assets

// Third Parties
import numeral from "numeral";
import ReactTooltip from "react-tooltip";
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import { useHistory, useParams } from "react-router";
import StateBlock from "components/StateBlock";
import { Link } from "react-router-dom";
import OrderProducts from "./OrderProducts";
import moment from "moment-timezone";
import GraphQLClient from "services/api";
import OrderChangeStatusMutation from "graphql/mutation/order/change.status"
import { ToastProvider, useToasts } from "react-toast-notifications";
import { useForm, Controller } from "react-hook-form";

// Styles

// Ad-Hoc Components

/**
 * @name OrderDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const OrderDetails = ({ setPageTitle }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const { id } = useParams();
  console.log(`id`, id);
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  // State Hooks
  let { data, status, refetch } = useSingleEntityAPI(
    "order",
    {
      id,
      query: SingleOrderQuery,
    },
    {
      onSuccess: ({ data }) => {},
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      // enabled: !isAddForm(),
    }
  );

  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);
  const successPayment = useMemo(() => {
    const payments = currentDataObject?.payments || [];
    return payments.find((payment) => payment.success) || {};
  }, [currentDataObject]);
  // console.log(`currentDataObject`, currentDataObject);
  // useEffect(() => {
  //   // if (currentDataObject?.id) {
  //   //   setPageTitle(`Order ${currentDataObject?.number} Details`);
  //   // }
  // }, [currentDataObject]);
  useEffect(() => setPageTitle("Order Details"), []);

  const { mutate: changeStatus, isLoading: isChangingStatus } = useMutation(
    ({ status }) =>
      GraphQLClient.request(OrderChangeStatusMutation, {
        id,
        status
      }),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("orders");
        queryClient.invalidateQueries("order");
      },
    }
  );

  // Effect Hooks
  const {
    customer = {},
    orderAddress = {},
    subtotal,
    discount,
    deliveryFees,
    total,
    number,
    exchangeRate,
    currency,
    createdAt,
    orderProducts = [],
    status: orderStatus,
  } = currentDataObject;
  const { transactionId } = successPayment;
  // Other Hooks

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      status: orderStatus,
    },
  });

  // Event Handlers

  // Other
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/orders");
    }
  };
  // Component Render

  return (
    <div className="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined className="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
        }}
      >
        <div className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y ">
          <div className="flex-1">
            <div className="my-4 mt-6 -mx-2 lg:flex">
              <div className="lg:px-2 lg:w-1/2">
                <div className="p-4 bg-gray-100 rounded-full">
                  <h1 className="ml-2 font-bold uppercase">
                    Order Information
                  </h1>
                </div>
                <div className="p-4 px-6">
                  <p className="mb-4">
                    Order Date:{" "}
                    {moment(parseInt(createdAt)).tz("Asia/Kuwait").format("DD/MM/YYYY, hh:MM A")}
                  </p>
                  <p className="mb-4">Order Number: {number}</p>

                  <p className="mb-4">
                    Status:{" "}
                    <span className={`text-sm relative inline-block px-3 py-1 font-semibold ${orderStatus === "PENDING" ? 'text-yellow-900' : orderStatus === "ON_THE_WAY" ? 'text-blue-900' : 'text-green-900'} leading-tight`}>
                        <span
                          aria-hidden
                          className={`absolute inset-0 ${orderStatus === "PENDING" ? 'bg-yellow-200' : orderStatus === "ON_THE_WAY" ? 'bg-blue-200' : 'bg-green-200'} opacity-50 rounded-full`}
                        ></span>
                        <span className="relative">
                          <select className="bg-transparent font-semibold" value={orderStatus} onChange={(e) => changeStatus({status: e.target.value})}>
                            <option value="PENDING">Pending</option>
                            <option value="ON_THE_WAY">On The Way</option>
                            <option value="DELIVERED">Delivered</option>
                          </select>
                        </span>
                      </span>
                        {isChangingStatus && <LoadingOutlined />}
                  </p>
                </div>
              </div>
              <div className="lg:px-2 lg:w-1/2">
                <div className="p-4 bg-gray-100 rounded-full">
                  <h1 className="ml-2 font-bold uppercase">
                    Payment Information
                  </h1>
                </div>
                <div className="p-4 px-6">
                  <p className="mb-4">Transaction ID: {transactionId}</p>
                  <p className="mb-4">Exchange Rate: {exchangeRate}</p>
                  <p className="mb-4">Currency: {currency}</p>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <OrderProducts orderProducts={orderProducts || []} />
              <hr className="pb-6 mt-6" />
              <div className="my-4 mt-6 -mx-2 lg:flex">
                <div className="lg:px-2 lg:w-1/2">
                  <div className="p-4 bg-gray-100 rounded-full">
                    <h1 className="ml-2 font-bold uppercase">
                      Customer Details
                    </h1>
                  </div>
                  <div className="p-4 px-6">
                    <p className="text-gray-900 whitespace-nowrap">
                      <Link
                        to={`/portal/customers/${customer.id}`}
                        className="text-blue-500 underline"
                      >
                        {customer?.firstName} {customer?.lastName}
                      </Link>
                      <br />
                      {customer?.email}
                      <br />
                      {customer.mobileCountryCode}
                      {customer?.mobileNumber}
                    </p>
                  </div>
                  <div className="p-4 mt-6 bg-gray-100 rounded-full">
                    <h1 className="ml-2 font-bold uppercase">
                      Shipping Address
                    </h1>
                  </div>

                  <div className="p-4 px-6">
                    {orderAddress?.country?.name === "Kuwait" ? (
                      <>
                        <p className="mb-4">
                          {orderAddress?.block}, {orderAddress?.streetNumber},
                        </p>
                        <p className="mb-4">
                          {orderAddress?.buildingNumber},{" "}
                          {orderAddress?.floorNumber},{" "}
                          {orderAddress?.apartmentNumber},
                        </p>
                      </>
                    ) : (
                      <p className="mb-4">
                        {orderAddress?.addressLine_1},{" "}
                        {orderAddress?.addressLine_2},
                      </p>
                    )}
                    <p className="mb-4">
                      {orderAddress?.country?.name}, {orderAddress?.area?.name}
                    </p>
                  </div>
                </div>
                <div className="lg:px-2 lg:w-1/2">
                  <div className="p-4 bg-gray-100 rounded-full">
                    <h1 className="ml-2 font-bold uppercase">Order Totals</h1>
                  </div>
                  <div className="p-4">
                    <div className="flex justify-between border-b">
                      <div className="lg:px-2 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800">
                        Subtotal
                      </div>
                      <div className="lg:px-2 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900">
                        {numeral(subtotal).format("$ 0,0.0")}
                      </div>
                    </div>
                    <div className="flex justify-between pt-4 border-b">
                      <div className="flex lg:px-2 lg:py-2 m-2 text-lg lg:text-xl font-bold text-gray-800">
                        Discount
                      </div>
                      <div className="lg:px-2 lg:py-2 m-2 lg:text-lg font-bold text-center text-red-700">
                        {numeral(discount).format("$ 0,0.0")}
                      </div>
                    </div>

                    <div className="flex justify-between pt-4 border-b">
                      <div className="lg:px-2 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800">
                        Delivery Fees
                      </div>
                      <div className="lg:px-2 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900">
                        {numeral(deliveryFees).format("$ 0,0.0")}
                      </div>
                    </div>
                    <div className="flex justify-between pt-4 border-b">
                      <div className="lg:px-2 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800">
                        Total
                      </div>
                      <div className="lg:px-2 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900">
                        {numeral(total).format("$ 0,0.0")}
                      </div>
                    </div>
                    {/* <a href="#">
                  <button className="flex justify-center w-full px-10 py-3 mt-6 font-medium text-white uppercase bg-gray-800 rounded-full shadow item-center hover:bg-gray-700 focus:shadow-outline focus:outline-none">
                    <svg
                      aria-hidden="true"
                      data-prefix="far"
                      data-icon="credit-card"
                      className="w-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"
                      />
                    </svg>
                    <span className="ml-2 mt-5px">Procceed to checkout</span>
                  </button>
                </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StateBlock>
    </div>
  );
};

OrderDetails.propTypes = {
  /**
   *
   */
};

export default OrderDetails;
