// React
import React, { useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import {
  CloseOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT
import Button from "components/form/Button";
import Input from "components/form/InputField";

// Globals
import GraphQLClient from "services/api";

// Helpers
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import { AbilityContext } from "utils/ability";

// Components
import StateBlock from "components/StateBlock";

// Factories

// Screens

// Assets
import SingleSocialQuery from "graphql/query/social/single";

// Third Parties
import ReactTooltip from "react-tooltip";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";

// Styles

// Ad-Hoc Components
import UpdateSocialMutation from "graphql/mutation/social/update";

/**
 * @name SocialLinkDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SocialLinkDetails = ({ setPageTitle, setPageActionsMenu }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);
  const isAddForm = () => id === "new";

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      contentAr: "",
      contentEn: "",
    },
  });

  let { data, status, refetch } = useSingleEntityAPI(
    "social",
    {
      id,
      query: SingleSocialQuery,
    },
    {
      onSuccess: ({ data }) => {
        setValue("id", data.id);
        setValue("type", data.type);
        setValue("value", data.value);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled: !isAddForm(),
    }
  );

  if (isAddForm()) status = "success";

  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);

  const { mutate: submitRequest, isLoading: isSubmitting } = useMutation(
    ({ id, type, value }) =>
      GraphQLClient.request(UpdateSocialMutation, {
        id,
        type,
        value,
      }),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("socials");
        queryClient.invalidateQueries("social");
        if (isAddForm()) {
          addToast("Social link added successfully!", { appearance: "success" });
          history.push("/portal/social");
        } else {
          addToast("Social link updated successfully!", { appearance: "success" });
        }
      },
    }
  );

  // State Hooks
  useEffect(() => {
    setPageTitle("Social Link Details");
  }, []);

  useEffect(() => {
    if (currentDataObject.id) {
      setValue("id", currentDataObject.id);
      setValue("type", currentDataObject.type);
      setValue("value", currentDataObject.value);
    }
  }, [currentDataObject]);

  useEffect(() => {
    if (!isAddForm() && currentDataObject.id) {
      setPageActionsMenu([]);
    }

    return () => {
      setPageActionsMenu([]);
    };
  }, [id, currentDataObject]);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/portal/social");
    }
  };

  // Other

  // Component Render
  return (
    <div className="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined className="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
        }}
      >
        <form
          onSubmit={handleSubmit(submitRequest)}
          className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y"
        >
          <input type="hidden" {...register("id")} />
          <input type="hidden" {...register("type")} />
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
            </div>
            <div className="p-4 px-6 flex flex-col">
                <Input
                    name="value"
                    label="Link"
                    placeholder="Link"
                    {...register("value", {
                        required: "Social link is required!",
                    })}
                    errors={errors}
                />
            </div>
          </div>
          <div className="flex-1">
            <Button
              text="Save"
              className="w-full"
              color="text-white bg-pink-600 hover:bg-pink-700"
              SuffixIcon={!isSubmitting ? CheckCircleOutlined : LoadingOutlined}
              type="submit"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </StateBlock>
    </div>
  );
};

SocialLinkDetails.propTypes = {
  /**
   *
   */
};

export default SocialLinkDetails;
