// React
import React, { useEffect, useMemo, useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";
import Select from "components/form/SelectField";

// Globals
import GraphQLClient from "services/api";

// Helpers
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import { AbilityContext } from "utils/ability";

// Components
import Media from "components/form/Media";
import StateBlock from "components/StateBlock";

// Factories

// Screens

// Assets
import SingleCustomerQuery from "graphql/query/customers/single";

// Third Parties
import ReactTooltip from "react-tooltip";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { ToastProvider, useToasts } from "react-toast-notifications";

// Styles

// Ad-Hoc Components
import AddCustomerMutatuion from "graphql/mutation/customer/add";
import UpdateCustomerMutatuion from "graphql/mutation/customer/update";
import DeleteCustomerMutatuion from "graphql/mutation/customer/delete";

/**
 * @name CustomerDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const CustomerDetails = ({ setPageTitle, setPageActionsMenu }) => {
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);
  const isAddForm = () => id === "new";
  const [isEditing, setIsEditing] = useState(isAddForm() ? true : false);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      status: "Active",
      pushNotifications: "Enabled",
      verified: "No",
      mobileCountryCode: "+965",
    },
  });

  const currentStatus = watch("status");
  const currentPushNotifications = watch("pushNotifications");
  const currentVerified = watch("verified");

  let { data, status, refetch } = useSingleEntityAPI(
    "customer",
    {
      id,
      query: SingleCustomerQuery,
    },
    {
      onSuccess: ({ data }) => {
        setValue("id", data.id);
        setValue("firstName", data.firstName);
        setValue("lastName", data.lastName);
        setValue("mobileCountryCode", data.mobileCountryCode);
        setValue("mobileNumber", data.mobileNumber);
        setValue("email", data.email);
        setValue("verified", data.isVerified ? "Yes" : "No");
        setValue(
          "pushNotifications",
          data.pushNotificationsEnabled ? "Enabled" : "Disabled"
        );
        setValue("status", data.isActive ? "Active" : "Inactive");
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled: !isAddForm(),
    }
  );

  if (isAddForm()) status = "success";

  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);

  const { mutate: submitRequest, isLoading: isSubmitting } = useMutation(
    ({
      id,
      firstName,
      lastName,
      mobileCountryCode,
      mobileNumber,
      email,
      password,
      status,
      pushNotifications,
      verified,
    }) =>
      GraphQLClient.request(
        isAddForm() ? AddCustomerMutatuion : UpdateCustomerMutatuion,
        {
          id,
          firstName,
          lastName,
          mobileCountryCode,
          mobileNumber,
          email,
          password,
          isActive: status === "Active" ? true : false,
          pushNotificationsEnabled:
            pushNotifications === "Enabled" ? true : false,
          isVerified: verified === "Yes" ? true : false,
        }
      ),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("customers");
        queryClient.invalidateQueries("customer");
        if (isAddForm()) {
          addToast("Customer added successfully!", { appearance: "success" });
          history.push("/portal/customers");
        } else {
          addToast("Customer updated successfully!", { appearance: "success" });
        }
      },
    }
  );

  const { mutate: deleteRequest, isLoading: isDeleting } = useMutation(
    ({ id }) =>
      GraphQLClient.request(DeleteCustomerMutatuion, {
        id,
      }),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("customers");
        queryClient.invalidateQueries("customer");

        addToast("Customer deleted successfully!", { appearance: "success" });
        history.push("/portal/customers");
      },
    }
  );

  // State Hooks
  useEffect(() => {
    setPageTitle("Customer Details");
  }, []);

  useEffect(() => {
    if (currentDataObject.id) {
      setValue("id", currentDataObject.id);
      setValue("firstName", currentDataObject.firstName);
      setValue("lastName", currentDataObject.lastName);
      setValue("mobileCountryCode", currentDataObject.mobileCountryCode);
      setValue("mobileNumber", currentDataObject.mobileNumber);
      setValue("email", currentDataObject.email);
      setValue("verified", currentDataObject.isVerified ? "Yes" : "No");
      setValue(
        "pushNotifications",
        currentDataObject.pushNotificationsEnabled ? "Enabled" : "Disabled"
      );
      setValue("status", currentDataObject.isActive ? "Active" : "Inactive");
    }
  }, [currentDataObject]);

  useEffect(() => {
    if (!isAddForm() && currentDataObject.id) {
      if (ability.can("customersDelete", "customers")) {
        setPageActionsMenu([
          {
            text: "Edit Customer",
            isDanger: false,
            callback: () => setIsEditing(true),
          },
          {
            text: "Delete Customer",
            isDanger: true,
            callback: () => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm("Are you sure to delete. This action can't be undone!")
              ) {
                deleteRequest({ id });
              }
            },
          },
        ]);
      }
    }

    return () => {
      setPageActionsMenu([]);
    };
  }, [id, currentDataObject]);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/customers");
    }
  };

  const cancelCustomerEditing = () => setIsEditing(false);

  // Component Render
  return (
    <div className="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined className="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
        }}
      >
        {!isEditing ? (
          <div className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y ">
            <div className="flex-1">
              <div className="p-4 bg-gray-100 rounded-full">
                <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
              </div>
              <div className="p-4 px-6 flex flex-col">
                <div className="flex flex-row items-center">
                  {/* <div className="mb-4 w-1/4 flex">
                  <div className="flex-shrink-0 w-28 h-28">
                    <img
                      className="w-full h-full rounded-full"
                      src={currentDataObject.image}
                      alt=""
                    />
                  </div>
                </div> */}
                  <div className="mb-4 w-full flex flex-wrap">
                    <div className="pr-2 mb-2 w-1/3 flex flex-col">
                      <span className="text-sm">Name: </span>
                      <span className="text-base">
                        {currentDataObject.firstName}{" "}
                        {currentDataObject.lastName}
                      </span>
                    </div>
                    <div className="pl-2 w-1/3 flex flex-col">
                      <span className="text-sm">Mobile: </span>
                      <span className="text-base">
                        {currentDataObject.mobileCountryCode}
                        {currentDataObject.mobileNumber}
                      </span>
                    </div>
                    <div className="w-1/3 flex flex-col items-start"></div>
                    <div className="w-1/3 flex flex-col items-start">
                      <span className="text-sm">Status: </span>
                      <span
                        className={
                          "relative inline-block px-3 py-1 font-semibold leading-tight text-sm " +
                          (currentDataObject.isActive
                            ? "text-green-900"
                            : "text-red-900")
                        }
                      >
                        <span
                          aria-hidden
                          className={
                            "absolute inset-0 opacity-50 rounded-full " +
                            (currentDataObject.isActive
                              ? "bg-green-200"
                              : "bg-red-200")
                          }
                        ></span>
                        <span className="relative text-sm">
                          {currentDataObject.isActive ? "Active" : "Inactive"}
                        </span>
                      </span>
                    </div>
                    <div className="w-1/3 flex flex-col items-start">
                      <span className="text-sm">Push Notifications: </span>
                      <span
                        className={
                          "relative inline-block px-3 py-1 font-semibold leading-tight text-sm " +
                          (currentDataObject.pushNotificationsEnabled
                            ? "text-green-900"
                            : "text-red-900")
                        }
                      >
                        <span
                          aria-hidden
                          className={
                            "absolute inset-0 opacity-50 rounded-full " +
                            (currentDataObject.pushNotificationsEnabled
                              ? "bg-green-200"
                              : "bg-red-200")
                          }
                        ></span>
                        <span className="relative text-sm">
                          {currentDataObject.pushNotificationsEnabled
                            ? "Enabled"
                            : "Disabled"}
                        </span>
                      </span>
                    </div>

                    <div className="w-1/3 flex flex-col items-start">
                      <span className="text-sm">Verified: </span>
                      <span
                        className={
                          "relative inline-block px-3 py-1 font-semibold leading-tight text-sm " +
                          (currentDataObject.isVerified
                            ? "text-green-900"
                            : "text-red-900")
                        }
                      >
                        <span
                          aria-hidden
                          className={
                            "absolute inset-0 opacity-50 rounded-full " +
                            (currentDataObject.isVerified
                              ? "bg-green-200"
                              : "bg-red-200")
                          }
                        ></span>
                        <span className="relative text-sm">
                          {currentDataObject.isVerified ? "Yes" : "No"}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="p-4 bg-gray-100 rounded-full">
                <h1 className="ml-2 font-bold uppercase">Access Information</h1>
              </div>
              <div className="p-4 px-6 flex flex-col">
                <div className="mb-4 w-full flex">
                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                    <span className="text-sm">Email: </span>
                    <span className="text-base">{currentDataObject.email}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="p-4 bg-gray-100 rounded-full">
                <h1 className="ml-2 font-bold uppercase">
                  Address Information
                </h1>
              </div>
              <div className="p-4 px-6 flex flex-col">
                <div className="w-full mx-auto">
                  <div className="mb-4 rounded overflow-hidden">
                    {currentDataObject.addresses?.map((address, i) => {
                      if (address.country?.name === "Kuwait") {
                        return (
                          <div
                            className="group outline-none accordion-section"
                            tabindex={i + 1}
                          >
                            <div className="group bg-gray-100 flex justify-between px-4 py-3 items-center text-gray-500 transition ease duration-500 cursor-pointer pr-10 relative">
                              <div className="uppercase text-gray-600 group-focus:text-gray-900 transition ease duration-500">
                                {address.name}
                              </div>
                              {/* <div className="-rotate-180 h-8 text-gray-900 w-8 border border-gray-900 rounded-full items-center inline-flex justify-center transform transition ease duration-500 group-focus:text-gray-900 group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
                                <DownOutlined />
                              </div> */}
                            </div>
                            <div className="group-focus:max-h-screen max-h-screen bg-gray px-4 overflow-hidden ease duration-500">
                              <div className="p-4 px-6 flex flex-col">
                                <div className="mb-4 w-full flex">
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">Country: </span>
                                    <span className="text-base">
                                      {address.country?.name}
                                    </span>
                                  </div>
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">Area: </span>
                                    <span className="text-base">
                                      {address.area?.name}
                                    </span>
                                  </div>
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">Block: </span>
                                    <span className="text-base">
                                      {address.block}
                                    </span>
                                  </div>
                                </div>
                                <div className="mb-4 w-full flex">
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">
                                      Street Number:{" "}
                                    </span>
                                    <span className="text-base">
                                      {address.streetNumber}
                                    </span>
                                  </div>
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">
                                      Building Number:{" "}
                                    </span>
                                    <span className="text-base">
                                      {address.buildingNumber}
                                    </span>
                                  </div>
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">
                                      Apartment Number:{" "}
                                    </span>
                                    <span className="text-base">
                                      {address.apartmentNumber}
                                    </span>
                                  </div>
                                </div>
                                <div className="mb-4 w-full flex">
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">
                                      Floor Number:{" "}
                                    </span>
                                    <span className="text-base">
                                      {address.floorNumber}
                                    </span>
                                  </div>
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">
                                      Postal Code:{" "}
                                    </span>
                                    <span className="text-base">
                                      {address.postalCode}
                                    </span>
                                  </div>
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">
                                      Other Instructions:{" "}
                                    </span>
                                    <span className="text-base">
                                      {address.otherInstructions}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="group outline-none accordion-section"
                            tabindex={i + 1}
                          >
                            <div className="group bg-gray-100 flex justify-between px-4 py-3 items-center text-gray-500 transition ease duration-500 cursor-pointer pr-10 relative">
                              <div className="uppercase text-gray-600 group-focus:text-gray-900 transition ease duration-500">
                                {address.name}
                              </div>
                              {/* <div className="-rotate-180 h-8 text-gray-900 w-8 border border-gray-900 rounded-full items-center inline-flex justify-center transform transition ease duration-500 group-focus:text-gray-900 group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
                                <DownOutlined />
                              </div> */}
                            </div>
                            <div className="group-focus:max-h-screen max-h-screen bg-gray px-4 overflow-hidden ease duration-500">
                              <div className="p-4 px-6 flex flex-col">
                                <div className="mb-4 w-full flex">
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">Country: </span>
                                    <span className="text-base">
                                      {address.country?.name}
                                    </span>
                                  </div>
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">Area: </span>
                                    <span className="text-base">
                                      {address.area?.name}
                                    </span>
                                  </div>
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">
                                      Address Line 1:{" "}
                                    </span>
                                    <span className="text-base">
                                      {address.addressLine_1}
                                    </span>
                                  </div>
                                </div>
                                <div className="mb-4 w-full flex">
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">
                                      Address Line 2:{" "}
                                    </span>
                                    <span className="text-base">
                                      {address.addressLine_2}
                                    </span>
                                  </div>
                                  <div className="pr-2 mb-2 w-1/3 flex flex-col">
                                    <span className="text-sm">
                                      Postal Code:{" "}
                                    </span>
                                    <span className="text-base">
                                      {address.postalCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(submitRequest)}
            className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y"
          >
            <input type="hidden" {...register("id")} />
            <div className="flex-1">
              <div className="p-4 bg-gray-100 rounded-full">
                <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
              </div>
              <div className="p-4 px-6 flex flex-col">
                <div className="mb-4 w-full flex">
                  <div className="pr-2 w-1/2">
                    <Input
                      name="firstName"
                      label="First Name"
                      placeholder="First Name"
                      {...register("firstName", {
                        required: "Customer first name is required!",
                      })}
                      errors={errors}
                      autocomplete="off"
                    />
                  </div>
                  <div className="pl-2 w-1/2">
                    <Input
                      name="lastName"
                      label="Last Name"
                      placeholder="Last Name"
                      {...register("lastName", {
                        required: "Customer last name is required!",
                      })}
                      errors={errors}
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div className="mb-4 w-full flex">
                  <div className="pr-2 w-1/2">
                    <Select
                      name="mobileCountryCode"
                      label="Mobile Country Code"
                      placeholder="Mobile Country Code"
                      options={{
                        "+965": "+965",
                        "+971": "+971",
                        "+966": "+966",
                        "+974": "+974",
                        "+973": "+973",
                        "+968": "+968",
                      }}
                      {...register("mobileCountryCode", {
                        required: "Mobile country code is required!",
                      })}
                      errors={errors}
                    />
                  </div>
                  <div className="pl-2 w-1/2">
                    <Input
                      name="mobileNumber"
                      label="Mobile Number"
                      placeholder="Mobile Number"
                      {...register("mobileNumber", {
                        required: "Customer mobile number is required!",
                      })}
                      errors={errors}
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div className="mb-4 w-full flex">
                  <div className="pr-2 w-1/3">
                    <span className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                      Status:
                    </span>
                    <br />
                    <span
                      className={
                        "relative text-xs inline-block px-3 py-1 font-semibold leading-tight " +
                        (currentStatus === "Active"
                          ? "text-green-900"
                          : "text-red-900")
                      }
                    >
                      <span
                        aria-hidden
                        className={
                          "absolute inset-0 opacity-50 rounded-full " +
                          (currentStatus === "Active"
                            ? "bg-green-200"
                            : "bg-red-200")
                        }
                      ></span>
                      <span className="relative">
                        <select
                          className="bg-transparent font-semibold"
                          {...register("status", {
                            required: "Customer status is required!",
                          })}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </span>
                    </span>
                  </div>
                  <div className="pr-2 w-1/3">
                    <span className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                      Push Notifications:
                    </span>
                    <br />
                    <span
                      className={
                        "relative text-xs inline-block px-3 py-1 font-semibold leading-tight " +
                        (currentPushNotifications === "Enabled"
                          ? "text-green-900"
                          : "text-red-900")
                      }
                    >
                      <span
                        aria-hidden
                        className={
                          "absolute inset-0 opacity-50 rounded-full " +
                          (currentPushNotifications === "Enabled"
                            ? "bg-green-200"
                            : "bg-red-200")
                        }
                      ></span>
                      <span className="relative">
                        <select
                          className="bg-transparent font-semibold"
                          {...register("pushNotifications", {
                            required:
                              "Customer push notification status is required!",
                          })}
                        >
                          <option value="Enabled">Enabled</option>
                          <option value="Disabled">Disabled</option>
                        </select>
                      </span>
                    </span>
                  </div>
                  <div className="pr-2 w-1/3">
                    <span className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                      Verified:
                    </span>
                    <br />
                    <span
                      className={
                        "relative text-xs inline-block px-3 py-1 font-semibold leading-tight " +
                        (currentVerified === "Yes"
                          ? "text-green-900"
                          : "text-red-900")
                      }
                    >
                      <span
                        aria-hidden
                        className={
                          "absolute inset-0 opacity-50 rounded-full " +
                          (currentVerified === "Yes"
                            ? "bg-green-200"
                            : "bg-red-200")
                        }
                      ></span>
                      <span className="relative">
                        <select
                          className="bg-transparent font-semibold"
                          {...register("verified", {
                            required:
                              "Customer verification status is required!",
                          })}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="p-4 bg-gray-100 rounded-full">
                <h1 className="ml-2 font-bold uppercase">Access Information</h1>
              </div>
              <div className="p-4 px-6 flex flex-col">
                <div className="mb-4 w-full flex">
                  <div className="pr-2 w-1/2">
                    <Input
                      name="email"
                      label="Email"
                      placeholder="Email"
                      {...register("email", {
                        required: "Customer email is required!",
                      })}
                      errors={errors}
                      autocomplete="off"
                    />
                  </div>
                  <div className="pl-2 w-1/2">
                    <Input
                      name="password"
                      label="Password"
                      type="password"
                      placeholder="Password"
                      note={
                        !isAddForm()
                          ? "Fill in that field to reset customer password or leave empty to keep the current password"
                          : null
                      }
                      {...register("password", {
                        required: "Customer password is required!",
                      })}
                      errors={errors}
                      autocomplete="new-password"
                    />
                  </div>
                </div>
              </div>
            </div>
            {!isAddForm && (
              <div className="flex-1">
                <div className="p-4 bg-gray-100 rounded-full">
                  <h1 className="ml-2 font-bold uppercase">
                    Address Information
                  </h1>
                </div>
                <div className="p-4 px-6 flex flex-col">
                  <div className="w-full mx-auto">
                    <div className="mb-4 rounded overflow-hidden">
                      <div
                        className="group outline-none accordion-section"
                        tabindex="1"
                      >
                        <div className="group bg-gray-100 flex justify-between px-4 py-3 items-center text-gray-500 transition ease duration-500 cursor-pointer pr-10 relative">
                          <div className="uppercase text-gray-600 group-focus:text-gray-900 transition ease duration-500">
                            Home
                          </div>
                          <div className="mr-2">
                            <DeleteOutlined />
                          </div>
                          <div className="-rotate-180 h-8 text-gray-900 w-8 border border-gray-900 rounded-full items-center inline-flex justify-center transform transition ease duration-500 group-focus:text-gray-900 group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
                            <DownOutlined />
                          </div>
                        </div>
                        <div className="group-focus:max-h-screen max-h-screen bg-gray px-4 overflow-hidden ease duration-500">
                          <div className="p-4 px-6 flex flex-col">
                            <div className="mb-4 w-full flex">
                              <div className="pr-2 w-1/2">
                                <Input
                                  name="addressName"
                                  label="Address Name"
                                  placeholder="Address Name"
                                />
                              </div>
                              <div className="pl-2 w-1/2">
                                <Input
                                  name="addressArea"
                                  label="Area"
                                  placeholder="Area"
                                />
                              </div>
                            </div>
                            <div className="mb-4 w-full flex">
                              <div className="pr-2 w-1/2">
                                <Input
                                  name="addressBlock"
                                  label="Block"
                                  placeholder="Block"
                                />
                              </div>
                              <div className="pl-2 w-1/2">
                                <Input
                                  name="addressStreetNumber"
                                  label="Street Number"
                                  placeholder="Street Number"
                                />
                              </div>
                            </div>
                            <div className="mb-4 w-full flex">
                              <div className="pr-2 w-1/2">
                                <Input
                                  name="addressBuildingNumber"
                                  label="BuildingNumber"
                                  placeholder="Building Number"
                                />
                              </div>
                              <div className="pl-2 w-1/2">
                                <Input
                                  name="addressFloorNumber"
                                  label="Floor Number"
                                  placeholder="Floor Number"
                                />
                              </div>
                            </div>
                            <div className="mb-4 w-full flex">
                              <div className="pr-2 w-1/2">
                                <Input
                                  name="addressPostalCode"
                                  label="Postal Code"
                                  placeholder="Postal Code"
                                />
                              </div>
                              <div className="pl-2 w-1/2">
                                <Input
                                  name="addressOtherInstructions"
                                  label="Other Instructions"
                                  placeholder="Other Instructions"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="group outline-none accordion-section"
                        tabindex="2"
                      >
                        <div className="group bg-gray-100 flex justify-between px-4 py-3 items-center text-gray-500 transition ease duration-500 cursor-pointer pr-10 relative">
                          <div className="uppercase text-gray-600 group-focus:text-gray-900 transition ease duration-500">
                            Office
                          </div>
                          <div className="mr-2">
                            <DeleteOutlined />
                          </div>
                          <div className="h-8 text-gray-600 h-8 w-8 border border-gray-600 rounded-full items-center inline-flex justify-center transform transition ease duration-500 group-focus:text-white group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
                            <DownOutlined />
                          </div>
                        </div>
                        <div className="group-focus:max-h-screen max-h-0 bg-gray-800 px-4 overflow-hidden ease duration-500"></div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 w-full justify-end flex">
                    <Button
                      text="Add Address"
                      color="ml-auto text-pink-500 hover:text-pink-700"
                      basic
                      bold
                      PrefixIcon={PlusOutlined}
                    />
                  </div>
                </div>
              </div>
            )}

            <Button
              text="Save"
              className="w-full"
              color="text-white bg-pink-600 hover:bg-pink-700"
              SuffixIcon={!isSubmitting ? CheckCircleOutlined : LoadingOutlined}
              type="submit"
              disabled={isSubmitting}
            />

            {!isAddForm() && (
              <Button
                text="Cancel"
                className="w-full mt-3"
                color="text-black bg-gray-200 hover:bg-gray-300"
                onClick={cancelCustomerEditing}
              />
            )}
          </form>
        )}
      </StateBlock>
    </div>
  );
};

CustomerDetails.propTypes = {
  /**
   *
   */
};

export default CustomerDetails;
