// React
import React from "react";
import PropTypes from "prop-types";
import createClassName from "utils/createClassName";

// UI KIT

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name StatisticsCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const StatisticsCard = ({ Icon, title, count, color }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div className="flex flex-row bg-white shadow-sm rounded p-4">
      <div
        className={createClassName(
          "flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl",
          color
        )}
      >
        <Icon style={{ fontSize: 24 }} />
      </div>
      <div className="flex flex-col flex-grow ml-4">
        <div className="text-sm text-gray-500">{title}</div>
        <div className="font-bold text-lg">{count}</div>
      </div>
    </div>
  );
};

StatisticsCard.propTypes = {
  /**
   *
   */
};

export default StatisticsCard;
