import { gql } from "graphql-request";

export default gql`
  query products(
    $take: Int!
    $skip: Int!
    $search: String
    $category: String
    $brand: String
  ) {
    products(
      args: {
        pagination: { take: $take, skip: $skip }
        sort: { productMeta: { nameEn: 1, nameAr: 1 } }
        search: { productMeta: { nameEn: $search, nameAr: $search } }
        filter: { category: $category, brand: $brand }
      }
    ) {
      data {
        id
        productMeta {
          nameEn
          nameAr
          descriptionEn
          descriptionAr
        }
        media {
          id
          name
          path
        }
        brand {
          id
          nameAr
          nameEn
        }
        category {
          id
          nameAr
          nameEn
        }
        stock
        price
        isActive
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        search
        filter
      }
    }
  }
`;
