import { gql } from "graphql-request";

export default gql`
  query SoldProductsByArea($from: String!, $to: String!) {
    soldProductsByArea(dateRange: { from: $from, to: $to }) {
      totalSoldProducts
      areaName
      countryName
    }
  }
`;
