import { gql } from "graphql-request";

export default gql`
  query DynamicTotals($from: String, $to: String) {
    dynamicTotals(dateRanage: { from: $from, to: $to }) {
      totalSales
      totalOrders
      totalCustomers
    }
  }
`;
