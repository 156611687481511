// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  PlusOutlined,
  LoadingOutlined,
  MehOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
// UI KIT

// Globals

// Helpers
import { useListViewParams } from "utils/useListViewParams";
import { useListViewState } from "utils/useListViewState";
import { useListViewAPI } from "utils/useListViewAPI";

// Components
import ListView from "components/listView/ListView";
import ListViewRow from "components/listView/ListViewRow";
import ListViewCol from "components/listView/ListViewCol";
import ListViewHeader from "components/listView/ListViewHeader";
import ListViewAddButton from "components/listView/ListViewAddButton";
import ListViewContainer from "components/listView/ListViewContainer";

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import RolesListQuery from "graphql/query/roles/list";

// Styles

// Ad-Hoc Components

/**
 * @name Roles
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Roles = ({ setPageTitle }) => {
  // Theme & Style Hooks
  const history = useHistory();
  // Global State Hooks

  // State Hooks
  const {
    page,
    perPage,
    search,
    filter,
    nextPage,
    prevPage,
    handlePerPageChange,
    handleSearchChange,
    handleStatusChange,
  } = useListViewState({});

  // Effect Hooks
  useEffect(() => setPageTitle("Roles"), []);

  // Other Hooks

  // Event Handlers
  const goToAdd = () => history.push("/portal/roles/new");

  // Other
  const { status, data, error, isFetching, refetch } = useListViewAPI(
    "roles",
    {
      perPage,
      page,
      search,
      filter,
      query: RolesListQuery,
      queryFilterEvaluation: (filter) => ({}),
    }
  );

  const viewData = data?.data;
  const viewMetadata = data?.metadata;

  const {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
  } = useListViewParams(page, perPage, viewMetadata, viewData?.length);

  const headers = [{ text: "Role Name" }];

  const listViewParams = {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
    headers,
    prevPage,
    nextPage,
  };

  const RowComponent = ({ id, name }) => {
    return (
      <ListViewRow>
        <ListViewCol>
          <div className="flex items-center">
            <div className="flex-shrink-0 w-40 ">
              <p className="text-gray-900 whitespace-no-wrap">
                <Link
                  to={`/portal/roles/${id}`}
                  className="text-blue-500 underline"
                >
                  {name}
                </Link>
              </p>
            </div>
          </div>
        </ListViewCol>
      </ListViewRow>
    );
  };

  // Component Render
  return (
    <ListViewContainer
      {...{
        isSuccess: status === "success",
        isLoading: status === "loading",
        isError: status === "error",
        refetch,
        header: (
          <>
            <ListViewAddButton onClick={goToAdd}>Add Role</ListViewAddButton>

            <ListViewHeader
              {...{
                perPage,
                handlePerPageChange,
                search,
                handleSearchChange,
              }}
            />
          </>
        ),
      }}
    >
      <ListView {...listViewParams}>
        {viewData?.map(
          ({ id, name }) => (
            <RowComponent {...{ id, name }} />
          )
        )}
      </ListView>
    </ListViewContainer>
  );
};

Roles.propTypes = {
  /**
   *
   */
};

export default Roles;
