import { gql } from "graphql-request";

export default gql`
  query SoldProductsByBrand($from: String!, $to: String!) {
    soldProductsByBrand(dateRange: { from: $from, to: $to }) {
      totalSoldProducts
      nameEn
      nameAr
      mediaPath
    }
  }
`;
