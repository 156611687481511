import { gql } from "graphql-request";

export default gql`
  mutation ChangeOrderStatus(
    $id: String!
    $status: String!
  ) {
    changeOrderStatus(
      orderId: $id
      orderStatus: $status
    ) {
      id
    }
  }
`;
