import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PortalLayout from "screens/PortalLayout";
import Login from "screens/auth/Login";
import ForgetPassword from "screens/auth/ForgetPassword";
import ResetPassword from "screens/auth/ResetPassword";
import Error404 from "screens/Error404";
import numeral from "numeral";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { AbilityProvider } from "utils/ability";
import RefreshToken from "./RefreshToken";
import { defaults } from "react-sweet-state";

defaults.devtools = true;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20, // 20 Seconds
    },
  },
});

function App() {
  try {
    numeral.localeData("kw");
  } catch (e) {
    numeral.register("locale", "kw", {
      delimiters: {
        thousands: " ",
        decimal: ".",
      },
      abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t",
      },
      currency: {
        symbol: "KWD",
      },
    });
    numeral.locale("kw");
  }

  return (
    <div className="App">
      <ToastProvider autoDismiss={true}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <RefreshToken />
            <Switch>
              <Route path="/auth">
                <Switch>
                  <Route path="/auth/login">
                    <Login />
                  </Route>
                  <Route path="/auth/forgetpassword">
                    <ForgetPassword />
                  </Route>
                  <Route path="/auth/resetpassword">
                    <ResetPassword />
                  </Route>
                  <Redirect exact from="/auth" to="/auth/login" />

                  <Route>
                    <Error404 />
                  </Route>
                </Switch>
              </Route>
              <Route path="/portal">
                <AbilityProvider>
                  <PortalLayout />
                </AbilityProvider>
              </Route>
              <Redirect exact from="/" to="/portal" />
              <Route>
                <Error404 />
              </Route>
            </Switch>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ToastProvider>
    </div>
  );
}

export default App;
