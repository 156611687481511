// React
import React, { useState } from "react";
import createClassName from "utils/createClassName";
import {
  SearchOutlined,
  UserOutlined,
  DashboardOutlined,
  ContactsOutlined,
  ContainerOutlined,
  AppleOutlined,
  HomeOutlined,
  TableOutlined,
  SkinOutlined,
  ApartmentOutlined,
  PercentageOutlined,
  DownOutlined,
  FireOutlined,
  CrownOutlined,
  FileTextOutlined,
  DollarOutlined,
  FileDoneOutlined,
  TwitterOutlined,
  SafetyOutlined,
  UpOutlined,
} from "@ant-design/icons";

import { Switch, Route, Link, Redirect, useRouteMatch } from "react-router-dom";

// UI KIT
import Input from "components/form/InputField";

// Globals
import { useAuthTokens } from "services/tokens/auth";

// Helpers
import { Can } from "utils/ability";

// Components

// Factories

// Screens
import Dashboard from "screens/dashboard";
import Orders from "screens/orders";
import OrderDetails from "screens/orders/OrderDetails";
import Customers from "screens/customers";
import CustomerDetails from "screens/customers/CustomerDetails";
import Products from "screens/products";
import ProductDetails from "screens/products/ProductDetails";
import Brands from "screens/brands";

import BrandDetails from "screens/brands/BrandDetails";
import Areas from "screens/areas";
import AreaDetails from "screens/areas/AreaDetails";
import CheckoutNotes from "screens/checkoutNotes";
import CheckoutNoteDetails from "screens/checkoutNotes/CheckoutNoteDetails";
import DeliveryFees from "screens/deliveryFees";
import DeliveryFeeDetails from "screens/deliveryFees/DeliveryFeeDetails";
import Categories from "screens/categories";
import CategoryDetails from "screens/categories/CategoryDetails";
import Reports from "screens/reports";
import Promos from "screens/promos";
import PromoDetails from "screens/promos/PromoDetails";
import Error404 from "screens/Error404";
import Error401 from "screens/Error401";
import HomeBannerImages from "screens/homebanner";
import HomeBannerImageDetails from "screens/homebanner/HomeBannerImageDetails";
import HomeSliderImages from "screens/homeslider";
import HomeSliderImageDetails from "screens/homeslider/HomeSliderImageDetails";
import Pages from "screens/pages";
import PagesDetails from "screens/pages/PagesDetails";
import SocialLink from "screens/social";
import SocialLinkDetails from "screens/social/SocialLinkDetails";
import Roles from "screens/roles";
import RoleDetails from "screens/roles/RoleDetails";
import Users from "screens/users";
import UserDetails from "screens/users/UserDetails";
import { useListViewAPI } from "utils/useListViewAPI";

// Assets
import Logo from "assets/logo-colored.png";

// Third Parties
import * as Scroll from "react-scroll";
import {
  Link as ScrollLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Messages from "./contactUs";
import MessagesListQuery from "graphql/query/contactUs/list";

// Styles

// Ad-Hoc Components

/**
 * @name PortalLayout
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const PortalLayout = () => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [pageActionsMenuOpen, setPageActionsMenuOpen] = useState(false);
  const [pageActionsMenu, setPageActionsMenu] = useState([]);
  const [, { setAuthToken, setRefreshToken }] = useAuthTokens();

  // Effect Hooks

  // Other Hooks
  let routerMatch = useRouteMatch();

  // Event Handlers
  const closeSidebar = () => setSidebarOpen(false);
  const openSidebar = () => setSidebarOpen(true);
  const closeAccountMenu = () => setAccountMenuOpen(false);
  // const openAccountMenu = () => setAccountMenuOpen(true);
  const toggleAccountMenu = () => setAccountMenuOpen((s) => !s);
  const closePageActionsMenu = () => setPageActionsMenuOpen(false);
  // const openPageActionsMenu = () => setPageActionsMenuOpen(true);
  const togglePageActionsMenu = () => setPageActionsMenuOpen((s) => !s);
  // Other

  const { data: messagesData } = useListViewAPI("contactUsMessages", {
    perPage: 10,
    page: 1,
    filter: { status: "ALL" },
    query: MessagesListQuery,
    queryFilterEvaluation: (filter) => ({
      status: filter.status === "ALL" ? null : filter.status,
    }),
  });

  const unreadMessagesCount = messagesData?.metadata?.other?.unReadCount;

  const MenuLink = ({
    children,
    className,
    to,
    activeOnlyWhenExact,
    Icon,
    ...otherProps
  }) => (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({ match }) => {
        let classes =
          "flex items-center mt-4 py-2 px-6 text-gray-500 hover:bg-gray-700 hover:bg-opacity-25 hover:text-gray-100";
        if (match) {
          classes =
            "flex items-center mt-4 py-2 px-6 bg-gray-500 bg-opacity-25 text-gray-100";
        }
        return (
          <Link
            to={to}
            className={classes.concat(" ").concat(className)}
            match={match}
            {...otherProps}
          >
            {Icon && <Icon style={{ fontSize: 24 }} />}
            <span class="mx-3">{children}</span>
          </Link>
        );
      }}
    />
  );

  // Component Render
  return (
    <div>
      <div className="flex h-screen bg-gray-200">
        <div
          className={createClassName(
            sidebarOpen ? "block" : "hidden",
            "fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"
          )}
          onClick={closeSidebar}
        ></div>

        <Element
          className={createClassName(
            sidebarOpen
              ? "translate-x-0 ease-out"
              : "-translate-x-full ease-in",
            "fixed z-30 inset-y-0 left-0 w-64 transition duration-300 transform bg-gray-800 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0 scrollbar-hide"
          )}
          name="Sidebar"
          id="Sidebar"
        >
          <div className="bg-gray-800 sticky flex items-center justify-center py-8 top-0  z-10">
            <div className="flex items-center">
              <img src={Logo} className="w-full px-8" alt="The Front Row" />
            </div>
          </div>

          <nav className="relative">
            {/* <div className="bg-gray-800 text-white sticky flex items-center justify-center py-2 top-0">
              <UpOutlined />
            </div> */}
            <Can I="dashboardView" a="dashboard">
              <MenuLink
                to={`${routerMatch.path}/dashboard`}
                Icon={DashboardOutlined}
              >
                Dashboard
              </MenuLink>
            </Can>

            <Can I="ordersView" a="orders">
              <MenuLink
                to={`${routerMatch.path}/orders`}
                Icon={ContainerOutlined}
              >
                Orders
              </MenuLink>
            </Can>

            <Can I="customersView" a="customers">
              <MenuLink
                to={`${routerMatch.path}/customers`}
                Icon={ContactsOutlined}
              >
                Customers
              </MenuLink>
            </Can>

            <Can I="productsView" a="products">
              <MenuLink to={`${routerMatch.path}/products`} Icon={SkinOutlined}>
                Products
              </MenuLink>
            </Can>

            <Can I="brandsView" a="brands">
              <MenuLink to={`${routerMatch.path}/brands`} Icon={AppleOutlined}>
                Brands
              </MenuLink>
            </Can>
            <Can I="areasView" a="areas">
              <MenuLink to={`${routerMatch.path}/areas`} Icon={HomeOutlined}>
                Areas
              </MenuLink>
            </Can>
            <Can I="categoriesView" a="categories">
              <MenuLink
                to={`${routerMatch.path}/categories`}
                Icon={ApartmentOutlined}
              >
                Categories
              </MenuLink>
            </Can>

            <Can I="promoCodesView" a="promoCodes">
              <MenuLink
                to={`${routerMatch.path}/promos`}
                Icon={PercentageOutlined}
              >
                Promo Codes
              </MenuLink>
            </Can>

            <Can I="homeSliderView" a="homeSlider">
              <MenuLink
                to={`${routerMatch.path}/homeslider`}
                Icon={CrownOutlined}
              >
                Home Slider
              </MenuLink>
            </Can>

            <Can I="homeBannerView" a="homeBanner">
              <MenuLink
                to={`${routerMatch.path}/homebanner`}
                Icon={FireOutlined}
              >
                Home Banner
              </MenuLink>
            </Can>

            <Can I="pagesView" a="pages">
              <MenuLink
                to={`${routerMatch.path}/pages`}
                Icon={FileTextOutlined}
              >
                Pages
              </MenuLink>
            </Can>

            <Can I="socialView" a="social">
              <MenuLink
                to={`${routerMatch.path}/social`}
                Icon={TwitterOutlined}
              >
                Social Links
              </MenuLink>
            </Can>

            <Can I="checkoutNotesView" a="checkoutNotes">
              <MenuLink
                to={`${routerMatch.path}/checkoutNotes`}
                Icon={FileDoneOutlined}
              >
                Checkout Notes
              </MenuLink>
            </Can>

            <Can I="deliveryFeesView" a="deliveryFees">
              <MenuLink
                to={`${routerMatch.path}/deliveryFees`}
                Icon={DollarOutlined}
              >
                Delivery Fees
              </MenuLink>
            </Can>

            <Can I="rolesView" a="roles">
              <MenuLink to={`${routerMatch.path}/roles`} Icon={SafetyOutlined}>
                Roles
              </MenuLink>
            </Can>

            <Can I="usersView" a="users">
              <MenuLink to={`${routerMatch.path}/users`} Icon={UserOutlined}>
                Users
              </MenuLink>
            </Can>

            <Can I="usersView" a="users">
              <MenuLink to={`${routerMatch.path}/reports`} Icon={TableOutlined}>
                Reports
              </MenuLink>
            </Can>
            <ScrollLink
              to="Sidebar-End"
              containerId="Sidebar"
              smooth={true}
              className="bg-gray-800 text-white sticky top-auto flex items-center justify-center py-2 bottom-0 z-index-1"
            >
              <DownOutlined />
              {/* <UpOutlined /> */}
            </ScrollLink>
            <Element id="Sidebar-End"></Element>
          </nav>
        </Element>
        <div className="flex-1 flex flex-col overflow-hidden">
          <header className="flex justify-between items-center py-4 px-6 bg-white border-b-4 border-pink-500">
            <div className="flex items-center">
              <button
                onClick={openSidebar}
                className="text-gray-500 focus:outline-none lg:hidden"
              >
                <svg
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6H20M4 12H20M4 18H11"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>

              <div className="relative mx-4 lg:mx-0">
                {/* <span className="absolute inset-y-0 left-0 pl-3 flex items-center"> */}
                {/* <Input
                  name="search"
                  small
                  placeholder="Search Orders"
                  className="w-32 sm:w-64"
                  PrefixIcon={SearchOutlined}
                /> */}

                {/* <svg
                    className="h-5 w-5 text-gray-500"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>

                <input
                  className="form-input w-32 sm:w-64 rounded-md pl-10 pr-4 focus:border-indigo-600"
                  type="text"
                  placeholder="Search"
                /> */}
              </div>
            </div>

            <div className="flex items-center">
              <div className="mr-4">
                <Link to={`${routerMatch.path}/messages`}>
                  <button
                    type="button"
                    class="text-sm bg-blue-600 text-white p-2 rounded  leading-none flex items-center"
                  >
                    <span>Messages</span>
                    {unreadMessagesCount ? (
                      <span class="bg-white px-1 rounded text-blue-600 text-xs ml-2">
                        {unreadMessagesCount}
                      </span>
                    ) : null}
                  </button>
                </Link>
              </div>
              <div className="relative">
                <button
                  onClick={toggleAccountMenu}
                  className="relative block h-8 w-8 rounded-full overflow-hidden shadow focus:outline-none text-center"
                >
                  <UserOutlined
                    className="h-full w-full mx-auto"
                    style={{ fontSize: 18 }}
                  />
                </button>

                {accountMenuOpen && (
                  <div>
                    <div
                      onClick={closeAccountMenu}
                      className="fixed inset-0 h-full w-full z-10"
                    ></div>

                    <div class="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10">
                      {/* <Link
                        to="/portal/profile"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
                      >
                        Profile
                      </Link> */}

                      <button
                        onClick={() => {
                          setAuthToken(null);
                          setRefreshToken(null);
                        }}
                        className="w-full block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </header>
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
            <div className="container mx-auto px-6 py-8 relative">
              <h3 className="text-gray-700 text-3xl font-medium flex flex-row items-center">
                <span>{pageTitle}</span>
                {pageActionsMenu && pageActionsMenu.length > 0 && (
                  <div className="relative">
                    <button
                      onClick={togglePageActionsMenu}
                      className="flex items-center"
                    >
                      <DownOutlined className="text-xl mx-2" />
                    </button>
                    {pageActionsMenuOpen && (
                      <div>
                        <div
                          onClick={closePageActionsMenu}
                          className="fixed inset-0 h-full w-full z-10"
                        ></div>

                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10">
                          {pageActionsMenu.map((menuItem) => (
                            <button
                              className={createClassName(
                                "block px-4 py-2 text-sm w-full",
                                !menuItem.isDanger
                                  ? "text-gray-700 hover:bg-indigo-600 hover:text-white"
                                  : "bg-red-500 text-white hover:bg-indigo-600 hover:text-white"
                              )}
                              onClick={() => {
                                closePageActionsMenu();
                                typeof menuItem.callback === "function" &&
                                  menuItem.callback();
                              }}
                            >
                              {menuItem.text}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </h3>
              <Switch>
                <Route path={`${routerMatch.path}/dashboard`}>
                  <Can I="dashboardView" a="dashboard" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Dashboard setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/orders/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="ordersAdd" a="orders" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <OrderDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="ordersEdit" a="orders" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <OrderDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/orders`}>
                  <Can I="ordersView" a="orders" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Orders setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/customers/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="customersAdd" a="customers" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <CustomerDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="customersEdit" a="customers" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <CustomerDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/customers`}>
                  <Can I="customersView" a="customers" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Customers setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/products/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="productsAdd" a="products" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <ProductDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="productsEdit" a="products" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <ProductDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/products`}>
                  <Can I="productsView" a="products" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Products setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/brands/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="brandsAdd" a="brands" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <BrandDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="brandsEdit" a="brands" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <BrandDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/areas/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="areasAdd" a="areas" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <AreaDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="areasEdit" a="areas" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <AreaDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/brands`}>
                  <Can I="brandsView" a="brands" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Brands setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>
                <Route path={`${routerMatch.path}/areas`}>
                  <Can I="areasView" a="areas" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Areas setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>
                <Route path={`${routerMatch.path}/checkoutNotes/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="checkoutNotesAdd" a="checkoutNotes" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <CheckoutNoteDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="checkoutNotesEdit" a="checkoutNotes" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <CheckoutNoteDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/checkoutNotes`}>
                  <Can I="checkoutNotesView" a="checkoutNotes" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <CheckoutNotes setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/deliveryFees/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="deliveryFeesAdd" a="deliveryFees" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <DeliveryFeeDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="deliveryFeesEdit" a="deliveryFees" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <DeliveryFeeDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/deliveryFees`}>
                  <Can I="deliveryFeesView" a="deliveryFees" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <DeliveryFees setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/categories/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="categoriesAdd" a="categories" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <CategoryDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="categoriesEdit" a="categories" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <CategoryDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/categories`}>
                  <Can I="categoriesView" a="categories" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Categories setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/promos/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="promoCodesAdd" a="promoCodes" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <PromoDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="promoCodesEdit" a="promoCodes" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <PromoDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/promos`}>
                  <Can I="promoCodesView" a="promoCodes" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Promos setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/reports`}>
                  <Can I="reportsView" a="reports" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Reports setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/homeslider/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="homeSliderAdd" a="homeSlider" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <HomeSliderImageDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="homeSliderEdit" a="homeSlider" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <HomeSliderImageDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/homeslider`}>
                  <Can I="homeSliderView" a="homeSlider" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <HomeSliderImages setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/homebanner/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="homeBannerAdd" a="homeBanner" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <HomeBannerImageDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="homeBannerEdit" a="homeBanner" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <HomeBannerImageDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/homebanner`}>
                  <Can I="homeBannerView" a="homeBanner" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <HomeBannerImages setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/pages/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="pagesAdd" a="pages" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <PagesDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="pagesEdit" a="pages" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <PagesDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/pages`}>
                  <Can I="pagesView" a="pages" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Pages setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/social/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="socialAdd" a="social" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <SocialLinkDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="socialEdit" a="social" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <SocialLinkDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/social`}>
                  <Can I="socialView" a="social" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <SocialLink setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/roles/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="rolesAdd" a="roles" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <RoleDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="rolesEdit" a="roles" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <RoleDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/roles`}>
                  <Can I="rolesView" a="roles" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Roles setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/users/:id`}>
                  {({ match }) => {
                    const { id } = match.params;
                    return id === "new" ? (
                      <Can I="usersAdd" a="users" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <UserDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    ) : (
                      <Can I="usersEdit" a="users" passThrough>
                        {(allowed) =>
                          allowed ? (
                            <UserDetails
                              setPageTitle={setPageTitle}
                              setPageActionsMenu={setPageActionsMenu}
                            />
                          ) : (
                            <Error401 setPageTitle={setPageTitle} />
                          )
                        }
                      </Can>
                    );
                  }}
                </Route>
                <Route path={`${routerMatch.path}/users`}>
                  <Can I="usersView" a="users" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Users setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Route path={`${routerMatch.path}/messages`}>
                  <Can I="contactUsView" a="contactUs" passThrough>
                    {(allowed) =>
                      allowed ? (
                        <Messages setPageTitle={setPageTitle} />
                      ) : (
                        <Error401 setPageTitle={setPageTitle} />
                      )
                    }
                  </Can>
                </Route>

                <Redirect exact from="/portal" to="/portal/dashboard" />
                <Route>
                  <Error404 setPageTitle={setPageTitle} />
                </Route>
              </Switch>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

PortalLayout.propTypes = {
  /**
   *
   */
};

export default PortalLayout;
