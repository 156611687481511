import { gql } from "graphql-request";

export default gql`
  query StaticTotals {
    staticTotals {
      totalBrands
      totalCategories
      totalProducts
      totalDeliveredOrders
    }
  }
`;
