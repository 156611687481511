import { gql } from "graphql-request";

export default gql`
  mutation UpdatePromoCode(
    $id: String!
    $name: String!
    $discountType: String!
    $value: Float!
    $isPrivate: Boolean!
    $isActive: Boolean!
    $validFrom: String!
    $validTo: String!
  ) {
    updatePromoCode(
      promoCodeId: $id
      input: {
        name: $name
        discountType: $discountType
        value: $value
        isPrivate: $isPrivate
        isActive: $isActive
        validFrom: $validFrom
        validTo: $validTo
      }
    ) {
      id
      name
      discountType
      value
      isPrivate
      isActive
    }
  }
`;
