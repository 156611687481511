// React
import React, { useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";
import Select from "components/form/SelectField";

// Globals
import GraphQLClient from "services/api";

// Helpers
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import { usePicklistAPI } from "utils/usePicklistAPI";
import { AbilityContext } from "utils/ability";

// Components
import Media from "components/form/Media";
import StateBlock from "components/StateBlock";

// Factories

// Screens

// Assets
import SingleCategoryQuery from "graphql/query/categories/single";
import ParentCategoriesPicklistQuery from "graphql/query/categories/picklist.parent";

// Third Parties
import ReactTooltip from "react-tooltip";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { ToastProvider, useToasts } from "react-toast-notifications";

// Styles

// Ad-Hoc Components
import AddCategoryMutatuion from "graphql/mutation/category/add";
import UpdateCategoryMutatuion from "graphql/mutation/category/update";
import DeleteCategoryMutatuion from "graphql/mutation/category/delete";

/**
 * @name CategoryDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const CategoryDetails = ({ setPageTitle, setPageActionsMenu }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);
  const isAddForm = () => id === "new";

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      status: "Active",
      categoryType: "parent",
      isHot: "No",
      bulkDiscountPercentage: "0",
    },
  });

  const currentStatus = watch("status");
  const currentCategoryType = watch("categoryType");

  const updateFormData = (data) => {
    setValue("id", data.id);
    setValue("nameEn", data.nameEn);
    setValue("nameAr", data.nameAr);
    setValue("media", data.media);
    setValue("status", data.isActive ? "Active" : "Inactive");
    setValue(
      "categoryType",
      data.isCustomized ? "customized" : data.category?.id ? "sub" : "parent"
    );
    setValue("category", data.category?.id);
    setValue("isHot", data.isHot ? "Yes" : "No");
    setValue("bulkDiscountPercentage", data.bulkDiscountPercentage);
  };

  let { data, status, refetch } = useSingleEntityAPI(
    "category",
    {
      id,
      query: SingleCategoryQuery,
    },
    {
      onSuccess: ({ data }) => {
        updateFormData(data);
        // setValue("id", data.id);
        // setValue("nameEn", data.nameEn);
        // setValue("nameAr", data.nameAr);
        // setValue("media", data.media);
        // setValue("status", data.isActive ? "Active" : "Inactive");
        // setValue(
        //   "categoryType",
        //   data.isCustomized
        //     ? "customized"
        //     : data.category?.id
        //     ? "sub"
        //     : "parent"
        // );
        // setValue("category", data.category?.id);
        // setValue("isHot", data.isHot ? "Yes" : "No");
        // setValue("bulkDiscountPercentage", data.bulkDiscountPercentage);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled: !isAddForm(),
    }
  );

  const { data: parentCategoriesResult } = usePicklistAPI("categories", {
    query: ParentCategoriesPicklistQuery,
  });

  const parentCategories = useMemo(() => parentCategoriesResult?.data, [
    parentCategoriesResult,
  ]);

  // parentCategoriesResult?.data

  if (isAddForm()) status = "success";

  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);

  const { mutate: submitRequest, isLoading: isSubmitting } = useMutation(
    ({
      id,
      nameEn,
      nameAr,
      media,
      status,
      bulkDiscountPercentage,
      category,
      categoryType,
      isHot,
    }) =>
      GraphQLClient.request(
        isAddForm() ? AddCategoryMutatuion : UpdateCategoryMutatuion,
        {
          id,
          nameEn,
          nameAr,
          mediaId: media.id,
          parentCategoryId: categoryType === "sub" ? category : null,
          isCustomized: categoryType === "customized",
          isHot: isHot === "Yes",
          isActive: status === "Active" ? true : false,
          bulkDiscountPercentage: parseFloat(bulkDiscountPercentage),
        }
      ),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("categories");
        queryClient.invalidateQueries("category");
        if (isAddForm()) {
          addToast("Category added successfully!", { appearance: "success" });
          history.push("/portal/categories");
        } else {
          addToast("Category updated successfully!", { appearance: "success" });
        }
      },
    }
  );

  const { mutate: deleteRequest, isLoading: isDeleting } = useMutation(
    ({ id }) =>
      GraphQLClient.request(DeleteCategoryMutatuion, {
        id,
      }),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("categories");
        queryClient.invalidateQueries("category");

        addToast("Category deleted successfully!", { appearance: "success" });
        history.push("/portal/categories");
      },
    }
  );

  // State Hooks
  useEffect(() => {
    setPageTitle("Category Details");
  }, []);

  useEffect(() => {
    if (currentDataObject.id) {
      updateFormData(currentDataObject);
      // setValue("id", currentDataObject.id);
      // setValue("nameEn", currentDataObject.nameEn);
      // setValue("nameAr", currentDataObject.nameAr);
      // setValue("media", currentDataObject.media);
      // setValue("category", currentDataObject.category?.id);
      // setValue("status", currentDataObject.isActive ? "Active" : "Inactive");
    }
  }, [currentDataObject]);

  useEffect(() => {
    if (!isAddForm() && currentDataObject.id) {
      if (ability.can("categoriesDelete", "categories")) {
        setPageActionsMenu([
          {
            text: "Delete Category",
            isDanger: true,
            callback: () => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm("Are you sure to delete. This action can't be undone!")
              ) {
                deleteRequest({ id });
              }
            },
          },
        ]);
      }
    }

    return () => {
      setPageActionsMenu([]);
    };
  }, [id, currentDataObject]);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/customers");
    }
  };

  // Other

  // Component Render
  return (
    <div className="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined className="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
        }}
      >
        <form
          onSubmit={handleSubmit(submitRequest)}
          className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y"
        >
          <input type="hidden" {...register("id")} />
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
            </div>
            <div className="p-4 px-6 flex flex-col">
              <div className="mb-4 w-full flex">
                <Controller
                  name="media"
                  control={control}
                  rules={{ required: "Category image is required!" }}
                  render={({ field }) => <Media {...field} errors={errors} />}
                />
              </div>
              <hr className="color-gray-500 my-4" />
              <div className="flex">
                <div className="mb-4 pr-2 flex-col w-1/2">
                  <div className="mb-4">
                    <Input
                      name="nameEn"
                      label="Category Name (EN)"
                      placeholder="Category Name (EN)"
                      {...register("nameEn", {
                        required: "Category english name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      name="nameAr"
                      label="Category Name (AR)"
                      placeholder="Category Name (AR)"
                      {...register("nameAr", {
                        required: "Category arabic name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      name="categoryType"
                      label="Category Type"
                      placeholder="Category Type"
                      options={{
                        parent: "Parent Category",
                        sub: "Sub Category",
                        customized: "Customized Category",
                      }}
                      {...register("categoryType", {
                        required: "Category Type is required!",
                      })}
                      disabled={!isAddForm()}
                    />
                    {!isAddForm() && (
                      <input
                        {...register("categoryType", {
                          required: "Category Type is required!",
                        })}
                        type="hidden"
                      />
                    )}
                  </div>
                  {currentCategoryType === "sub" && (
                    <div className="mb-4">
                      <Select
                        name="category"
                        label="Parent Category"
                        placeholder="Parent Category"
                        options={
                          parentCategories
                            ? parentCategories.reduce(
                                (a, c) => ({
                                  ...a,
                                  [c.id]: `${c.nameEn} (${c.nameAr})`,
                                }),
                                {}
                              )
                            : {}
                        }
                        {...register("category", {
                          required: "Category Parent is required!",
                        })}
                      />
                    </div>
                  )}

                  {currentCategoryType !== "parent" && (
                    <div className="mb-4">
                      <Select
                        name="isHot"
                        label="Is Hot Category?"
                        placeholder="Is Hot Category?"
                        options={{
                          Yes: "Yes",
                          No: "No",
                        }}
                        {...register("isHot", {
                          required:
                            "Please specify if the category is hot or not",
                        })}
                      />
                    </div>
                  )}
                  <div className="mb-4">
                    <Input
                      name="bulkDiscountPercentage"
                      label="Bulk Discount Percentage"
                      placeholder="Bulk Discount Percentage"
                      type="number"
                      min="0"
                      max="100"
                      step="0.1"
                      {...register("bulkDiscountPercentage", {
                        required:
                          "Category Bulk Discount Percentage is required!",
                        min: 0,
                        max: 100,
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className="">
                    <span className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                      Status:
                    </span>
                    <br />
                    <span
                      className={
                        "relative text-xs inline-block px-3 py-1 font-semibold leading-tight " +
                        (currentStatus === "Active"
                          ? "text-green-900"
                          : "text-red-900")
                      }
                    >
                      <span
                        aria-hidden
                        className={
                          "absolute inset-0 opacity-50 rounded-full " +
                          (currentStatus === "Active"
                            ? "bg-green-200"
                            : "bg-red-200")
                        }
                      ></span>
                      <span className="relative">
                        <select
                          className="bg-transparent font-semibold"
                          {...register("status", {
                            required: "Category status is required!",
                          })}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <Button
              text="Save"
              className="w-full"
              color="text-white bg-pink-600 hover:bg-pink-700"
              SuffixIcon={!isSubmitting ? CheckCircleOutlined : LoadingOutlined}
              type="submit"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </StateBlock>
    </div>
  );
};

CategoryDetails.propTypes = {
  /**
   *
   */
};

export default CategoryDetails;

// const CategoryDetails = ({ setPageTitle }) => {
//   // Theme & Style Hooks

//   // Global State Hooks

//   // State Hooks
//   useEffect(() => setPageTitle("Category Details"), []);

//   // Effect Hooks

//   // Other Hooks

//   // Event Handlers

//   // Other

//   // Component Render
//   return (
//     <div className="flex justify-center my-6">
//       <ReactTooltip />
//       <button
//         data-tip="Close"
//         className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
//       >
//         <CloseOutlined className="p-0" />
//       </button>
//       <div className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y ">
//         <div className="flex-1">
//           <div className="p-4 bg-gray-100 rounded-full">
//             <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
//           </div>
//           <div className="p-4 px-6 flex flex-col">
//             <div className="mb-4 w-full flex">
//               <div className="mr-4 flex-shrink-0 w-28 h-28">
//                 <div className="w-full h-full object-cover rounded text-3xl flex items-center justify-center border border-gray-500 border-dashed">
//                   <PlusOutlined />
//                 </div>
//               </div>
//             </div>
//             <hr className="color-gray-500 my-4" />
//             <div className="flex">
//               <div className="mb-4 pr-2 flex-col w-1/2">
//                 <div className="mb-4">
//                   <Input
//                     name="productName"
//                     label="Category Name"
//                     placeholder="Category Name"
//                   />
//                 </div>
//                 <div className="mb-4">
//                   <Select
//                     name="category"
//                     label="Parent Category"
//                     placeholder="Parent Category"
//                     options={{ 50: "Men" }}
//                   />
//                 </div>
//                 <div className="">
//                   <span className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
//                     Status:
//                   </span>
//                   <br />
//                   <span className="relative text-xs inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
//                     <span
//                       aria-hidden
//                       className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
//                     ></span>
//                     <span className="relative">
//                       <select className="bg-transparent font-semibold">
//                         <option selected>Active</option>
//                       </select>
//                     </span>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="flex-1">
//           <Button
//             text="Save"
//             className="w-full"
//             color="text-white bg-pink-600 hover:bg-pink-700"
//             SuffixIcon={CheckCircleOutlined}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// CategoryDetails.propTypes = {
//   /**
//    *
//    */
// };

// export default CategoryDetails;
