import { gql } from "graphql-request";

export default gql`
  mutation UpdateDeliveryFees($id: String!, $fees: Float!, $areaId: String!) {
    updateDeliveryFees(
      deliveryFeesId: $id
      input: { fees: $fees, areaId: $areaId }
    ) {
      id
      fees
      area {
        id
        name
      }
    }
  }
`;
