import { gql } from "graphql-request";

export default gql`
  query GetBrandsPicklist{
    brands(
      args: {
        pagination: { take: 10000000, skip: 0 }
        sort: { nameEn: 1 }
      }
    ) {
      data {
        id
        nameEn
        nameAr
        isActive
        media {
          id
          name
          path
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        search
        filter
        sort
      }
    }
  }
`;
