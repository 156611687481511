import { gql } from "graphql-request";

export default gql`
  mutation AddCheckoutNote(
    $noteEn: String!
    $noteAr: String!
    $countryId: String!
  ) {
    addCheckoutNote(
      input: { noteEn: $noteEn, noteAr: $noteAr, countryId: $countryId }
    ) {
      id
      noteEn
      noteAr
      country {
        id
        name
        abbr
      }
    }
  }
`;
