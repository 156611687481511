import { gql } from "graphql-request";

export default gql`
  query GetAreasPicklist {
    areas(
      args: { pagination: { take: 10000000, skip: 0 } }
    ) {
      data {
        id
        name
        country {
          id
          name
        }
      }
    }
  }
`;
