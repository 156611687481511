import { gql } from "graphql-request";

export default gql`
  mutation UpdateRole(
    $id: String!
    $name: String!
    $permissions: [PermissionInput]
  ) {
    updateRole(
        roleId: $id
        input: {
            name: $name
            permissions: $permissions
        }
    ) {
        id
        name
        permissions {
            id
            model
            operation
        }
    }
  }
`;
