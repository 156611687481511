import { gql } from "graphql-request";

export default gql`
  query RolesList {
    roles(
      args: {
        pagination: { take: 1000000, skip: 0 }
      }
    ) {
      data {
        id
        name
        permissions {
            id
            model
            operation
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
      }
    }
  }
`;
