import { gql } from "graphql-request";

export default gql`
  query SingleCheckoutNote($id: String!) {
    checkoutNote(checkoutNoteId: $id) {
      id
      noteEn
      noteAr
      country {
        id
        name
        abbr
      }
    }
  }
`;
