import { gql } from 'graphql-request';

export default gql`
    query PagesList(
        $take: Int!
        $skip: Int!
    ) {
        pages (
            args: {
                pagination: { take: $take, skip: $skip }
            }
        ) {
            data {
                id
                titleAr
                titleEn
                contentAr
                contentEn
            }
            metadata {
                pagination {
                    total
                    take
                    skip
                }
            }
        }
    }
`;