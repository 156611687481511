import { gql } from "graphql-request";

export default gql`
  mutation AddCustomer(
    $firstName: String!
    $lastName: String!
    $mobileCountryCode: String!
    $mobileNumber: String!
    $isActive: Boolean!
    $pushNotificationsEnabled: Boolean!
    $email: String!
    $password: String!
    $isVerified: Boolean!
  ) {
    addCustomer(
      input: {
        firstName: $firstName
        lastName: $lastName
        mobileCountryCode: $mobileCountryCode
        mobileNumber: $mobileNumber
        isActive: $isActive
        pushNotificationsEnabled: $pushNotificationsEnabled
        email: $email
        password: $password
        isVerified: $isVerified
      }
    ) {
      id
    }
  }
`;
