// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  TeamOutlined,
  ContainerOutlined,
  SkinOutlined,
  DollarOutlined,
} from "@ant-design/icons";

// UI KIT

// Globals

// Helpers
import numeral from "numeral";
// Components

// Factories

// Screens
import StatisticCards from "screens/dashboard/StatisticCards";
import MonthlySales from "screens/dashboard/MonthlySales";
import PendingOrders from "screens/dashboard/PendingOrders";
import StatisticCards2 from "./StatisticCards2";
import OrdersChart from "./OrdersChart";

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name Dashboard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Dashboard = ({ setPageTitle }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  useEffect(() => setPageTitle("Dashboard"), []);
  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div>
      <div className="flex flex-grow">
      <StatisticCards className="pr-2 w-1/2" />
      <StatisticCards2 className="w-1/2" />
      </div>
      <OrdersChart />
      {/* <MonthlySales /> */}
    </div>
  );
};

Dashboard.propTypes = {
  /**
   *
   */
};

export default Dashboard;
