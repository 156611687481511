// React
import React from "react";
import PropTypes from "prop-types";

// UI KIT

// Globals
import Input from "components/form/InputField";
import Button from "components/form/Button";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name ReportingPeriodForm
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ReportingPeriodForm = ({ dateRange, setDateRange }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      from: moment(dateRange.from).tz("Asia/Kuwait").format("YYYY-MM-DD"),
      to: moment(dateRange.to).tz("Asia/Kuwait").format("YYYY-MM-DD"),
    },
  });

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const getMillinsInTz = (d, start) => {
    const x = moment(d);
    let y = moment()
      .tz("Asia/Kuwait")
      .date(x.date())
      .month(x.month())
      .year(x.year());
    if (start) y.startOf("day");
    else y.endOf("day");
    return y;
  };

  const handleChangeReportingPeriod = ({ from, to }) => {
    setDateRange({
      from: getMillinsInTz(from, true),
      to: getMillinsInTz(to, false),
    });
  };
  // Other

  // Component Render
  return (
    <div className="flex flex-row w-full my-2 p-6 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y ">
      <form
        className="pt-2 flex flex-col"
        onSubmit={handleSubmit(handleChangeReportingPeriod)}
      >
        <p>Select Reporting Period</p>
        <div className="mt-2 flex items-end">
          <Input
            type="date"
            label="From"
            {...register("from", {
              required: "Required!",
            })}
            errors={errors}
            className="mr-2"
          />
          <Input
            type="date"
            label="To"
            {...register("to", {
              required: "Required!",
            })}
            errors={errors}
            className="mr-2"
          />
          <Button text="submit" type="submit"></Button>
        </div>
      </form>
    </div>
  );
};

ReportingPeriodForm.propTypes = {
  /**
   *
   */
};

export default ReportingPeriodForm;
