import { gql } from "graphql-request";

export default gql`
  mutation UpdateSocialLink(
    $id: String!
    $type: String!
    $value: String!
  ) {
    updateSocial(
      socialId: $id
      input: {
        type: $type
        value: $value
      }
    ) {
      id
      type
      value
    }
  }
`;
