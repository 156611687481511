// React
import React from "react";
import PropTypes from "prop-types";

// UI KIT

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name ListViewCol
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ListViewCol = ({ children, className }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <td
      className={
        `px-5 py-5 border-b border-gray-200 bg-white text-sm` + ` ${className}`
      }
    >
      {children}
    </td>
  );
};

ListViewCol.propTypes = {
  /**
   *
   */
};

export default ListViewCol;
