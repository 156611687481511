import ListView from "components/listView/ListView";
import ListViewCol from "components/listView/ListViewCol";
import ListViewRow from "components/listView/ListViewRow";
import React from "react";
import numeral from "numeral";
const headers = [
  { text: "Product" },
  { text: "COLOR" },
  { text: "SIZE" },
  { text: "PRICE" },
  { text: "QUANTITY" },
  { text: "TOTAL" },
];
const OrderProducts = ({ orderProducts = [] }) => {

  const RowComponent = ({
    id,
    nameEn,
    nameAr,
    image,
    quantity,
    price,
    color,
    size,
  }) => {
    return (
      <ListViewRow>
        <ListViewCol>
          <div className="flex items-center">
            <div className="flex-shrink-0 w-32 ">
              <img
                className="w-full h-full rounded object-cover"
                src={image}
                alt=""
              />
            </div>
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">
                {nameEn}<br />({nameAr})
              </p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">{color}</p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">{size}</p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">
                {" "}
                {numeral(price).format("$ 0,0.0")}
              </p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">{quantity}</p>
            </div>
          </div>
        </ListViewCol>
        <ListViewCol>
          <div className="flex items-center">
            <div className="ml-3">
              <p className="text-gray-900 whitespace-no-wrap">
                {numeral(price * quantity).format("$ 0,0.0")}
              </p>
            </div>
          </div>
        </ListViewCol>
      </ListViewRow>
    );
  };
  return (
    <>
      <div className="p-4 mb-4 bg-gray-100 rounded-full">
        <h1 className="ml-2 font-bold uppercase">Order Items</h1>
      </div>
      <ListView headers={headers} showFooter={false}>
        {orderProducts?.map(
          ({
            id,
            productMeta: { nameEn, nameAr },
            media,
            quantity,
            price,
            productVariations,
          }) => {
            const colorObj = productVariations.find(
              (variation) => variation.nameEn === "Color"
            );
            const sizeObj = productVariations.find(
              (variation) => variation.nameEn === "Size"
            );
            return (
              <RowComponent
                {...{
                  id,
                  nameEn,
                  nameAr,
                  quantity,
                  price,
                  color: colorObj.valueEn,
                  size: sizeObj.valueEn,
                  image: media?.path,
                }}
              />
            );
          }
        )}
      </ListView>
    </>
  );
};

export default OrderProducts;
