// React
import React, { useState , forwardRef} from "react";
import PropTypes from "prop-types";
import createClassName from "utils/createClassName";

// UI KIT
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  CheckCircleOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";

// Globals
import MediaListQuery from "graphql/query/media/list";

// Helpers
import { useListViewAPI } from "utils/useListViewAPI";

// Components
import Modal from "components/Modal";
import { ErrorMessage } from "@hookform/error-message";

// Factories

// Screens

// Assets

// Third Parties
import axios from "axios";
import { useQuery, useQueryClient } from "react-query";

// Styles

// Ad-Hoc Components

/**
 * @name Media
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Media = forwardRef(({ isMultiple, value: media, onChange, errors, name, small }, ref) => {
  // Theme & Style Hooks

  // Global State Hooks
  const queryClient = useQueryClient();
  const { data, state } = useListViewAPI("medias", {
    perPage: 10000,
    page: 1,
    search: "",
    query: MediaListQuery,
  });
  // State Hooks
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState({});
  const [showModal, setShowModal] = useState(false);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const uploadFile = (file) => {
    const formData = new FormData();
    setUploadingFiles((ufiles) => [
      ...ufiles,
      {
        file,
        progress: 0,
      },
    ]);

    formData.append("file", file);
    axios
      .request({
        method: "post",
        url: "http://localhost:3010/media/upload",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (p) => {
          setUploadingFiles((ufiles) =>
            ufiles.map((ufile) =>
              ufile.file === file
                ? { ...ufile, progress: (p.loaded * 100) / p.total }
                : ufile
            )
          );
        },
      })
      .then((data) => {
        setUploadingFiles((ufiles) =>
          ufiles.filter((ufile) => ufile.file !== file)
        );
        queryClient.setQueryData(
          [
            "medias",
            {
              perPage: 10000,
              page: 1,
              search: "",
            },
          ],
          (old) => {
            if (Array.isArray(old?.data)) {
              return { ...old, data: [data.data, ...old.data] };
            }
            return old;
          }
        );
        queryClient.invalidateQueries("medias");
      });
  };

  const handleFileUpload = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      uploadFile(file);
    }
  };

  // Other

  const handleSelectMedia = () => {
    if (isMultiple) {
      const m = Array.isArray(media) ? media : [];
      m.push(selectedMedia);
      onChange(m);
    } else {
      onChange(selectedMedia);
    }

    setSelectedMedia({});
    setShowModal(false);
  };

  // Component Render
  return (
    <div ref={ref} className="flex flex-row">
      {showModal && (
        <Modal
          title="Media"
          ok="Select Media"
          disableOk={!Boolean(selectedMedia.id)}
          cancel="Cancel"
          onCancel={() => setShowModal(false)}
          onOk={handleSelectMedia}
        >
          <div>
            <div className="flex flex-col w-full pb-6 items-center justify-center bg-grey">
              <label className="w-full flex flex-col items-center justify-center px-4 py-4 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:text-pink">
                <CloudUploadOutlined className="text-xl mx-4" />
                <span className="mt-2 text-base leading-normal">
                  Select an image to upload
                </span>
                <input
                  type="file"
                  className="hidden"
                  onChange={handleFileUpload}
                  accept=".jpg,.jpeg,.png"
                  multiple
                />
              </label>
              {uploadingFiles.map((ufile) => (
                <div className="w-full  mt-2">
                  <span className="text-xs">{ufile.file.name}</span>
                  <div className="shadow w-full bg-grey-light">
                    <div
                      className="bg-pink-600 text-xs leading-none py-1 text-center text-white"
                      style={{ width: `${ufile.progress}%` }}
                    >
                      {ufile.progress}%
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-row w-full flex-wrap align-center justify-center">
              {data?.data?.map((media) => (
                <div
                  className={`relative mr-4 mb-4 flex-shrink-0 w-44 h-44 overflow-hidden ${
                    selectedMedia.id === media.id
                      ? "bg-yellow-100"
                      : "opacity-50"
                  }`}
                  onClick={() => setSelectedMedia(media)}
                >
                  <img
                    src={media.path}
                    className="object-cover"
                    alt={media.name}
                    title={media.name}
                  />
                  {selectedMedia.id === media.id && (
                    <div className="tooltip absolute bottom-0 right-0 p-2  opacity-70">
                      <CheckCircleOutlined className="p-0" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal>
      )}
      {isMultiple ? (
        Array.isArray(media) &&
        media.map((m) => (
          <div className={`relative mr-4 flex-shrink-0 overflow-hidden ${small ? "w-20 h-20" : "w-28 h-28"}`}>
            <img src={m.path} className="w-full" />
            <button
              data-tip="Delete"
              className="tooltip absolute bottom-0 right-0 p-2  bg-white opacity-70 hover:opacity-100 cursor-pointer"
              onClick={() => onChange(media.filter((ms) => ms.id !== m.id))}
            >
              <DeleteOutlined className="p-0" />
            </button>
          </div>
        ))
      ) : media ? (
        <div className={`relative mr-4 flex-shrink-0 overflow-hidden ${small ? "w-20 h-20" : "w-28 h-28"}`}>
          <img src={media.path} className="w-full" />
          <button
            data-tip="Delete"
            className="tooltip absolute bottom-0 right-0 p-2  bg-white opacity-70 hover:opacity-100 cursor-pointer"
            onClick={() => onChange(undefined)}
          >
            <DeleteOutlined className="p-0" />
          </button>
        </div>
      ) : null}
      {(isMultiple || !media) && (
        <div
          className={`mr-4 flex-shrink-0 cursor-pointer ${small ? "w-20 h-20" : "w-28 h-28"}`}
          onClick={() => setShowModal(true)}
        >
          <div className="w-full h-full object-cover rounded text-3xl flex items-center justify-center border border-gray-500 border-dashed">
            <PlusOutlined />
          </div>
        </div>
      )}
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <div
              className={createClassName(
                "relative",
                "text-xs",
                "my-1",
                "text-red-500"
              )}
            >
              {message}
            </div>
          )}
        />
      )}
    </div>
  );
});

export default Media;
