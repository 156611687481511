import { gql } from "graphql-request";

export default gql`
  mutation DeleteCheckoutNote($id: String!) {
    deleteCheckoutNote(checkoutNoteId: $id) {
      id
      noteEn
      noteAr
      country {
        id
        name
        abbr
      }
    }
  }
`;
