import { gql } from "graphql-request";

export default gql`
  query SingleDeliveryFees($id: String!) {
    deliveryFeesDetails(deliveryFeesId: $id) {
      id
      fees
      area {
        id
        name
      }
    }
  }
`;
