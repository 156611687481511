import { gql } from "graphql-request";

export default gql`
  query areas(
    $take: Int!
    $skip: Int!
    $search: String
    $country: String
  ) {
    areas(
      args: {
        pagination: { take: $take, skip: $skip }
        search: { name: $search }
        sort: { name: 1 }
        filter: { country: $country }
      }
    ) {
      data {
        id
        name
        country {
          id
          name
        }
      }
    }
  }
`;
