// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  PlusOutlined,
  LoadingOutlined,
  MehOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
// UI KIT

// Globals

// Helpers
import { useListViewParams } from "utils/useListViewParams";
import { useListViewState } from "utils/useListViewState";
import { useListViewAPI } from "utils/useListViewAPI";

// Components
import ListView from "components/listView/ListView";
import ListViewRow from "components/listView/ListViewRow";
import ListViewCol from "components/listView/ListViewCol";
import ListViewHeader from "components/listView/ListViewHeader";
import ListViewAddButton from "components/listView/ListViewAddButton";
import ListViewContainer from "components/listView/ListViewContainer";

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import moment from "moment-timezone";
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import MessagesListQuery from "graphql/query/contactUs/list";
import Modal from "components/Modal";
import MarkAsSeenMutation from "graphql/mutation/contactUs/markAsSeen";

import { useMutation, useQueryClient } from "react-query";
import GraphQLClient from "services/api";

// Styles

// Ad-Hoc Components

/**
 * @name Messages
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Messages = ({ setPageTitle }) => {
  // Theme & Style Hooks
  const history = useHistory();
  const queryClient = useQueryClient();
  // Global State Hooks

  // State Hooks
  const {
    page,
    perPage,
    search,
    filter,
    nextPage,
    prevPage,
    handlePerPageChange,
    handleSearchChange,
    handleStatusChange,
    handleVerifiedChange,
  } = useListViewState({
    filterDefault: { status: "ALL" },
  });

  const [message, setMessage] = useState(null);

  // Effect Hooks
  useEffect(() => setPageTitle("Messages"), []);

  // Other Hooks

  // Event Handlers
  const goToAdd = () => history.push("/portal/messages/new");

  // Other
  const { status, data, error, isFetching, refetch } = useListViewAPI(
    "contactUsMessages",
    {
      perPage,
      page,
      search,
      filter,
      query: MessagesListQuery,
      queryFilterEvaluation: (filter) => ({
        status: filter.status === "ALL" ? null : filter.status,
      }),
    }
  );

  const { mutate: markAsSeen } = useMutation(
    ({ id }) =>
      GraphQLClient.request(MarkAsSeenMutation, {
        id,
      }),
    {
      onError: () => {},
      onSuccess: (data) => {
        queryClient.invalidateQueries("contactUsMessages");
      },
    }
  );

  const viewData = data?.data;
  const viewMetadata = data?.metadata;

  const {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
  } = useListViewParams(page, perPage, viewMetadata, viewData?.length);

  const headers = [
    { text: "Name" },
    { text: "Email" },
    { text: "Mobile" },
    { text: "Date" },
    { text: "Seen" },
    { text: "" },
  ];

  const listViewParams = {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
    headers,
    prevPage,
    nextPage,
  };

  const RowComponent = ({
    id,
    name,
    email,
    countryCode,
    mobileNumber,
    datetime,
    status,
    message,
  }) => {
    return (
      <ListViewRow>
        <ListViewCol>
          <div className="flex items-center">
            <p className="text-gray-900 whitespace-no-wrap">{name}</p>
          </div>
        </ListViewCol>

        <ListViewCol>
          <div className="flex items-center">
            <p className="text-gray-900 whitespace-no-wrap">{email}</p>
          </div>
        </ListViewCol>

        <ListViewCol>
          <div className="flex items-center">
            <p className="text-gray-900 whitespace-no-wrap">
              {countryCode}
              {mobileNumber}
            </p>
          </div>
        </ListViewCol>

        <ListViewCol>
          <div className="flex items-center">
            <p className="text-gray-900 whitespace-no-wrap">
              {moment(parseInt(datetime))
                .tz("Asia/Kuwait")
                .format("DD/MM/YYYY, hh:MM A")}
            </p>
          </div>
        </ListViewCol>

        <ListViewCol>
          <span
            className={
              "relative inline-block px-3 py-1 font-semibold leading-tight " +
              (status === "seen" ? "text-green-900" : "text-red-900")
            }
          >
            <span
              aria-hidden
              className={
                "absolute inset-0 opacity-50 rounded-full " +
                (status === "seen" ? "bg-green-200" : "bg-red-200")
              }
            ></span>
            <span className="relative">{status === "seen" ? "Yes" : "No"}</span>
          </span>
        </ListViewCol>
        <ListViewCol>
          <button
            type="button"
            class="text-sm bg-pink-500 text-white p-2 rounded  leading-none flex items-center"
            onClick={() => {
              setMessage(message);
              markAsSeen({ id });
            }}
          >
            View Message
          </button>
        </ListViewCol>
      </ListViewRow>
    );
  };

  // Component Render
  return (
    <>
      {message && (
        <Modal title="Message" ok="Close" onOk={() => setMessage(null)}>
          {message}
        </Modal>
      )}

      <ListViewContainer
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
          header: (
            <>
              <ListViewHeader
                {...{
                  perPage,
                  handlePerPageChange,
                  search,
                  handleSearchChange,
                  hideSearch: true,
                  filters: [
                    {
                      state: filter.status,
                      handleStateChange: handleStatusChange,
                      values: {
                        ALL: "All Messages",
                        seen: "Read",
                        unread: "Unread",
                      },
                    },
                  ],
                }}
              />
            </>
          ),
        }}
      >
        <ListView {...listViewParams}>
          {viewData?.map(
            ({
              id,
              name,
              email,
              countryCode,
              mobileNumber,
              datetime,
              status,
              message,
            }) => (
              <RowComponent
                {...{
                  id,
                  name,
                  email,
                  countryCode,
                  mobileNumber,
                  datetime,
                  status,
                  message,
                }}
              />
            )
          )}
        </ListView>
      </ListViewContainer>
    </>
  );
};

Messages.propTypes = {
  /**
   *
   */
};

export default Messages;
