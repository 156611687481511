// React
import React, { useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";
import Select from "components/form/SelectField";

// Globals
import GraphQLClient from "services/api";

// Helpers
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import { usePicklistAPI } from "utils/usePicklistAPI";
import { AbilityContext } from "utils/ability";

// Components
import Media from "components/form/Media";
import StateBlock from "components/StateBlock";

// Factories

// Screens

// Assets
import SingleUserQuery from "graphql/query/users/single";
import RolesPicklistQuery from "graphql/query/users/picklist.roles";

// Third Parties
import ReactTooltip from "react-tooltip";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { detect } from "detect-browser";

// Styles

// Ad-Hoc Components
import AddUserMutatuion from "graphql/mutation/users/add";
import UpdateUserMutatuion from "graphql/mutation/users/update";
import DeleteBrandMutatuion from "graphql/mutation/brand/delete";

/**
 * @name UserDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const UserDetails = ({ setPageTitle, setPageActionsMenu }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);
  const isAddForm = () => id === "new";
  const browser = detect();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      status: "Active",
    },
  });

  const currentStatus = watch("status");
  const currentPassword = watch("password");

  const { data: rolesResults } = usePicklistAPI("roles", {
    query: RolesPicklistQuery,
  });

  const roles = useMemo(() => rolesResults?.data, [
    rolesResults,
  ]);

  let { data, status, refetch } = useSingleEntityAPI(
    "user",
    {
      id,
      query: SingleUserQuery,
    },
    {
      onSuccess: ({ data }) => {
        setValue("id", data.id);
        setValue("name", data.name);
        setValue("email", data.email);
        setValue("password", "");
        setValue("retypePassword", "");
        setValue("role", data.role?.id || "");
        setValue("status", data.isActive ? "Active" : "Inactive");
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled: !isAddForm(),
    }
  );

  if (isAddForm()) status = "success";

  // const emptyData = {};
  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);

  const { mutate: submitRequest, isLoading: isSubmitting } = useMutation(
    ({ id, name, email, password, role, status }) =>
      GraphQLClient.request(
        isAddForm() ? AddUserMutatuion : UpdateUserMutatuion,
        {
          id,
          name,
          email,
          password: password !== "" ? password : null,
          roleId: role,
          isActive: status === "Active" ? true : false,
        }
      ),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries("user");
        if (isAddForm()) {
          addToast("User added successfully!", { appearance: "success" });
          history.push("/portal/users");
        } else {
          addToast("User updated successfully!", { appearance: "success" });
        }
      },
    }
  );

  const { mutate: deleteRequest, isLoading: isDeleting } = useMutation(
    ({ id }) =>
      GraphQLClient.request(DeleteBrandMutatuion, {
        id,
      }),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries("user");

        addToast("User deleted successfully!", { appearance: "success" });
        history.push("/portal/users");
      },
    }
  );

  // State Hooks
  useEffect(() => {
    setPageTitle("User Details");
    //   if (currentDataObject) {
    //     setValue("nameEn", currentDataObject.nameEn);
    //     setValue("nameAr", currentDataObject.nameAr);
    //     setValue("media", currentDataObject.media);
    //     setValue("status", currentDataObject.isActive ? "Active" : "Inactive");
    //   }
  }, []);

  useEffect(() => {
    if (currentDataObject.id) {
      setValue("id", currentDataObject.id);
      setValue("name", currentDataObject.name);
      setValue("email", currentDataObject.email);
      setValue("password", "");
      setValue("retypePassword", "");
      setValue("role", currentDataObject.role?.id || "");
      setValue("status", currentDataObject.isActive ? "Active" : "Inactive");
    }
  }, [currentDataObject]);

  useEffect(() => {
    if (!isAddForm() && currentDataObject.id) {
      if (ability.can('usersDelete', 'users')) {
        setPageActionsMenu([
          {
            text: "Delete User",
            isDanger: true,
            callback: () => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm("Are you sure to delete. This action can't be undone!")
              ) {
                deleteRequest({ id });
              }
            },
          },
        ]);
      }
    }

    return () => {
      setPageActionsMenu([]);
    };
  }, [id, currentDataObject]);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/users");
    }
  };

  // Other

  // Component Render
  return (
    <div class="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        class="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined class="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
        }}
      >
        <form
          onSubmit={handleSubmit(submitRequest)}
          class="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y"
        >
          <input type="hidden" {...register("id")} />
          <div class="flex-1">
            <div class="p-4 bg-gray-100 rounded-full">
              <h1 class="ml-2 font-bold uppercase">Basic Information</h1>
            </div>
            <div class="p-4 px-6 flex flex-col">
              <div class="flex">
                <div class="mb-4 pr-2 flex-col w-1/2">
                  <div class="mb-4">
                    <Input
                      name="name"
                      label="User Name"
                      autocomplete={ browser.name==='chrome' ? "new-password" : "off" }
                      placeholder="User Name"
                      {...register("name", {
                        required: "User name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div class="mb-4">
                    <Input
                      name="email"
                      label="Email"
                      autocomplete={ browser.name==='chrome' ? "new-password" : "off" }
                      placeholder="Email"
                      {...register("email", {
                        required: "Email is required!",
                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div class="mb-4">
                    <Input
                      name="password"
                      label="Password"
                      autocomplete={ browser.name==='chrome' ? "new-password" : "off" }
                      type="password"
                      {...register("password", {
                        required: isAddForm() ? "Password is required!" : false,
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div class="mb-4">
                    <Input
                      name="retypePassword"
                      label="Retype Password"
                      autocomplete={ browser.name==='chrome' ? "new-password" : "off" }
                      type="password"
                      {...register("retypePassword", {
                        required: currentPassword !== '' ? "You must retype password!" : false,
                        validate: value => value === currentPassword || "Password doesn't match"
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div class="mb-4">
                      <Select
                        name="role"
                        label="Role"
                        placeholder="Role"
                        options={
                          roles
                            ? roles.reduce(
                                (a, c) => ({
                                  ...a,
                                  [c.id]: `${c.name}`,
                                }),
                                {}
                              )
                            : {}
                        }
                        {...register("role", {
                          required: "Role is required!",
                        })}
                        errors={errors}
                      />
                    </div>
                  <div class="">
                    <span class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                      Status:
                    </span>
                    <br />
                    <span
                      className={
                        "relative text-xs inline-block px-3 py-1 font-semibold leading-tight " +
                        (currentStatus === "Active"
                          ? "text-green-900"
                          : "text-red-900")
                      }
                    >
                      <span
                        aria-hidden
                        className={
                          "absolute inset-0 opacity-50 rounded-full " +
                          (currentStatus === "Active"
                            ? "bg-green-200"
                            : "bg-red-200")
                        }
                      ></span>
                      <span class="relative">
                        <select
                          class="bg-transparent font-semibold"
                          {...register("status", {
                            required: "Brand status is required!",
                          })}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <Button
              text="Save"
              className="w-full"
              color="text-white bg-pink-600 hover:bg-pink-700"
              SuffixIcon={!isSubmitting ? CheckCircleOutlined : LoadingOutlined}
              type="submit"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </StateBlock>
    </div>
  );
};

UserDetails.propTypes = {
  /**
   *
   */
};

export default UserDetails;
