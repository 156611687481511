import jwt_decode from "jwt-decode";
function JWT(token) {
  this.token = token;
  try {
    this.data = jwt_decode(token);
  } catch (e) {
    this.data = undefined;
  }
}

JWT.prototype.isValidToken = function () {
  return Boolean(this.data);
};

JWT.prototype.millisToExpire = function () {
  if (this.data?.exp && parseInt(this.data?.exp) > 0) {
    const tte = this.data.exp * 1000 - Date.now();
    if (tte > 0) return tte;
  }

  return 0;
};

JWT.prototype.isExpired = function () {
  if (this.millisToExpire() > 0) return false;

  return true;
};

export default JWT;
