// React
import React from "react";
import PropTypes from "prop-types";

// UI KIT

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets
import Logo from "assets/logo-icon.png";

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name layout
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const layout = ({ heading, children }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-200">
      <img src={Logo} className="w-full max-w-md mb-8 px-32" />
      <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
        <div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
          {heading}
        </div>
        <div className="mt-10">{children}</div>
      </div>
    </div>
  );
};

layout.propTypes = {
  /**
   *
   */
};

export default layout;
