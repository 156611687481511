// React
import React from "react";
import PropTypes from "prop-types";

// UI KIT
import { PlusOutlined } from "@ant-design/icons";

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name ListViewAddButton
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ListViewAddButton = ({ children, ...otherProps }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <button
      {...otherProps}
      className="flex justify-center items-center tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
    >
      <PlusOutlined className="p-0 mr-1" />
      <span>{children}</span>
    </button>
  );
};

ListViewAddButton.propTypes = {
  /**
   *
   */
};

export default ListViewAddButton;
