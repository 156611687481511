import { gql } from "graphql-request";

export default gql`
  mutation UpdatePage(
    $id: String!
    $contentAr: String!
    $contentEn: String!
  ) {
    updatePage(
      pageId: $id
      input: {
        contentAr: $contentAr
        contentEn: $contentEn
      }
    ) {
      id
      titleAr
      titleEn
      contentAr
      contentEn
    }
  }
`;
