import { gql } from "graphql-request";

export default gql`
  mutation UpdateHomeBannerImage(
    $id: String!
    $mediaId: String!
    $isActive: Boolean!
  ) {
    updateHomeBannerImage(
      homeBannerImageId: $id
      input: {
        mediaId: $mediaId
        isActive: $isActive
      }
    ) {
      id
      media {
        id
        name
        path
      }
    }
  }
`;
