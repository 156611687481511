// React
import React, { useState } from "react";
import {
  TeamOutlined,
  ContainerOutlined,
  SkinOutlined,
  DollarOutlined,
  AppleOutlined,
  AppstoreOutlined,
  LoadingOutlined,
  ProfileOutlined,
  FilterOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import createClassName from "utils/createClassName";

import moment from "moment-timezone";

import { useForm } from "react-hook-form";
// UI KIT

// Globals
import DynamicTotalsQuery from "graphql/query/dashboard/dynamic.totals";
import { useSimpleQueryAPI } from "utils/useSimpleQueryAPI";
// Helpers

// Components
import StatisticsCard from "components/StatisticsCard";
// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import Input from "components/form/InputField";
import Button from "components/form/Button";

// Styles

// Ad-Hoc Components

/**
 * @name StatisticCards2
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const StatisticCards2 = ({ className }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const [dateRange, setDateRange] = useState({
    from: moment().tz("Asia/Kuwait").startOf("month"),
    to: moment().tz("Asia/Kuwait").endOf("month"),
  });

  const [changeReportingPeriod, setChangeReportingPeriod] = useState(false);

  // State Hooks

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      from: moment(dateRange.from).tz("Asia/Kuwait").format("YYYY-MM-DD"),
      to: moment(dateRange.to).tz("Asia/Kuwait").format("YYYY-MM-DD"),
    },
  });

  const { data, isLoading, isError, refetch } = useSimpleQueryAPI(
    "dynamicTotals",
    DynamicTotalsQuery,
    {
      from: (dateRange.from / 1000).toString(),
      to: (dateRange.to / 1000).toString(),
    }
  );
  // Effect Hooks

  // Other Hooks

  // Event Handlers

  const getMillinsInTz = (d, start) => {
    const x = moment(d);
    let y = moment().tz("Asia/Kuwait").date(x.date()).month(x.month()).year(x.year());
    if (start) y.startOf("day");
    else y.endOf("day");
    return y;
  };

  const handleChangeReportingPeriod = ({ from, to }) => {
    setChangeReportingPeriod(false);
    setDateRange({
      from: getMillinsInTz(from, true),
      to: getMillinsInTz(to, false),
    });
  };
  // Other

  // Component Render
  return (
    <div className={`flex items-center ${className}`}>
      <div className="py-2 w-full h-full ">
        <div className=" overflow-hidden transition-all duration-500 ease-in-out flex flex-col flex-grow bg-white shadow-sm rounded p-4 h-full w-full">
          <div className="flex flex-row flex-grow ">
            <div
              className="flex flex-col items-center justify-center flex-grow"
              title={data?.totalCustomers}
            >
              <div
                className={createClassName(
                  "flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl"
                )}
              >
                <TeamOutlined style={{ fontSize: 24 }} />
              </div>
              <div className="flex flex-col flex-grow items-center">
                <div className="text-sm text-gray-500">Customers</div>
                <div className="font-bold text-lg">
                  {isLoading ? (
                    <LoadingOutlined />
                  ) : isError ? (
                    <button onClick={refetch}>Retry</button>
                  ) : (
                    numeral(data.totalCustomers).format("0 a")
                  )}
                </div>
              </div>
            </div>
            <div
              className="flex flex-col items-center justify-center flex-grow"
              title={data?.totalOrders}
            >
              <div
                className={createClassName(
                  "flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl"
                )}
              >
                <ProfileOutlined style={{ fontSize: 24 }} />
              </div>
              <div className="flex flex-col flex-grow items-center">
                <div className="text-sm text-gray-500">Orders</div>
                <div className="font-bold text-lg">
                  {isLoading ? (
                    <LoadingOutlined />
                  ) : isError ? (
                    <button onClick={refetch}>Retry</button>
                  ) : (
                    numeral(data.totalOrders).format("0 a")
                  )}
                </div>
              </div>
            </div>
            <div
              className="flex flex-col items-center justify-center flex-grow"
              title={data?.totalSales}
            >
              <div
                className={createClassName(
                  "flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-xl"
                )}
              >
                <DollarOutlined style={{ fontSize: 24 }} />
              </div>
              <div className="flex flex-col flex-grow items-center">
                <div className="text-sm text-gray-500">Sales</div>
                <div className="font-bold text-lg">
                  {isLoading ? (
                    <LoadingOutlined />
                  ) : isError ? (
                    <button onClick={refetch}>Retry</button>
                  ) : (
                    numeral(data.totalSales).format("$ 0 a")
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="flex text-gray-500 text-sm">
            <span>
              <CalendarOutlined /> Reporting Period:{" "}
              {moment(dateRange.from).tz("Asia/Kuwait").format("DD MMM, YYYY")}{" "}
              - {moment(dateRange.to).tz("Asia/Kuwait").format("DD MMM, YYYY")}
            </span>
            <button
              className="ml-auto"
              onClick={() => setChangeReportingPeriod((i) => !i)}
            >
              <FilterOutlined /> Change
            </button>
          </div>
          {changeReportingPeriod && (
            <form
              className="pt-2 flex flex-col"
              onSubmit={handleSubmit(handleChangeReportingPeriod)}
            >
              <hr className="py-2" />
              <p>Change Reporting Period</p>
              <div className="mt-2 flex justify-between">
                <Input
                  type="date"
                  label="From"
                  {...register("from", {
                    required: "Required!",
                  })}
                  errors={errors}
                />
                <Input
                  type="date"
                  label="To"
                  {...register("to", {
                    required: "Required!",
                  })}
                  errors={errors}
                />
              </div>
              <Button
                text="submit"
                type="submit"
                className={"ml-auto"}
              ></Button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

StatisticCards2.propTypes = {
  /**
   *
   */
};

export default StatisticCards2;
