import { gql } from "graphql-request";

export default gql`
  query SingleArea($id: String!) {
    area(areaId: $id) {
      id
      name
      country {
        id
        name
      }
    }
  }
`;
