// React
import React, { useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";
import Select from "components/form/SelectField";

// Globals
import GraphQLClient from "services/api";

// Helpers
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import { AbilityContext } from "utils/ability";

// Components
import Media from "components/form/Media";
import StateBlock from "components/StateBlock";

// Factories

// Screens

// Assets
import SingleBrandQuery from "graphql/query/brands/single";

// Third Parties
import ReactTooltip from "react-tooltip";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { ToastProvider, useToasts } from "react-toast-notifications";

// Styles

// Ad-Hoc Components
import AddBrandMutatuion from "graphql/mutation/brand/add";
import UpdateBrandMutatuion from "graphql/mutation/brand/update";
import DeleteBrandMutatuion from "graphql/mutation/brand/delete";

/**
 * @name BrandDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const BrandDetails = ({ setPageTitle, setPageActionsMenu }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);
  const isAddForm = () => id === "new";

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      status: "Active",
    },
  });

  const currentStatus = watch("status");

  let { data, status, refetch } = useSingleEntityAPI(
    "brand",
    {
      id,
      query: SingleBrandQuery,
    },
    {
      onSuccess: ({ data }) => {
        setValue("id", data.id);
        setValue("nameEn", data.nameEn);
        setValue("nameAr", data.nameAr);
        setValue("media", data.media);
        setValue("status", data.isActive ? "Active" : "Inactive");
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled: !isAddForm(),
    }
  );

  if (isAddForm()) status = "success";

  // const emptyData = {};
  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);

  const { mutate: submitRequest, isLoading: isSubmitting } = useMutation(
    ({ id, nameEn, nameAr, media, status }) =>
      GraphQLClient.request(
        isAddForm() ? AddBrandMutatuion : UpdateBrandMutatuion,
        {
          id,
          nameEn,
          nameAr,
          mediaId: media.id,
          isActive: status === "Active" ? true : false,
        }
      ),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("brands");
        queryClient.invalidateQueries("brand");
        if (isAddForm()) {
          addToast("Brand added successfully!", { appearance: "success" });
          history.push("/portal/brands");
        } else {
          addToast("Brand updated successfully!", { appearance: "success" });
        }
      },
    }
  );

  const { mutate: deleteRequest, isLoading: isDeleting } = useMutation(
    ({ id }) =>
      GraphQLClient.request(DeleteBrandMutatuion, {
        id,
      }),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("brands");
        queryClient.invalidateQueries("brand");

        addToast("Brand deleted successfully!", { appearance: "success" });
        history.push("/portal/brands");
      },
    }
  );

  // State Hooks
  useEffect(() => {
    setPageTitle("Brand Details");
    //   if (currentDataObject) {
    //     setValue("nameEn", currentDataObject.nameEn);
    //     setValue("nameAr", currentDataObject.nameAr);
    //     setValue("media", currentDataObject.media);
    //     setValue("status", currentDataObject.isActive ? "Active" : "Inactive");
    //   }
  }, []);

  useEffect(() => {
    if (currentDataObject.id) {
      setValue("id", currentDataObject.id);
      setValue("nameEn", currentDataObject.nameEn);
      setValue("nameAr", currentDataObject.nameAr);
      setValue("media", currentDataObject.media);
      setValue("status", currentDataObject.isActive ? "Active" : "Inactive");
    }
  }, [currentDataObject]);

  useEffect(() => {
    if (!isAddForm() && currentDataObject.id) {
      if (ability.can('brandsDelete', 'brands')) {
        setPageActionsMenu([
          {
            text: "Delete Brand",
            isDanger: true,
            callback: () => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm("Are you sure to delete. This action can't be undone!")
              ) {
                deleteRequest({ id });
              }
            },
          },
        ]);
      }
    }

    return () => {
      setPageActionsMenu([]);
    };
  }, [id, currentDataObject]);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/customers");
    }
  };

  // Other

  // Component Render
  return (
    <div className="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined className="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
        }}
      >
        <form
          onSubmit={handleSubmit(submitRequest)}
          className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y"
        >
          <input type="hidden" {...register("id")} />
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
            </div>
            <div className="p-4 px-6 flex flex-col">
              <div className="mb-4 w-full flex">
                <Controller
                  name="media"
                  control={control}
                  rules={{ required: "Brand image is required!" }}
                  render={({ field }) => <Media {...field} errors={errors} />}
                />
              </div>
              <hr className="color-gray-500 my-4" />
              <div className="flex">
                <div className="mb-4 pr-2 flex-col w-1/2">
                  <div className="mb-4">
                    <Input
                      name="nameEn"
                      label="Brand Name (EN)"
                      placeholder="Brand Name (EN)"
                      {...register("nameEn", {
                        required: "Brand english name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      name="nameAr"
                      label="Brand Name (AR)"
                      placeholder="Brand Name (AR)"
                      {...register("nameAr", {
                        required: "Brand arabic name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className="">
                    <span className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                      Status:
                    </span>
                    <br />
                    <span
                      className={
                        "relative text-xs inline-block px-3 py-1 font-semibold leading-tight " +
                        (currentStatus === "Active"
                          ? "text-green-900"
                          : "text-red-900")
                      }
                    >
                      <span
                        aria-hidden
                        className={
                          "absolute inset-0 opacity-50 rounded-full " +
                          (currentStatus === "Active"
                            ? "bg-green-200"
                            : "bg-red-200")
                        }
                      ></span>
                      <span className="relative">
                        <select
                          className="bg-transparent font-semibold"
                          {...register("status", {
                            required: "Brand status is required!",
                          })}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <Button
              text="Save"
              className="w-full"
              color="text-white bg-pink-600 hover:bg-pink-700"
              SuffixIcon={!isSubmitting ? CheckCircleOutlined : LoadingOutlined}
              type="submit"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </StateBlock>
    </div>
  );
};

BrandDetails.propTypes = {
  /**
   *
   */
};

export default BrandDetails;
