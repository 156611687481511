import { gql } from "graphql-request";

export default gql`
  query QuantitySoldByProduct($from: String!, $to: String!) {
    quantitySoldByProduct(dateRange: { from: $from, to: $to }) {
      quantitySold
      nameEn
      nameAr
    }
  }
`;
