import { gql } from 'graphql-request';

export default gql`
    query HomeBannerImagesList(
        $take: Int!
        $skip: Int!
        $isActive: String
    ) {
        homeBannerImages (
            args: {
                pagination: { take: $take, skip: $skip }
                filter: { isActive: $isActive }
            }
        ) {
            data {
                id
                media {
                  id
                  name
                  path
                }
                isActive
            }
            metadata {
                pagination {
                    total
                    take
                    skip
                }
                filter
            }
        }
    }
`;