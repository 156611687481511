import { gql } from "graphql-request";

export default gql`
  mutation AddDeliveryFees($fees: Float!, $areaId: String!) {
    addDeliveryFees(input: { fees: $fees, areaId: $areaId }) {
      id
      fees
      area {
        id
        name
      }
    }
  }
`;
