import { gql } from "graphql-request";

export default gql`
  mutation UpdateCategory(
    $id: String!
    $nameEn: String!
    $nameAr: String!
    $mediaId: String!
    $isActive: Boolean!
    $bulkDiscountPercentage: Float
    $parentCategoryId: String
    $isHot: Boolean!
    $isCustomized: Boolean!
  ) {
    updateCategory(
      categoryId: $id
      input: {
        nameEn: $nameEn
        nameAr: $nameAr
        mediaId: $mediaId
        isActive: $isActive
        bulkDiscountPercentage: $bulkDiscountPercentage
        categoryId: $parentCategoryId
        isHot: $isHot
        isCustomized: $isCustomized
      }
    ) {
      id
      nameEn
      nameAr
      isHot
      isCustomized
      bulkDiscountPercentage
      isActive
      category {
        id
        nameEn
        nameAr
        isActive
      }
      media {
        id
        name
        path
      }
    }
  }
`;
