import { gql } from "graphql-request";

export default gql`
  query SinglePage($id: String!) {
    page(pageId: $id) {
      id
      titleAr
      titleEn
      contentAr
      contentEn
    }
  }
`;
