import { gql } from "graphql-request";

export default gql`
  query orders($take: Int!, $skip: Int!, $search: String, $status: String) {
    orders(
      args: {
        pagination: { take: $take, skip: $skip }
        search: { number: $search }
        filter: { status: $status }
      }
    ) {
      data {
        id
        number
        status
        createdAt
        total
        customer {
          id
          firstName
          lastName
          email
          mobileNumber
          mobileCountryCode
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        search
        filter
      }
    }
  }
`;
