import { gql } from "graphql-request";

export default gql`
  query SoldProductsByCategory($from: String!, $to: String!) {
    soldProductsByCategory(dateRange: { from: $from, to: $to }) {
      totalSoldProducts
      nameEn
      nameAr
      mediaPath
    }
  }
`;
