import { gql } from "graphql-request";

export default gql`
  query SingleHomeBannerImage($id: String!) {
    homeBannerImage(homeBannerImageId: $id) {
      id
      isActive
      media {
        id
        path
      }
    }
  }
`;
