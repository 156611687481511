// React
import React, { useRef } from "react";
import PropTypes from "prop-types";
import createClassName from "utils/createClassName";
import { DownOutlined } from "@ant-design/icons";

// UI KIT

// Globals

// Helpers

// Components
import { ErrorMessage } from "@hookform/error-message";

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name Select
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Select = (
  {
    style,
    className,
    name,
    label,
    Icon,
    placeholder,
    small,
    options,
    optionGroups,
    errors,
    ...inputProps
  },
  ref
) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks
  const selectEl = useRef(null);

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div
      className={createClassName("flex", "flex-col", className)}
      style={style}
    >
      {label && (
        <label
          for={name}
          className={createClassName(
            "mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
          )}
        >
          {label}:
        </label>
      )}

      <div className={createClassName("relative")}>
        {Icon && (
          <div
            className={createClassName(
              "inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
            )}
          >
            {
              <Icon className="h-6 w-6 text-sm sm:text-base flex justify-center items-center" />
            }
          </div>
        )}

        {options && (
          <select
            id={name}
            name={name}
            className={createClassName(
              small
                ? "text-sm px-2 py-1 text-center"
                : "text-sm sm:text-base px-4 py-2 w-full",
              "appearance-none placeholder-gray-500 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-400",
              Icon ? "pl-10" : ""
            )}
            placeholder={placeholder}
            {...inputProps}
            ref={ref}
          >
            {Object.keys(options).map((key) => (
              <option value={key}>{options[key]}</option>
            ))}
          </select>
        )}
        {optionGroups && (
          <select
            id={name}
            name={name}
            className={createClassName(
              small
                ? "text-sm px-2 py-1 text-center"
                : "text-sm sm:text-base px-4 py-2 w-full",
              "appearance-none placeholder-gray-500 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-400",
              Icon ? "pl-10" : ""
            )}
            placeholder={placeholder}
            {...inputProps}
            ref={ref}
          >
            {Object.keys(optionGroups).map((optionGroup) => (
              <optgroup label={optionGroup}>
                {optionGroups[optionGroup].map((option) => (
                  <option value={option.key}>{option.label}</option>
                ))}
              </optgroup>
            ))}
          </select>
        )}
      </div>
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <div
              className={createClassName(
                "relative",
                "text-xs",
                "my-1",
                "text-red-500",
                "whitespace-nowrap"
              )}
            >
              {message}
            </div>
          )}
        />
      )}
    </div>
  );
};

Select.propTypes = {
  /**
   *
   */
};

export default React.forwardRef(Select);
