import { gql } from "graphql-request";

export default gql`
  query {
    myUser {
      id
      name
      email
      role {
        id
        name
        permissions {
          id
          model
          operation
        }
      }
    }
  }
`;
