import GraphQLClient from "services/api";
import { useQuery, useQueryClient } from "react-query";

export const useSingleEntityAPI = (key, { id, query }, options) => {
  const fetchData = async ({ queryKey }) => {
    const [_key, { id }] = queryKey;
    const { [key]: data } = await GraphQLClient.request(query, {
      id,
    });
    return { data };
  };

  const queryClient = useQueryClient();
  //   queryClient.prefetchQuery([key, { id }], fetchData);

  return useQuery([key, { id }], fetchData, options);
};
