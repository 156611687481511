import { gql } from "graphql-request";

export default gql`
  mutation login($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password }) {
      token
      refreshToken
    }
  }
`;
