// React
import React from "react";
import PropTypes from "prop-types";
import createClassName from "utils/createClassName";

// UI KIT

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties
import { ErrorMessage } from "@hookform/error-message";

// Styles

// Ad-Hoc Components

/**
 * @name Input
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Input = (
  {
    style,
    className,
    name,
    type,
    label,
    PrefixIcon,
    SuffixIcon,
    placeholder,
    small,
    value,
    note,
    errors,
    ...otherProps
  },
  ref
) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div
      className={createClassName("flex", "flex-col", className)}
      style={style}
    >
      {label && (
        <label
          for={name}
          className={createClassName(
            "mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
          )}
        >
          {label}:
        </label>
      )}

      <div className={createClassName("relative")}>
        {PrefixIcon && (
          <div
            className={createClassName(
              "inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
            )}
          >
            {
              <PrefixIcon className="h-6 w-6 text-sm sm:text-base flex justify-center items-center" />
            }
          </div>
        )}

        {type!=="textarea" && 
          <input
            id={name}
            type={type}
            name={name}
            className={createClassName(
              small ? "text-sm px-2 py-1 w-50" : "text-sm sm:text-base w-auto min-w-full px-4 py-2",
              "placeholder-gray-500 rounded-lg border border-gray-400  focus:outline-none focus:border-blue-400",
              PrefixIcon ? "pl-10" : ""
            )}
            placeholder={placeholder}
            value={value}
            ref={ref}
            {...otherProps}
          />
        }

        {type==="textarea" && 
          <textarea
            id={name}
            name={name}
            className={createClassName(
              small ? "text-sm px-2 py-1 w-50" : "text-sm sm:text-base w-full px-4 py-2",
              "placeholder-gray-500  rounded-lg border border-gray-400  focus:outline-none focus:border-blue-400",
              PrefixIcon ? "pl-10" : ""
            )}
            placeholder={placeholder}
            value={value}
            ref={ref}
            {...otherProps}
          />
        }

        {SuffixIcon && (
          <div
            className={createClassName(
              "inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 text-gray-400"
            )}
          >
            {
              <SuffixIcon className="h-6 w-6 text-sm sm:text-base flex justify-center items-center" />
            }
          </div>
        )}
      </div>
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <div
              className={createClassName(
                "relative",
                "text-xs",
                "my-1",
                "text-red-500"
              )}
            >
              {message}
            </div>
          )}
        />
      )}

      {note && (
        <div
          className={createClassName(
            "relative",
            "text-xs",
            "my-1",
            "text-gray-500"
          )}
        >
          {note}
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  /**
   *
   */
};

export default React.forwardRef(Input);
