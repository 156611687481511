// React
import React from "react";
import PropTypes from "prop-types";

// UI KIT

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name ListView
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ListView = ({
  headers,
  firstRecordIndex,
  lastRecordIndex,
  totalRecords,
  isFirstPage,
  isLastPage,
  prevPage,
  nextPage,
  children,
  showFooter = true,
}) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-2 overflow-x-auto">
      <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              {headers.map((header) => (
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {header.text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
        {showFooter && (
          <div className="px-5 py-5 bg-white border-t flex flex-row xs:flex-row items-center xs:justify-between">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {totalRecords === 0 ? 0 : firstRecordIndex} to{" "}
              {lastRecordIndex} of {totalRecords} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0 ml-auto">
              <button
                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l disabled:text-gray-400 disabled:cursor-not-allowed disabled:bg-gray-300"
                onClick={prevPage}
                disabled={isFirstPage}
              >
                Prev
              </button>
              <button
                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r disabled:text-gray-400 disabled:cursor-not-allowed disabled:bg-gray-300"
                onClick={nextPage}
                disabled={isLastPage}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ListView.propTypes = {
  /**
   *
   */
};

export default ListView;
