// React
import React from "react";
import PropTypes from "prop-types";
import {
  UnlockOutlined,
  DoubleRightOutlined,
  MailOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";

// Globals

// Helpers

// Components

// Factories

// Screens
import Layout from "./PageLayout";

// Assets

// Third Parties
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

// Styles

// Ad-Hoc Components

/**
 * @name ForgetPassword
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ForgetPassword = ({}) => {
  const history = useHistory();

  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const goToLogin = () => {
    history.replace("/auth/login");
  };

  const resetPassword = () => {
    history.push("/auth/resetpassword");
  };

  // Component Render
  return (
    <Layout heading="Forget Password?">
      <form action="#">
        <Input
          name="email"
          type="email"
          label="Email Address"
          placeholder="Email Address"
          className="mb-6"
          PrefixIcon={MailOutlined}
        />
        <div className="flex items-center mb-6 -mt-4">
          <p className="text-xs">
            Enter your email address and we will be sending a verification code
            to verify your operation.
          </p>
        </div>

        <Button
          text="Next"
          className="w-full"
          color="text-white bg-pink-600 hover:bg-pink-700"
          SuffixIcon={DoubleRightOutlined}
          onClick={resetPassword}
        />

        <Button
          text="Back To Login"
          color="text-pink-600 hover:text-pink-700"
          basic
          bold
          PrefixIcon={UnlockOutlined}
          onClick={goToLogin}
        />
      </form>
    </Layout>
  );
};

ForgetPassword.propTypes = {
  /**
   *
   */
};

export default ForgetPassword;
