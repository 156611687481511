// React
import React from "react";
import PropTypes from "prop-types";

// UI KIT

// Globals

// Helpers

// Components
import StateBlock from "components/StateBlock";

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name ListViewContainer
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ListViewContainer = ({
  header,
  isLoading,
  isError,
  isSuccess,
  refetch,
  children,
}) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div className="container mx-auto w-full">
      {header}
      <div className="py-2">
        <StateBlock {...{ isLoading, isError, isSuccess, refetch }}>
          {children}
        </StateBlock>
      </div>
    </div>
  );
};

ListViewContainer.propTypes = {
  /**
   *
   */
};

export default ListViewContainer;
