import { gql } from "graphql-request";

export default gql`
  query SingleProduct($id: String!) {
    product(productId: $id) {
      productMeta {
        id
        nameEn
        nameAr
        descriptionEn
        descriptionAr
      }
      productSpecs {
        id
        nameEn
        nameAr
        valueEn
        valueAr
      }
      category {
        id
        nameAr
        nameEn
      }
      brand {
        id
        nameAr
        nameEn
        media {
          id
          name
          path
        }
      }
      productVariations {
        id
        media {
          id
          name
          path
        }
        price
        offerPrice
        isSearchable
        isActive
        stock
        isFeatured
        variations {
          nameEn
          nameAr
          valueEn
          valueAr
        }
      }
    }
  }
`;
