import { gql } from "graphql-request";

export default gql`
  mutation AddHomeSliderImage(
    $mediaId: String!
    $order: Int!
    $isActive: Boolean!
  ) {
    addHomeSliderImage(
      input: {
        mediaId: $mediaId
        isActive: $isActive
        order: $order
      }
    ) {
      id
      order
      media {
        id
        name
        path
      }
    }
  }
`;
