// React
import React, { useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";
import Select from "components/form/SelectField";

// Globals
import GraphQLClient from "services/api";

// Helpers
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import { usePicklistAPI } from "utils/usePicklistAPI";
import { AbilityContext } from "utils/ability";

// Components
import Media from "components/form/Media";
import StateBlock from "components/StateBlock";

// Factories

// Screens

// Assets
import SinglePromoCodeQuery from "graphql/query/promos/single";
import CustomersPicklistQuery from "graphql/query/promos/picklist.customer";

// Third Parties
import ReactTooltip from "react-tooltip";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { ToastProvider, useToasts } from "react-toast-notifications";
import moment from "moment-timezone";

// Styles

// Ad-Hoc Components
import AddPromoMutation from "graphql/mutation/promos/add";
import UpdatePromoMutation from "graphql/mutation/promos/update";
import DeletePromoMutation from "graphql/mutation/promos/delete";
import AssignToCustomerMutation from "graphql/mutation/promos/assignToCustomer";

/**
 * @name PromoCodeDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const PromoCodeDetails = ({ setPageTitle, setPageActionsMenu }) => {
  // Theme & Style Hooks

  // Global State Hooks
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);
  const isAddForm = () => id === "new";

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    unregister,
  } = useForm({
    defaultValues: {
      status: "Active",
      isPrivateSelector: "NO"
    },
  });

  const discountType = watch("discountType");
  const isPrivateSelector = watch("isPrivateSelector");
  const validFrom = watch("validFrom");
  const currentStatus = watch("status");
  const currentCustomer = watch("customer");

  const { data: customersResult } = usePicklistAPI("customers", {
    query: CustomersPicklistQuery,
  });

  const customers = useMemo(() => customersResult?.data, [
    customersResult,
  ]);

  let { data, status, refetch } = useSingleEntityAPI(
    "promoCode",
    {
      id,
      query: SinglePromoCodeQuery,
    },
    {
      onSuccess: ({ data }) => {
        setValue("id", data.id);
        setValue("name", data.name);
        setValue("discountType", data.discountType);
        setValue("value", data.value);
        setValue("isPrivateSelector", data.isPrivate ? "YES" : "NO");
        setValue("validFrom", moment(parseInt(data.validFrom)).tz("Asia/Kuwait").format("YYYY-MM-DDTHH:mm"));
        setValue("validTo", moment(parseInt(data.validTo)).tz("Asia/Kuwait").format());
        setValue("status", data.isActive ? "Active" : "Inactive");
        setValue("customer", data.customer?.id || "")
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled: !isAddForm(),
    }
  );

  if (isAddForm()) status = "success";

  useEffect(() => {
    if (isPrivateSelector === "NO") {
      unregister("customer");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrivateSelector]);

  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);
  
  const { mutate: submitAssignToCustomer } = useMutation(({ promoCodeId, customerId }) => GraphQLClient.request(AssignToCustomerMutation, {
    promoCodeId,
    customerId
  }));

  const { mutate: submitRequest, isLoading: isSubmitting } = useMutation(
    ({ id, name, discountType, value, isPrivateSelector, validFrom, validTo, status }) =>
      GraphQLClient.request(
        isAddForm() ? AddPromoMutation : UpdatePromoMutation,
        {
          id,
          name,
          discountType,
          value: parseFloat(value),
          isPrivate: isPrivateSelector === "YES" ? true : false,
          validFrom: moment.tz(validFrom, "Asia/Kuwait").toISOString(),
          validTo: moment.tz(validTo, "Asia/Kuwait").toISOString(),
          isActive: status === "Active" ? true : false,
        }
      ),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        const afterCompletion = () => {
          queryClient.invalidateQueries("promoCodes");
          queryClient.invalidateQueries("promoCode");
          if (isAddForm()) {
            addToast("Promo code added successfully!", { appearance: "success" });
            history.push("/portal/promos");
          } else {
            addToast("Promo code updated successfully!", { appearance: "success" });
          }
        }
        if (data?.updatePromoCode?.isPrivate || data?.addPromoCode?.isPrivate) {
          submitAssignToCustomer({
            promoCodeId: data?.updatePromoCode?.id || data?.addPromoCode?.id,
            customerId: currentCustomer,
          }, {
            onSuccess: afterCompletion,
            onError: () => {
              addToast("Something went wrong. Please try again.", {
                appearance: "error",
              });
            },
          });
        } else {
          afterCompletion();
        }
      },
    }
  );

  const { mutate: deleteRequest, isLoading: isDeleting } = useMutation(
    ({ id }) =>
      GraphQLClient.request(DeletePromoMutation, {
        id,
      }),
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("promoCodes");
        queryClient.invalidateQueries("promoCode");

        addToast("Promo code deleted successfully!", { appearance: "success" });
        history.push("/portal/promos");
      },
    }
  );

  // State Hooks
  useEffect(() => {
    setPageTitle("Promo Code Details");
    //   if (currentDataObject) {
    //     setValue("nameEn", currentDataObject.nameEn);
    //     setValue("nameAr", currentDataObject.nameAr);
    //     setValue("media", currentDataObject.media);
    //     setValue("status", currentDataObject.isActive ? "Active" : "Inactive");
    //   }
  }, []);

  useEffect(() => {
    if (currentDataObject.id) {
      setValue("id", currentDataObject.id);
      setValue("name", currentDataObject.name);
      setValue("discountType", currentDataObject.discountType);
      setValue("value", currentDataObject.value);
      setValue("isPrivateSelector", currentDataObject.isPrivate ? "YES" : "NO");
      setValue("isActive", currentDataObject.isActive);
      setValue("validFrom", moment(parseInt(currentDataObject.validFrom)).tz("Asia/Kuwait").format("YYYY-MM-DDTHH:mm"));
      setValue("validTo", moment(parseInt(currentDataObject.validTo)).tz("Asia/Kuwait").format("YYYY-MM-DDTHH:mm"));
      setValue("status", currentDataObject.isActive ? "Active" : "Inactive");
      setValue("customer", currentDataObject.customer?.id || "")
    }
  }, [currentDataObject]);

  useEffect(() => {
    if (!isAddForm() && currentDataObject.id) {
      if (ability.can('promoCodesDelete','promoCodes')) {
        setPageActionsMenu([
          {
            text: "Delete Promo Code",
            isDanger: true,
            callback: () => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm("Are you sure to delete. This action can't be undone!")
              ) {
                deleteRequest({ id });
              }
            },
          },
        ]);
      }
    }

    return () => {
      setPageActionsMenu([]);
    };
  }, [id, currentDataObject]);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/promos");
    }
  };

  const handleGenerateCode = (e) => {
    e.preventDefault();
    let length = 8;
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength))); 
    }
    setValue("name", result.join(''));
  }

  // Other

  // Component Render
  return (
    <div className="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined className="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading: status === "loading",
          isError: status === "error",
          refetch,
        }}
      >
        <form
          onSubmit={handleSubmit(submitRequest)}
          className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y"
        >
          <input type="hidden" {...register("id")} />
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
            </div>
            <div className="p-4 px-6 flex flex-col">
              <div className="mb-4 w-full flex">
              <Input
                    name="name"
                    label="Code"
                    placeholder="Code"
                    {...register("name", {
                      required: "Code is required!",
                    })}
                    errors={errors}
                    note={
                      <div className="flex flex-row items-center flex-nowrap">
                        <Button
                          text={"Click Here"}
                          className={"flex w-min whitespace-nowrap"}
                          onClick={handleGenerateCode}
                        />
                        <span className="ml-2">to generate a code</span>
                      </div>
                    }
                  />
              </div>
              <hr className="color-gray-500 my-4" />
              <div className="flex">
                <div className="mb-4 pr-2 flex-col w-1/2">
                <div className="mb-4">
                  <Input
                    name="validFrom"
                    label="Valid From"
                    placeholder="Valid From"
                    type="datetime-local"
                    {...register("validFrom", {
                      required: "Valid From is required!",
                    })}
                    errors={errors}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    name="validTo"
                    label="Valid To"
                    placeholder="Valid To"
                    type="datetime-local"
                    {...register("validTo", {
                      required: "Valid To is required!",
                    })}
                    errors={errors}
                  />
                </div>
                  <div className="mb-4">
                    <Select
                        name="discountType"
                        label="Discount Type"
                        placeholder="Discount Type"
                        options={{
                          PERCENTAGE: "Percentage",
                          VALUE: "Value",
                        }}
                        {...register("discountType", {
                          required: "Discount Type is required!",
                        })}
                        errors={errors}
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      name="value"
                      label="Value"
                      placeholder="Value"
                      type="number"
                      min="0"
                      max={discountType === "PERCENTAGE" ? "100" : ""}
                      step={discountType === "VALUE" ? "0.01" : "0.1"}
                      {...register("value", {
                        required:
                          "Value is required!",
                        min: 0,
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                        name="isPrivateSelector"
                        label="Private Code"
                        placeholder="Private Code"
                        options={{
                          YES: "Yes",
                          NO: "No",
                        }}
                        {...register("isPrivateSelector")}
                    />
                  </div>
                  {isPrivateSelector === "YES" && (
                    <div className="mb-4">
                      <Select
                        name="customer"
                        label="Customer"
                        placeholder="Customer"
                        options={
                          customers
                            ? customers.reduce(
                                (a, c) => ({
                                  ...a,
                                  [c.id]: `${c.firstName} ${c.lastName} - ${c.email}`,
                                }),
                                {}
                              )
                            : {}
                        }
                        {...register("customer", {
                          required: (value) => {
                            if (!value && isPrivateSelector === "YES") {
                              return "Customer is required!";
                            } else {
                              return false;
                            }
                          },
                        })}
                        errors={errors}
                      />
                    </div>
                  )}
                  <div className="">
                    <span className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                      Status:
                    </span>
                    <br />
                    <span
                      className={
                        "relative text-xs inline-block px-3 py-1 font-semibold leading-tight " +
                        (currentStatus === "Active"
                          ? "text-green-900"
                          : "text-red-900")
                      }
                    >
                      <span
                        aria-hidden
                        className={
                          "absolute inset-0 opacity-50 rounded-full " +
                          (currentStatus === "Active"
                            ? "bg-green-200"
                            : "bg-red-200")
                        }
                      ></span>
                      <span className="relative">
                        <select
                          className="bg-transparent font-semibold"
                          {...register("status", {
                            required: "Promo code status is required!",
                          })}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <Button
              text="Save"
              className="w-full"
              color="text-white bg-pink-600 hover:bg-pink-700"
              SuffixIcon={!isSubmitting ? CheckCircleOutlined : LoadingOutlined}
              type="submit"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </StateBlock>
    </div>
  );
};

PromoCodeDetails.propTypes = {
  /**
   *
   */
};

export default PromoCodeDetails;
