// React
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { AbilityContext } from "utils/ability";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";
import Select from "components/form/SelectField";
import Subform from "components/form/Subform";

// Globals

// Helpers
import { useParams, useHistory } from "react-router-dom";
import AddProductMutation from "graphql/mutation/product/add";
import UpdateProductMutation from "graphql/mutation/product/update";
import DeleteProductMutation from "graphql/mutation/product/delete";
import ParentCategoriesPicklistQuery from "graphql/query/categories/picklist.withnoparent";
import BrandsListQuery from "graphql/query/brands/picklist";

import { usePicklistAPI } from "utils/usePicklistAPI";
// Components

// Factories

// Screens
import ReactTooltip from "react-tooltip";
import { useToasts } from "react-toast-notifications";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import SingleProductQuery from "graphql/query/products/single";
import GraphQLClient from "services/api";
import StateBlock from "components/StateBlock";
import Media from "components/form/Media";

// Styles

// Ad-Hoc Components
import * as uuid from "uuid";
import validateDuplicateVariantInArray from "./utils/validateDuplicateVariantInArray";
import getBooleanFromString from "utils/getBooleanFromString";
import getStringFromBoolean from "utils/getStringFromBoolean";
import transformVariants from "./utils/transformVariants";

/**
 * @name ProductDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ProductDetails = ({ setPageTitle }) => {
  // Theme & Style Hooks
  const { id } = useParams();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const ability = useContext(AbilityContext);

  const [colorsHolder, setColorsHolder] = useState([]);
  const [sizesHolder, setSizesHolder] = useState([]);
  const [variantsHolder, setVariantsHolder] = useState([]);

  const isAddForm = () => id === "new";

  const colorsRef = useRef(null);
  const sizesRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      colors: [],
    },
  });

  const {
    fields: variantFields,
    append: appendVariant,
    remove: removeVariant,
  } = useFieldArray({
    control,
    name: "variants",
  });

  const variantFieldsValues = watch("variants");

  const selectedMainVariantsCount = variantFieldsValues?.filter(
    (v) => v.isSearchable === "Yes"
  ).length;

  console.log(selectedMainVariantsCount);

  const colors = watch("colors");
  const sizes = watch("sizes");
  const category = watch("category");

  const { data: brandsList, isLoading: isLoadingBrands } = usePicklistAPI(
    "brands",
    {
      query: BrandsListQuery,
    }
  );
  const brands = useMemo(() => brandsList?.data || [], [brandsList]);
  const { data: parentCategoriesResult, isLoading: isLoadingCategories } =
    usePicklistAPI("categories", {
      query: ParentCategoriesPicklistQuery,
    });

  const parentCategories = useMemo(
    () => parentCategoriesResult?.data,
    [parentCategoriesResult]
  );
  const parentCategoriesObject = parentCategories
    ? parentCategories.reduce(
        (a, c) => ({
          ...a,
          [c.id]: c.nameEn,
        }),
        {}
      )
    : {};

  const handleGenerateVariantsList = async () => {
    if (!Array.isArray(colors) || colors.length <= 0) {
      addToast("Cannot generate variants, You have no colors", {
        appearance: "error",
      });
      return;
    }
    if (!Array.isArray(sizes) || sizes.length <= 0) {
      addToast("Cannot generate variants, You have no sizes", {
        appearance: "error",
      });
      return;
    }
    const isDuplicateColors = validateDuplicateVariantInArray(colors);
    const isDuplicateSizes = validateDuplicateVariantInArray(sizes);
    if (isDuplicateColors) {
      addToast("Cannot generate variants, you have duplicate color names", {
        appearance: "error",
      });
      return;
    }
    if (isDuplicateSizes) {
      addToast("Cannot generate variants, you have duplicate size names", {
        appearance: "error",
      });
      return;
    }

    await removeVariant();

    const variants = [];
    colors.forEach((color, i) => {
      sizes.forEach((size, j) => {
        variants.push({
          color: color.id,
          size: size.id,
          offerPrice: 0,
          status: "Active",
          isSearchable: i === 0 && j === 0 ? "Yes" : "No",
          isFeatured: "No",
        });
      });
    });
    await appendVariant(variants);
  };

  const handleAddVariant = async () => {
    const newVariant = {
      color: null,
      size: null,
      status: "Active",
      isSearchable: "No",
      isFeatured: "No",
    };
    await appendVariant(newVariant);
  };

  const populateForm = async ({
    productMeta: { nameEn, nameAr, descriptionEn, descriptionAr },
    productSpecs,
    brand: { id: brandId },
    category: { id: categoryId },
    productVariations,
  }) => {
    const { colors, sizes, variants } = transformVariants(productVariations);

    const materialObj = productSpecs.find((item) => item.nameEn === "Material");
    const fittingObj = productSpecs.find((item) => item.nameEn === "Fitting");
    setValue("id", id);
    setValue("nameEn", nameEn);
    setValue("nameAr", nameAr);
    setValue("descriptionEn", descriptionEn);
    setValue("descriptionAr", descriptionAr);
    setValue("category", categoryId);
    setValue("brand", brandId);
    setValue("materialEn", materialObj.valueEn);
    setValue("materialAr", materialObj.valueAr);
    setValue("fittingEn", fittingObj.valueEn);
    setValue("fittingAr", fittingObj.valueAr);
    await colorsRef?.current?.removeAll();
    await sizesRef?.current?.removeAll();
    console.log("variants", variants);
    setColorsHolder(colors);
    setSizesHolder(sizes);
    setVariantsHolder(variants);
    await removeVariant();
    await appendVariant(variants);
  };

  let { data, status, refetch } = useSingleEntityAPI(
    "product",
    {
      id,
      query: SingleProductQuery,
    },
    {
      onSuccess: ({ data }) => {
        // populateForm(data);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      enabled:
        !isAddForm() && !!brandsList?.data && !!parentCategoriesResult?.data,
    }
  );
  // Global State Hooks
  if (isAddForm()) status = "success";

  useEffect(() => {
    if (colorsHolder.length > 0) {
      colorsRef?.current?.appendRecords(colorsHolder);
      setColorsHolder([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorsRef.current]);

  useEffect(() => {
    if (sizesHolder.length > 0) {
      sizesRef?.current?.appendRecords(sizesHolder);
      setSizesHolder([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizesRef.current]);

  useEffect(() => {
    if (variantsHolder.length > 0) {
      variantFields.forEach((variant, index) => {
        const foundVariant = variantsHolder.find(
          ($variant) =>
            variant.color === $variant.color && variant.size === $variant.size
        );
        if (foundVariant) {
          setValue(`variants.${index}.media`, [...foundVariant.media]);
        }
      });
      setVariantsHolder([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantsHolder]);

  const currentDataObject = useMemo(() => (data ? data.data : {}), [data]);

  const currentCategory =
    parentCategories?.find((item) => item.id === category) || null;

  const { mutate: submitRequest, isLoading: isSubmitting } = useMutation(
    ({
      id,
      nameEn,
      nameAr,
      descriptionEn,
      descriptionAr,
      category,
      colors,
      sizes,
      materialEn,
      materialAr,
      fittingEn,
      fittingAr,
      subCategory,
      brand,
      variants,
    }) => {
      const productVariations = variants.map((variant) => {
        const color = colors?.find((item) => item.id === variant.color) || null;
        const size = sizes?.find((item) => item.id === variant.size) || null;
        const mediaIds = variant.media.map((image) => image.id);
        return {
          id: variant.id,
          isFeatured: getBooleanFromString(variant.isFeatured, ["Yes", "No"]),
          isSearchable: getBooleanFromString(variant.isSearchable, [
            "Yes",
            "No",
          ]),
          isActive: getBooleanFromString(variant.status, [
            "Active",
            "Inactive",
          ]),
          mediaIds,
          price: parseInt(variant.price),
          offerPrice: parseInt(variant.offerPrice),
          stock: parseInt(variant.stock),
          variations: [
            {
              nameEn: "Color",
              nameAr: "اللون",
              valueEn: color.valueEn,
              valueAr: color.valueAr,
            },
            {
              nameEn: "Size",
              nameAr: "الحجم",
              valueEn: size.valueEn,
              valueAr: size.valueAr,
            },
          ],
        };
      });
      const productSpecs = [
        {
          nameEn: "Material",
          nameAr: "الخامة",
          valueEn: materialEn,
          valueAr: materialAr,
        },
        {
          nameEn: "Fitting",
          nameAr: "القصّة",
          valueEn: fittingEn,
          valueAr: fittingAr,
        },
      ];
      const productMeta = {
        nameEn,
        nameAr,
        descriptionEn,
        descriptionAr,
      };
      const hasSubCategory =
        category && currentCategory?.subCategories?.length > 0;
      const categoryId = hasSubCategory ? subCategory : category;
      return GraphQLClient.request(
        isAddForm() ? AddProductMutation : UpdateProductMutation,
        {
          id,
          productMeta,
          categoryId,
          brandId: brand,
          productSpecs,
          productVariations,
        }
      );
    },
    {
      onError: () => {
        addToast("Something went wrong. Please try again.", {
          appearance: "error",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("products");
        queryClient.invalidateQueries("product");
        if (isAddForm()) {
          addToast("Product added successfully!", {
            appearance: "success",
          });
          history.push("/portal/products");
        } else {
          addToast("Product updated successfully!", {
            appearance: "success",
          });
        }
      },
    }
  );

  // State Hooks
  useEffect(() => setPageTitle("Product Details"), []);
  useEffect(() => {
    if (currentDataObject?.productMeta) {
      populateForm(currentDataObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataObject]);
  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const onDeleteSubformRow = () => {};
  const closeScreen = () => {
    if (history.length) {
      history.goBack();
    } else {
      history.push("/products");
    }
  };

  // Component Render
  return (
    <div className="flex justify-center my-6">
      <ReactTooltip />
      <button
        onClick={closeScreen}
        data-tip="Close"
        className="tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
      >
        <CloseOutlined className="p-0" />
      </button>
      <StateBlock
        {...{
          isSuccess: status === "success",
          isLoading:
            status === "loading" || isLoadingBrands || isLoadingCategories,
          isError: status === "error",
          refetch,
        }}
      >
        <form
          onSubmit={handleSubmit(submitRequest)}
          className="flex flex-col w-full p-8 px-6 text-gray-800 bg-white shadow-lg pin-r pin-y"
        >
          <input type="hidden" {...register("id")} />
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full">
              <h1 className="ml-2 font-bold uppercase">Basic Information</h1>
            </div>
            <div className="p-4 px-6 flex flex-col">
              <div className="flex">
                <div className="mb-4 pr-2 flex-col w-1/2">
                  <div className=" mb-4 space-x-4 flex flex-row">
                    <Input
                      name="nameEn"
                      label="Product Name (EN)"
                      className="flex-1"
                      placeholder="Product Name (EN)"
                      {...register("nameEn", {
                        required: "Product english name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                    <Input
                      name="nameAr"
                      className="flex-1"
                      label="Product Name (AR)"
                      placeholder="Product Name (AR)"
                      {...register("nameAr", {
                        required: "Product arabic name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className=" mb-4 space-x-4 flex flex-row">
                    <Input
                      name="materialEn"
                      className="flex-1"
                      label="Material Name (EN)"
                      placeholder="Material Name (EN)"
                      {...register("materialEn", {
                        required: "Material english name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                    <Input
                      name="materialAr"
                      className="flex-1"
                      label="Material Name (AR)"
                      placeholder="Material Name (AR)"
                      {...register("materialAr", {
                        required: "Product material arabic name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className=" mb-4 space-x-4 flex flex-row">
                    <Input
                      name="fittingEn"
                      className="flex-1"
                      label="Fitting Name (EN)"
                      placeholder="Fitting Name (EN)"
                      {...register("fittingEn", {
                        required: "Fitting english name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                    <Input
                      name="fittingAr"
                      className="flex-1"
                      label="Fitting Name (AR)"
                      placeholder="Fitting Name (AR)"
                      {...register("fittingAr", {
                        required: "Product fitting arabic name is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      {...register("category", {
                        required: "Category Is Required is required!",
                      })}
                      name="category"
                      label="Category"
                      placeholder="Category"
                      options={parentCategoriesObject}
                    />
                  </div>
                  {category && currentCategory?.subCategories?.length > 0 && (
                    <div className="mb-4">
                      <Select
                        {...register("subCategory", {
                          required: "Subcategory Is Required is required!",
                        })}
                        name="subcategory"
                        label="Subcategory"
                        placeholder="Subcategory"
                        options={
                          currentCategory?.subCategories
                            ? currentCategory?.subCategories.reduce(
                                (a, c) => ({
                                  ...a,
                                  [c.id]: c.nameEn,
                                }),
                                {}
                              )
                            : {}
                        }
                      />
                    </div>
                  )}
                  <div className="mb-4">
                    <Select
                      {...register("brand", {
                        required: "Brand Is Required is required!",
                      })}
                      name="brand"
                      label="Brand"
                      placeholder="Brand"
                      options={
                        brands
                          ? brands.reduce(
                              (a, c) => ({
                                ...a,
                                [c.id]: c.nameEn,
                              }),
                              {}
                            )
                          : {}
                      }
                    />
                  </div>
                </div>
                <div className="mb-4 pl-2 w-1/2 flex flex-col">
                  <div className="mb-8 pl-2 flex flex-col" flex-row>
                    <Input
                      type="textarea"
                      rows={4}
                      name="descriptionEn"
                      label="Product Description (EN)"
                      placeholder="Product Description (En)"
                      {...register("descriptionEn", {
                        required: "Product English Description is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                  <div className="mb-8 pl-2  flex flex-col flex-row">
                    <Input
                      type="textarea"
                      rows={4}
                      name="descriptionAr"
                      label="Product Description (AR)"
                      placeholder="Product Description (AR)"
                      {...register("descriptionAr", {
                        required: "Product Arabic Description is required!",
                      })}
                      errors={errors}
                      // disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full flex-grow flex flex-row items-center justify-between">
              <h1 className="ml-2 font-bold uppercase">Variant Information</h1>
            </div>
            <div className="p-4 px-6 flex flex-col">
              <Subform
                ref={colorsRef}
                name="colors"
                labelPlural="Colors"
                labelSingle="Color"
                control={control}
                register={register}
                errors={errors}
                onDeleteRow={onDeleteSubformRow}
                allowDelete={isAddForm()}
              />
              <Subform
                ref={sizesRef}
                name="sizes"
                labelPlural="Sizes"
                labelSingle="Size"
                control={control}
                register={register}
                errors={errors}
                allowDelete={isAddForm()}
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="p-4 bg-gray-100 rounded-full flex-grow flex flex-row items-center justify-between">
              <h1 className="ml-2 font-bold uppercase">
                Stock &amp; Pricing Information
              </h1>
              {isAddForm() && (
                <Button
                  text={"Generate Variants List"}
                  className={"w-min whitespace-nowrap"}
                  onClick={handleGenerateVariantsList}
                />
              )}
            </div>
            <div className="p-4 px-6 flex flex-col">
              <div className="w-full mx-auto">
                <div className="mb-4 rounded">
                  <div
                    className="shadow rounded-lg "
                    style={{ overflowX: "scroll" }}
                  >
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Featured
                          </th>
                          <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Main
                          </th>
                          <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Color
                          </th>
                          <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Size
                          </th>
                          <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-28">
                            Stock
                          </th>
                          <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Price
                          </th>
                          <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-28">
                            Offer Price
                          </th>
                          <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Status
                          </th>
                          <th className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {variantFields.map((field, index) => {
                          const status = watch(`variants.${index}.status`);
                          const isSearchable = watch(
                            `variants.${index}.isSearchable`
                          );
                          return (
                            <>
                              <input
                                type="hidden"
                                {...register(`variants.${index}.id`)}
                              />
                              <tr>
                                <td className="px-2 py-2  border-gray-200 bg-white text-sm">
                                  <Select
                                    name={`variants.${index}.isFeatured`}
                                    placeholder="Is Featured Variant?"
                                    options={{
                                      Yes: "Yes",
                                      No: "No",
                                    }}
                                    small
                                    {...register(
                                      `variants.${index}.isFeatured`,
                                      {
                                        required: "required!",
                                      }
                                    )}
                                    defaultValue={field.isFeatured}
                                  />
                                </td>
                                <td className="px-2 py-2  border-gray-200 bg-white text-sm">
                                  <Select
                                    name={`variants.${index}.isSearchable`}
                                    placeholder="Is isSearchable Variant?"
                                    options={{
                                      Yes: "Yes",
                                      No: "No",
                                    }}
                                    small
                                    {...register(
                                      `variants.${index}.isSearchable`,
                                      {
                                        required: "required!",
                                        validate: (v) =>
                                          selectedMainVariantsCount === 1 ||
                                          (selectedMainVariantsCount > 1
                                            ? "You have to select only one variant as main"
                                            : "You have to select the main variant"),
                                      }
                                    )}
                                    defaultValue={field.isSearchable}
                                    disabled={
                                      Boolean(selectedMainVariantsCount) &&
                                      isSearchable !== "Yes"
                                    }
                                    errors={errors}
                                  />
                                </td>
                                <td className="px-2 py-2  border-gray-200 bg-white text-sm">
                                  <Select
                                    {...register(`variants.${index}.color`, {
                                      required: "required!",
                                    })}
                                    name={`variants.${index}.color`}
                                    placeholder="Color"
                                    defaultValue={field.color}
                                    small
                                    options={
                                      Array.isArray(colors) && colors.length > 0
                                        ? colors.reduce(
                                            (a, color) => ({
                                              ...a,
                                              [color.id]: color.valueEn,
                                            }),
                                            {}
                                          )
                                        : {}
                                    }
                                  />
                                </td>
                                <td className="px-2 py-2  border-gray-200 bg-white text-sm">
                                  <Select
                                    {...register(`variants.${index}.size`, {
                                      required: "required!",
                                    })}
                                    name={`variants.${index}.size`}
                                    placeholder="Size"
                                    defaultValue={field.size}
                                    small
                                    options={
                                      Array.isArray(sizes) && sizes.length > 0
                                        ? sizes.reduce(
                                            (a, size) => ({
                                              ...a,
                                              [size.id]: size.valueEn,
                                            }),
                                            {}
                                          )
                                        : {}
                                    }
                                  />
                                </td>
                                <td className="px-2 py-2  border-gray-200 bg-white text-sm">
                                  <Input
                                    name={`variants.${index}.stock`}
                                    placeholder="Stock"
                                    {...register(`variants.${index}.stock`, {
                                      required: "required!",
                                    })}
                                    small
                                    errors={errors}
                                  />
                                </td>
                                <td className="px-2 py-2  border-gray-200 bg-white text-sm min-width-10">
                                  <Input
                                    name={`variants.${index}.price`}
                                    placeholder="Price"
                                    {...register(`variants.${index}.price`, {
                                      required: "required!",
                                    })}
                                    small
                                    errors={errors}
                                  />
                                </td>
                                <td className="px-2 py-2  border-gray-200 bg-white text-sm">
                                  <Input
                                    name={`variants.${index}.offerPrice`}
                                    placeholder="Offer Price"
                                    {...register(
                                      `variants.${index}.offerPrice`,
                                      {
                                        required: "required!",
                                      }
                                    )}
                                    small
                                    errors={errors}
                                  />
                                </td>
                                <td>
                                  <span
                                    className={
                                      "relative text-xs inline-block px-3 py-1 font-semibold leading-tight " +
                                      (status === "Active"
                                        ? "text-green-900"
                                        : "text-red-900")
                                    }
                                  >
                                    <span
                                      aria-hidden
                                      className={
                                        "absolute inset-0 opacity-50 rounded-full " +
                                        (status === "Active"
                                          ? "bg-green-200"
                                          : "bg-red-200")
                                      }
                                    ></span>
                                    <span className="relative">
                                      <select
                                        className="bg-transparent font-semibold"
                                        // value={field.status}
                                        {...register(
                                          `variants.${index}.status`,
                                          {
                                            required: "required!",
                                          }
                                        )}
                                        defaultValue={field.status}
                                      >
                                        <option value="Active">Active</option>
                                        <option value="Inactive">
                                          Inactive
                                        </option>
                                      </select>
                                    </span>
                                  </span>
                                </td>
                                <td className="px-2 py-2  border-gray-200 bg-white text-sm">
                                  <Button
                                    color="pt-5 text-pink-500 hover:text-pink-700"
                                    basic
                                    bold
                                    PrefixIcon={DeleteOutlined}
                                    onClick={async () => {
                                      await removeVariant(index);
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th
                                  colspan="2"
                                  className="px-2 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                                >
                                  Media
                                </th>
                                <td
                                  colspan="7"
                                  className="px-2 py-2 border-b border-gray-200 bg-white text-sm"
                                >
                                  <Controller
                                    name={`variants.${index}.media`}
                                    control={control}
                                    defaultValue={[]}
                                    rules={{
                                      required: "Variant image is required!",
                                    }}
                                    className="w-full"
                                    render={({ field }) => {
                                      console.log("field :>> ", field);
                                      return (
                                        <Media
                                          {...field}
                                          errors={errors}
                                          isMultiple={true}
                                          small
                                        />
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                    <Button
                      text="Add New Variant"
                      className="py-5"
                      color="ml-auto text-pink-500 hover:text-pink-700"
                      basic
                      bold
                      PrefixIcon={PlusOutlined}
                      onClick={handleAddVariant}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Button
              text="Save"
              className="w-full"
              color="text-white bg-pink-600 hover:bg-pink-700"
              SuffixIcon={!isSubmitting ? CheckCircleOutlined : LoadingOutlined}
              type="submit"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </StateBlock>
    </div>
  );
};

ProductDetails.propTypes = {
  /**
   *
   */
};

export default ProductDetails;
