import { gql } from "graphql-request";

export default gql`
  mutation updateArea(
    $id: String!
    $name: String!
    $countryId: String!
  ) {
    updateArea(
      areaId: $id
      input: { name: $name, countryId: $countryId, isActive: true }
    ) {
      id
      name
      country {
        id
        name
      }
    }
  }
`;
