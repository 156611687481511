import { useEffect } from "react";
import { useAuthTokens } from "services/tokens/auth";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import GraphQLClient, {
  controller as GraphQLClientController,
} from "services/api";

import refreshTokenMutation from "graphql/mutation/refreshToken";

function RefreshToken() {
  const [{ authToken, refreshToken }, { setTokens }] = useAuthTokens();
  const isAuthPage = useRouteMatch("/auth");
  const history = useHistory();

  const {
    mutate: refreshTokenRequest,
  } = useMutation(
    (refreshToken) => {
      const promise = GraphQLClient.request(refreshTokenMutation, {
        refreshToken,
      });

      promise.cancel = () => GraphQLClientController.abort();

      return promise;
    },
    {
      onError: (e) => {
        process.env.NODE_ENV === "development" &&
          console.log("[RefreshToken] error");
        process.env.NODE_ENV === "development" && console.log(e);
        history.replace("/auth/login");
      },
      onSuccess: (data) => {
        const { token: authToken, refreshToken } = data.refreshUserToken;
        setTokens({ authToken, refreshToken });
        process.env.NODE_ENV === "development" &&
          console.log("[RefreshToken] Successfully Refreshed Token");
        process.env.NODE_ENV === "development" &&
          console.log("[RefreshToken] " + authToken);
      },
    }
  );

  let RefreshTokenTimer;

  const RefreshToken = (authToken, refreshToken) => {
    process.env.NODE_ENV === "development" &&
      console.log("[RefreshToken] Refreshing Token");

    clearTimeout(RefreshTokenTimer);
    RefreshTokenTimer = setTimeout(
      () => refreshTokenRequest(refreshToken.token),
      (() => {
        const TTE = authToken.millisToExpire();
        let timeout = 1000;
        if (TTE - 1000 * 60 * 15 > 0) {
          timeout = TTE - 1000 * 60 * 15;
        }
        process.env.NODE_ENV === "development" &&
          console.log("[RefreshToken] Time To Refresh Token " + timeout + "ms");
        return timeout;
      })()
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    process.env.NODE_ENV === "development" &&
      console.log("[RefreshToken] Auth Token or Refresh Token Changed");
    if (
      !authToken.isValidToken() ||
      !refreshToken.isValidToken() ||
      refreshToken.isExpired()
    ) {
      if (!isAuthPage) {
        history.replace("/auth/login");
      }
    } else {
      RefreshToken(authToken, refreshToken);
    }

    return () => {
      process.env.NODE_ENV === "development" &&
        console.log("[RefreshToken] Timer Cancelled");
      clearTimeout(RefreshTokenTimer);
    }; 
  }, [authToken, refreshToken]);
  /* eslint-enable */

  process.env.NODE_ENV === "development" &&
    console.log("[RefreshToken] Rerender");
  process.env.NODE_ENV === "development" &&
    console.log("[RefreshToken] " + authToken.token);
  return null;
}

export default RefreshToken;
