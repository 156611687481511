import React, { useState, createContext, useEffect } from "react";
import { createContextualCan } from "@casl/react";
import { AbilityBuilder, Ability } from "@casl/ability";
import { useSingleEntityAPI } from "utils/useSingleEntityAPI";
import MyUserQuery from "graphql/query/myUser";
import { useRouteMatch } from "react-router-dom";

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export const AbilityProvider = ({ children, ...rest }) => {
  // const [isError, setIsError] = useState(false);
  const routeMatch = useRouteMatch().path === "/portal";
  
  const [userAbilities, setUserAbilities] = useState(new Ability());
  const rolesMap = [
    {
      name: "Brands",
      roleName: "brands",
      actions: {
        view: "brandsView",
        add: "brandsAdd",
        edit: "brandsEdit",
        delete: "brandsDelete",
      },
    },
    {
      name: "Areas",
      roleName: "areas",
      actions: {
        view: "areasView",
        add: "areasAdd",
        edit: "areasEdit",
        delete: "areasDelete",
      },
    },
    {
      name: "Categories",
      roleName: "categories",
      actions: {
        view: "categoriesView",
        add: "categoriesAdd",
        edit: "categoriesEdit",
        delete: "categoriesDelete",
      },
    },
    {
      name: "Products",
      roleName: "products",
      actions: {
        view: "productsView",
        add: "productsAdd",
        edit: "productsEdit",
        delete: "productsDelete",
      },
    },
    {
      name: "Checkout Notes",
      roleName: "checkoutNotes",
      actions: {
        view: "checkoutNotesView",
        add: "checkoutNotesAdd",
        edit: "checkoutNotesEdit",
        delete: "checkoutNotesDelete",
      },
    },
    {
      name: "Contact Us",
      roleName: "contactUs",
      actions: {
        view: "contactUsView",
        add: "",
        edit: "contactUsMarkAsSeen",
        delete: "contactUsDelete",
      },
    },
    {
      name: "Customers",
      roleName: "customers",
      actions: {
        view: "customersView",
        add: "customersAdd",
        edit: "customersEdit",
        delete: "customersDelete",
      },
    },
    {
      name: "Dashboard",
      roleName: "dashboard",
      actions: {
        view: "dashboardView",
        add: "",
        edit: "",
        delete: "",
      },
    },
    {
      name: "Delivery Fees",
      roleName: "deliveryFees",
      actions: {
        view: "deliveryFeesView",
        add: "deliveryFeesAdd",
        edit: "deliveryFeesEdit",
        delete: "deliveryFeesDelete",
      },
    },
    {
      name: "Home Banner",
      roleName: "homeBanner",
      actions: {
        view: "homeBannerView",
        add: "homeBannerAdd",
        edit: "homeBannerEdit",
        delete: "homeBannerDelete",
      },
    },
    {
      name: "Home Slider",
      roleName: "homeSlider",
      actions: {
        view: "homeSliderView",
        add: "homeSliderAdd",
        edit: "homeSliderEdit",
        delete: "homeSliderDelete",
      },
    },
    {
      name: "Media",
      roleName: "media",
      actions: {
        view: "",
        add: "mediaAdd",
        edit: "",
        delete: "",
      },
    },
    {
      name: "Notifications",
      roleName: "notifications",
      actions: {
        view: "",
        add: "notificationSend",
        edit: "",
        delete: "",
      },
    },
    {
      name: "Orders",
      roleName: "orders",
      actions: {
        view: "ordersView",
        add: "",
        edit: "ordersEdit",
        delete: "",
      },
    },
    {
      name: "Pages",
      roleName: "pages",
      actions: {
        view: "pagesView",
        add: "",
        edit: "pagesEdit",
        delete: "",
      },
    },
    {
      name: "Promo Codes",
      roleName: "promoCodes",
      actions: {
        view: "promoCodesView",
        add: "promoCodesAdd",
        edit: "promoCodesEdit",
        delete: "promoCodesDelete",
      },
    },
    {
      name: "Social",
      roleName: "social",
      actions: {
        view: "socialView",
        add: "",
        edit: "socialEdit",
        delete: "",
      },
    },
    // Special for Super Admin
    {
      name: "Roles",
      roleName: "roles",
      actions: {
        view: "rolesView",
        add: "rolesAdd",
        edit: "rolesEdit",
        delete: "rolesDelete",
      },
    },
    {
      name: "Users",
      roleName: "users",
      actions: {
        view: "usersView",
        add: "usersAdd",
        edit: "usersEdit",
        delete: "usersDelete",
      },
    },
    {
      name: "Reports",
      roleName: "reports",
      actions: {
        view: "reportsView",
        add: "",
        edit: "",
        delete: "",
      },
    },
  ];

  const { status, refetch, isFetching } = useSingleEntityAPI(
    "myUser",
    {
      query: MyUserQuery,
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      retry: 3,
      enabled: !!routeMatch,
      onSuccess: ({ data }) => {
        const { can, rules } = new AbilityBuilder(Ability);
        if (data?.role?.name === "SUPER_ADMIN") {
          rolesMap.forEach((role) => {
            Object.keys(role.actions).forEach((actionName) => {
              if (role.actions[actionName] !== "") {
                can(role.actions[actionName], role.roleName);
              }
            });
          });
        } else {
          data?.role?.permissions?.forEach((permission) => {
            can(permission.operation, permission.model);
          });
        }
        setUserAbilities(new Ability(rules));
      },
      onError: () => {
        // setUserAbilities(new Ability());
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [])

  return (
    <AbilityContext.Provider value={userAbilities} {...rest}>
      {(status === "loading" || isFetching) && (
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 class="text-center text-white text-xl font-semibold">
            Loading...
          </h2>
          <p class="w-1/3 text-center text-white">
            Please wait while loading app assets
          </p>
        </div>
      )}
      {status === "success" && !isFetching && children}
    </AbilityContext.Provider>
  );
};
