// React
import React from "react";
import PropTypes from "prop-types";
import createClassName from "utils/createClassName";

// UI KIT

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name Button
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Button = ({
  className,
  style,
  color,
  text,
  PrefixIcon,
  SuffixIcon,
  basic,
  small,
  bold,
  disabled = false,
  ...otherProps
}) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <div
      className={createClassName("flex items-center justify-center", className)}
      style={style}
    >
      <button
        type="button"
        className={createClassName(
          bold ? "font-bold" : "",
          small ? "text-xs" : "text-sm sm:text-base",
          "flex items-center justify-center focus:outline-none rounded py-2 w-full transition duration-150 ease-in",
          "px-2",
          disabled
            ? "bg-pink-200 hover:bg-pink-200 text-gray-400 cursor-not-allowed"
            : color
        )}
        disabled={disabled}
        {...otherProps}
      >
        {PrefixIcon && <span>{<PrefixIcon className="h-5 w-5" />}</span>}
        <span
          className={createClassName(
            !basic ? "uppercase" : "",
            PrefixIcon ? "ml-1" : "",
            SuffixIcon ? "mr-1" : ""
          )}
        >
          {text}
        </span>
        {SuffixIcon && <span>{<SuffixIcon className="h-5 w-5" />}</span>}
      </button>
    </div>
  );
};

Button.propTypes = {
  /**
   *
   */
};

export default Button;
