// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  PlusOutlined,
  LoadingOutlined,
  MehOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
// UI KIT

// Globals

// Helpers
import { useListViewParams } from "utils/useListViewParams";
import { useListViewState } from "utils/useListViewState";
import { useListViewAPI } from "utils/useListViewAPI";

// Components
import ListView from "components/listView/ListView";
import ListViewRow from "components/listView/ListViewRow";
import ListViewCol from "components/listView/ListViewCol";
import ListViewHeader from "components/listView/ListViewHeader";
import ListViewAddButton from "components/listView/ListViewAddButton";
import ListViewContainer from "components/listView/ListViewContainer";

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import CategoriesListQuery from "graphql/query/categories/list";

// Styles

// Ad-Hoc Components

/**
 * @name Categories
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Categories = ({ setPageTitle }) => {
  // Theme & Style Hooks
  const history = useHistory();
  // Global State Hooks

  // State Hooks
  const {
    page,
    perPage,
    search,
    filter,
    nextPage,
    prevPage,
    handlePerPageChange,
    handleSearchChange,
    handleStatusChange,
  } = useListViewState({
    filterDefault: { status: "ALL" },
  });

  // Effect Hooks
  useEffect(() => setPageTitle("Categories"), []);

  // Other Hooks

  // Event Handlers
  const goToAdd = () => history.push("/portal/categories/new");

  // Other
  const { status, data, error, isFetching, refetch } = useListViewAPI(
    "categories",
    {
      perPage,
      page,
      search,
      filter,
      query: CategoriesListQuery,
      queryFilterEvaluation: (filter) => ({
        isActive:
          filter.status === "Active"
            ? "true"
            : filter.status === "Inactive"
            ? "false"
            : null,
      }),
    }
  );

  const viewData = data?.data;
  const viewMetadata = data?.metadata;

  const {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
  } = useListViewParams(page, perPage, viewMetadata, viewData?.length);

  const headers = [{ text: "Category" }, { text: "Status" }];

  const listViewParams = {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
    headers,
    prevPage,
    nextPage,
  };

  const RowComponent = ({
    id,
    nameEn,
    nameAr,
    image,
    isActive,
    subCategories,
    category,
  }) => {
    return (
      <>
        <ListViewRow>
          <ListViewCol className={category ? "border-l-8 pl-48" : ""}>
            <div className="flex items-center">
              <div className="flex-shrink-0 w-40 ">
                <img
                  className="w-full h-full rounded object-cover"
                  src={image}
                  alt=""
                />
              </div>
              <div className="ml-3">
                <p className="text-gray-900 whitespace-no-wrap">
                  <Link
                    to={`/portal/categories/${id}`}
                    className="text-blue-500 underline"
                  >
                    {nameEn} ({nameAr})
                  </Link>
                </p>
              </div>
            </div>
          </ListViewCol>

          <ListViewCol>
            <span
              className={
                "relative inline-block px-3 py-1 font-semibold leading-tight " +
                (isActive ? "text-green-900" : "text-red-900")
              }
            >
              <span
                aria-hidden
                className={
                  "absolute inset-0 opacity-50 rounded-full " +
                  (isActive ? "bg-green-200" : "bg-red-200")
                }
              ></span>
              <span className="relative">
                {isActive ? "Active" : "Inactive"}
                {/* <select
                className="bg-transparent font-semibold"
                value=
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select> */}
              </span>
            </span>
          </ListViewCol>
        </ListViewRow>
        {Array.isArray(subCategories) &&
          subCategories.map(
            ({
              id,
              nameEn,
              nameAr,
              isActive,
              media: { path: image },
              subCategories,
              category,
            }) =>
              RowComponent({
                ...{
                  id,
                  nameEn,
                  nameAr,
                  isActive,
                  image,
                  subCategories,
                  category: true,
                },
              })
          )}
      </>
    );
  };

  // Component Render
  return (
    <ListViewContainer
      {...{
        isSuccess: status === "success",
        isLoading: status === "loading",
        isError: status === "error",
        refetch,
        header: (
          <>
            <ListViewAddButton onClick={goToAdd}>
              Add Category
            </ListViewAddButton>

            <ListViewHeader
              {...{
                perPage,
                handlePerPageChange,
                search,
                handleSearchChange,
                filters: [
                  {
                    state: filter.status,
                    handleStateChange: handleStatusChange,
                    values: {
                      ALL: "All Statuses",
                      Active: "Active",
                      Inactive: "Inactive",
                    },
                  },
                ],
              }}
            />
          </>
        ),
      }}
    >
      <ListView {...listViewParams}>
        {viewData?.map(
          ({
            id,
            nameEn,
            nameAr,
            isActive,
            media: { path: image },
            subCategories,
            category,
          }) => (
            <RowComponent
              {...{
                id,
                nameEn,
                nameAr,
                isActive,
                image,
                subCategories,
                category,
              }}
            />
          )
        )}
      </ListView>
    </ListViewContainer>
  );
};

Categories.propTypes = {
  /**
   *
   */
};

export default Categories;

//   return (
//     <div className="container mx-auto w-full">
//       <button
//         data-tip="Close"
//         className="flex justify-center items-center tooltip absolute top-8 right-6 p-2 px-3 bg-white rounded opacity-70 hover:opacity-100 cursor-pointer"
//       >
//         <PlusOutlined className="p-0 mr-1" />
//         <span>Add Category</span>
//       </button>
//       <div className="py-2">
//         <div className="flex items-center">
//           {/* <h2 className="text-xl text-gray-700 leading-tight">Pending Orders</h2> */}
//           <div className="flex sm:flex-row flex-col ml-auto">
//             <div className="flex flex-row mb-1 sm:mb-0">
//               <div className="relative">
//                 <select className="appearance-none h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
//                   <option>5</option>
//                   <option selected>10</option>
//                   <option>20</option>
//                 </select>
//                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
//                   <svg
//                     className="fill-current h-4 w-4"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 20 20"
//                   >
//                     <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
//                   </svg>
//                 </div>
//               </div>
//               <div className="relative">
//                 <select className="appearance-none h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500">
//                   <option>All Statuses</option>
//                   <option>Active</option>
//                   <option>Inactive</option>
//                 </select>
//                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
//                   <svg
//                     className="fill-current h-4 w-4"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 20 20"
//                   >
//                     <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
//                   </svg>
//                 </div>
//               </div>

//               <div className="block relative">
//                 <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
//                   <svg
//                     viewBox="0 0 24 24"
//                     className="h-4 w-4 fill-current text-gray-500"
//                   >
//                     <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
//                   </svg>
//                 </span>
//                 <input
//                   placeholder="Search"
//                   className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
//           <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
//             <table className="min-w-full leading-normal">
//               <thead>
//                 <tr>
//                   <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
//                     Category
//                   </th>
//                   <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
//                     Status
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     <div className="flex items-center">
//                       <div className="flex-shrink-0 w-24 h-28 ">
//                         <img
//                           className="w-full h-full rounded object-cover"
//                           src="https://images.unsplash.com/photo-1516762689617-e1cffcef479d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=654&q=80"
//                           alt=""
//                         />
//                       </div>
//                       <div className="ml-3">
//                         <p className="text-gray-900 whitespace-no-wrap">
//                           <Link
//                             to={`/portal/categories/women`}
//                             className="text-blue-500 underline"
//                           >
//                             Women
//                           </Link>
//                         </p>
//                       </div>
//                     </div>
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
//                       <span
//                         aria-hidden
//                         className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
//                       ></span>
//                       <span className="relative">
//                         <select className="bg-transparent font-semibold">
//                           <option selected>Inactive</option>
//                         </select>
//                       </span>
//                     </span>
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="border-l-8 pl-12 px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     <div className="flex items-center">
//                       <div className="flex-shrink-0 w-24 h-28 ">
//                         <img
//                           className="w-full h-full rounded object-cover"
//                           src="https://images.unsplash.com/photo-1551488831-00ddcb6c6bd3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
//                           alt=""
//                         />
//                       </div>
//                       <div className="ml-3">
//                         <p className="text-gray-900 whitespace-no-wrap">
//                           <a href="#" className="text-blue-500 underline">
//                             Jackets
//                           </a>
//                         </p>
//                       </div>
//                     </div>
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
//                       <span
//                         aria-hidden
//                         className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
//                       ></span>
//                       <span className="relative">
//                         <select className="bg-transparent font-semibold">
//                           <option selected>Inactive</option>
//                         </select>
//                       </span>
//                     </span>
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="border-l-8 pl-12 px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     <div className="flex items-center">
//                       <div className="flex-shrink-0 w-24 h-28 ">
//                         <img
//                           className="w-full h-full rounded object-cover"
//                           src="https://images.unsplash.com/photo-1567401893414-76b7b1e5a7a5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
//                           alt=""
//                         />
//                       </div>
//                       <div className="ml-3">
//                         <p className="text-gray-900 whitespace-no-wrap">
//                           <a href="#" className="text-blue-500 underline">
//                             Dresses
//                           </a>
//                         </p>
//                       </div>
//                     </div>
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
//                       <span
//                         aria-hidden
//                         className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
//                       ></span>
//                       <span className="relative">
//                         <select className="bg-transparent font-semibold">
//                           <option selected>Inactive</option>
//                         </select>
//                       </span>
//                     </span>
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     <div className="flex items-center">
//                       <div className="flex-shrink-0 w-24 h-28 ">
//                         <img
//                           className="w-full h-full rounded object-cover"
//                           src="https://images.unsplash.com/photo-1525507119028-ed4c629a60a3?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=675&q=80"
//                           alt=""
//                         />
//                       </div>
//                       <div className="ml-3">
//                         <p className="text-gray-900 whitespace-no-wrap">
//                           <a href="#" className="text-blue-500 underline">
//                             Men
//                           </a>
//                         </p>
//                       </div>
//                     </div>
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
//                       <span
//                         aria-hidden
//                         className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
//                       ></span>
//                       <span className="relative">
//                         <select className="bg-transparent font-semibold">
//                           <option selected>Active</option>
//                         </select>
//                       </span>
//                     </span>
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="border-l-8 pl-12 px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     <div className="flex items-center">
//                       <div className="flex-shrink-0 w-24 h-28 ">
//                         <img
//                           className="w-full h-full rounded object-cover"
//                           src="https://images.unsplash.com/photo-1479064555552-3ef4979f8908?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
//                           alt=""
//                         />
//                       </div>
//                       <div className="ml-3">
//                         <p className="text-gray-900 whitespace-no-wrap">
//                           <a href="#" className="text-blue-500 underline">
//                             Casual
//                           </a>
//                         </p>
//                       </div>
//                     </div>
//                   </td>
//                   <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                     <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
//                       <span
//                         aria-hidden
//                         className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
//                       ></span>
//                       <span className="relative">
//                         <select className="bg-transparent font-semibold">
//                           <option selected>Active</option>
//                         </select>
//                       </span>
//                     </span>
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//             <div className="px-5 py-5 bg-white border-t flex flex-row xs:flex-row items-center xs:justify-between">
//               <span className="text-xs xs:text-sm text-gray-900">
//                 Showing 1 to 10 of 10000 Entries
//               </span>
//               <div className="inline-flex mt-2 xs:mt-0 ml-auto">
//                 <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
//                   Prev
//                 </button>
//                 <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
//                   Next
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// Categories.propTypes = {
//   /**
//    *
//    */
// };

// export default Categories;
