import { gql } from "graphql-request";

export default gql`
  query CustomersList(
    $take: Int!
    $skip: Int!
    $search: String
    $isActive: String
    $isVerified: String
  ) {
    customers(
      args: {
        pagination: { take: $take, skip: $skip }
        search: {
          firstName: $search
          lastName: $search
          email: $search
          mobileCountryCode: $search
          mobileNumber: $search
        }
        sort: { firstName: 1, lastName: 1 }
        filter: { isActive: $isActive, isVerified: $isVerified }
      }
    ) {
      data {
        id
        firstName
        lastName
        email
        mobileCountryCode
        mobileNumber
        isVerified
        pushNotificationsEnabled
        isActive
        addresses {
          area {
            name
          }
          country {
            name
          }
          name
          block
          buildingNumber
          floorNumber
          postalCode
          streetNumber
          addressLine_1
          addressLine_2
          apartmentNumber
          otherInstructions
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        search
        filter
        sort
      }
    }
  }
`;
