import { gql } from "graphql-request";

export default gql`
  mutation UpdateUser(
    $id: String!
    $name: String!
    $email: String!
    $password: String
    $roleId: String!
    $isActive: Boolean!
  ) {
    updateUser(
      userId: $id
      input: {
        name: $name
        email: $email
        password: $password
        roleId: $roleId
        isActive: $isActive
      }
    ) {
        id
        name
        email
        isActive
        role {
            id
            name
        }
    }
  }
`;
