import { gql } from "graphql-request";

export default gql`
  mutation UpdateHomeSliderImage(
    $id: String!
    $mediaId: String!
    $order: Int!
    $isActive: Boolean!
  ) {
    updateHomeSliderImage(
      homeSliderImageId: $id
      input: {
        mediaId: $mediaId
        order: $order
        isActive: $isActive
      }
    ) {
      id
      media {
        id
        name
        path
      }
    }
  }
`;
