import { gql } from "graphql-request";

export default gql`
  query SingleOrder($id: String!) {
    order(orderId: $id) {
      id
      number
      subtotal
      createdAt
      discount
      deliveryFees
      total
      currency
      exchangeRate
      status
      payments {
        transactionId
        referenceNumber
        success
      }
      orderAddress {
        name
        country {
          id
          name
          abbr
        }
        area {
          id
          name
        }
        streetNumber
        postalCode
        addressLine_1
        addressLine_2
        apartmentNumber
        floorNumber
        buildingNumber
        otherInstructions
        block
      }
      customer {
        id
        firstName
        lastName
        email
        mobileNumber
        mobileCountryCode
      }
      orderProducts {
        price
        quantity
        productMeta {
          nameEn
          nameAr
          descriptionEn
        }
        productVariations {
          nameEn
          valueEn
        }
        media {
          path
        }
      }
    }
  }
`;
