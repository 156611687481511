// React
import React, { useImperativeHandle } from "react";
import PropTypes from "prop-types";
import createClassName from "utils/createClassName";

// UI KIT
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
// Globals

// Helpers

// Components
import Input from "components/form/InputField";
import Button from "components/form/Button";

// Factories

// Screens

// Assets

// Third Parties
import { useFieldArray } from "react-hook-form";

// Styles

// Ad-Hoc Components

const Subform = React.forwardRef(
  (
    { name, control, register, errors, labelPlural, labelSingle, allowDelete },
    ref
  ) => {
    const { fields, append, remove } = useFieldArray({
      control,
      name,
    });

    useImperativeHandle(ref, () => {
      return {
        appendRecords(records) {
          append(
            records.map((record) => ({
              id: record.id,
              valueAr: record.valueAr,
              valueEn: record.valueEn,
            }))
          );
        },
        removeAll: async () => {
          await remove();
        },
      };
    });

    return (
      <div>
        <div className="group bg-gray-100 flex justify-between px-4 py-3 items-center text-gray-500 transition ease duration-500 cursor-pointer pr-10 relative">
          <div className="uppercase text-gray-600 group-focus:text-gray-900 transition ease duration-500">
            {labelPlural}
          </div>
        </div>
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="flex flex-row w-full p-6 mt-5 px-6 text-gray-800 shadow-md pin-r pin-y"
          >
            <input
              type="hidden"
              {...register(`${name}.${index}.id`)}
              value={field.id}
            />
            <Input
              className="flex-1 pr-10"
              name={`${name}.${index}.valueEn`}
              label={`${labelSingle} Name (EN)`}
              placeholder={`${labelSingle} Name (EN)`}
              {...register(`${name}.${index}.valueEn`, {
                required: `${labelSingle} english name is required!`,
              })}
              errors={errors}
              // disabled={isLoading}
            />
            <Input
              className="flex-1 pr-10"
              name={`${name}.${index}.valueAr`}
              label={`${labelSingle} Name (AR)`}
              placeholder={`${labelSingle} Name (AR)`}
              {...register(`${name}.${index}.valueAr`, {
                required: `${labelSingle} arabic name is required!`,
              })}
              errors={errors}
              // disabled={isLoading}
            />
            {allowDelete && (
              <Button
                color="pt-5 text-pink-500 hover:text-pink-700"
                basic
                bold
                PrefixIcon={DeleteOutlined}
                onClick={async () => {
                  await remove(index);
                }}
              />
            )}
          </div>
        ))}
        <Button
          text={`Add ${labelSingle}`}
          className="py-5"
          color="ml-auto text-pink-500 hover:text-pink-700"
          basic
          bold
          PrefixIcon={PlusOutlined}
          onClick={append}
        />
      </div>
    );
  }
);

export default Subform;
