import { gql } from "graphql-request";

export default gql`
  query SingleUser(
    $id: String!
  ) {
    user(
      userId: $id
    ) {
        id
        name
        email
        isActive
        role {
            id
            name
        }
    }
  }
`;
