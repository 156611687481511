import { gql } from "graphql-request";

export default gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshUserToken(token: $refreshToken) {
      token
      refreshToken
    }
  }
`;
