import { gql } from "graphql-request";

export default gql`
  query GetCategories(
    $take: Int!
    $skip: Int!
    $search: String
    $isActive: String
  ) {
    categories(
      args: {
        pagination: { take: $take, skip: $skip }
        search: { nameEn: $search, nameAr: $search }
        sort: { nameEn: 1 }
        filter: { isActive: $isActive, category: null }
      }
    ) {
      data {
        id
        nameEn
        nameAr
        media {
          id
          path
          name
        }
        isCustomized
        isHot
        isActive
        category {
          id
        }
        subCategories {
          id
          nameEn
          nameAr
          media {
            id
            path
            name
          }
          isCustomized
          isHot
          isActive
        }
      }
      metadata {
        pagination {
          total
          take
          skip
        }
        filter
        sort
        search
      }
    }
  }
`;
