import { gql } from 'graphql-request';

export default gql`
    query SocialList(
        $take: Int!
        $skip: Int!
    ) {
        socials (
            args: {
                pagination: { take: $take, skip: $skip }
            }
        ) {
            data {
                id
                type
                value
            }
            metadata {
                pagination {
                    total
                    take
                    skip
                }
            }
        }
    }
`;