// React
import React from "react";
import PropTypes from "prop-types";
import {
  BarcodeOutlined,
  LockOutlined,
  CheckCircleOutlined,
  UnlockOutlined,
} from "@ant-design/icons";

// UI KIT
import Input from "components/form/InputField";
import Button from "components/form/Button";

// Globals

// Helpers

// Components

// Factories

// Screens
import Layout from "./PageLayout";

// Assets

// Third Parties
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

// Styles

// Ad-Hoc Components

/**
 * @name ResetPassword
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ResetPassword = ({}) => {
  const history = useHistory();
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const goToLogin = () => {
    history.replace("/auth/login");
  };

  // Component Render
  return (
    <Layout heading="Reset Password">
      <form action="#">
        <Input
          name="verificationCode"
          type="text"
          label="Verification Code"
          placeholder="Verification Code"
          className="mb-6"
          PrefixIcon={BarcodeOutlined}
        />
        <div className="flex items-center mb-6 -mt-4">
          <p className="text-xs">
            Please check your inbox for the verification code.
          </p>
        </div>

        <Input
          name="password"
          type="password"
          label="New Password"
          placeholder="New Password"
          className="mb-6"
          PrefixIcon={LockOutlined}
        />

        <Input
          name="confirmPassword"
          type="password"
          label="Confirm New Password"
          placeholder="Confirm New Password"
          className="mb-6"
          PrefixIcon={LockOutlined}
        />

        <Button
          text="Submit"
          className="w-full"
          color="text-white bg-pink-600 hover:bg-pink-700"
          SuffixIcon={CheckCircleOutlined}
        />

        <Button
          text="Back To Login"
          color="text-pink-600 hover:text-pink-700"
          basic
          bold
          PrefixIcon={UnlockOutlined}
          onClick={goToLogin}
        />
      </form>
    </Layout>
  );
};

ResetPassword.propTypes = {
  /**
   *
   */
};

export default ResetPassword;
