// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  PlusOutlined,
  LoadingOutlined,
  MehOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
// UI KIT

// Globals

// Helpers
import { useListViewParams } from "utils/useListViewParams";
import { useListViewState } from "utils/useListViewState";
import { useListViewAPI } from "utils/useListViewAPI";

// Components
import ListView from "components/listView/ListView";
import ListViewRow from "components/listView/ListViewRow";
import ListViewCol from "components/listView/ListViewCol";
import ListViewHeader from "components/listView/ListViewHeader";
import ListViewAddButton from "components/listView/ListViewAddButton";
import ListViewContainer from "components/listView/ListViewContainer";

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import CustomersListQuery from "graphql/query/customers/list";

// Styles

// Ad-Hoc Components

/**
 * @name Customers
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Customers = ({ setPageTitle }) => {
  // Theme & Style Hooks
  const history = useHistory();
  // Global State Hooks

  // State Hooks
  const {
    page,
    perPage,
    search,
    filter,
    nextPage,
    prevPage,
    handlePerPageChange,
    handleSearchChange,
    handleStatusChange,
    handleVerifiedChange,
  } = useListViewState({
    filterDefault: { status: "ALL", verified: "ALL" },
  });

  // Effect Hooks
  useEffect(() => setPageTitle("Customers"), []);

  // Other Hooks

  // Event Handlers
  const goToAdd = () => history.push("/portal/customers/new");

  // Other
  const { status, data, error, isFetching, refetch } = useListViewAPI(
    "customers",
    {
      perPage,
      page,
      search,
      filter,
      query: CustomersListQuery,
      queryFilterEvaluation: (filter) => ({
        isActive:
          filter.status === "Active"
            ? "true"
            : filter.status === "Inactive"
            ? "false"
            : null,
        isVerified:
          filter.verified === "Verified"
            ? "true"
            : filter.verified === "Not Verified"
            ? "false"
            : null,
      }),
    }
  );

  const viewData = data?.data;
  const viewMetadata = data?.metadata;

  const {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
  } = useListViewParams(page, perPage, viewMetadata, viewData?.length);

  const headers = [
    { text: "Name" },
    { text: "Email" },
    { text: "Mobile" },
    { text: "Verified" },
    { text: "Status" },
  ];

  const listViewParams = {
    firstRecordIndex,
    lastRecordIndex,
    isFirstPage,
    isLastPage,
    totalRecords,
    headers,
    prevPage,
    nextPage,
  };

  const RowComponent = ({
    id,
    firstName,
    lastName,
    email,
    mobileCountryCode,
    mobileNumber,
    isVerified,
    isActive,
  }) => {
    return (
      <ListViewRow>
        <ListViewCol>
          <div className="flex items-center">
            <p className="text-gray-900 whitespace-no-wrap">
              <Link
                to={`/portal/customers/${id}`}
                className="text-blue-500 underline"
              >
                {firstName} {lastName}
              </Link>
            </p>
          </div>
        </ListViewCol>

        <ListViewCol>
          <div className="flex items-center">
            <p className="text-gray-900 whitespace-no-wrap">{email}</p>
          </div>
        </ListViewCol>

        <ListViewCol>
          <div className="flex items-center">
            <p className="text-gray-900 whitespace-no-wrap">
              {mobileCountryCode}
              {mobileNumber}
            </p>
          </div>
        </ListViewCol>

        <ListViewCol>
          <span
            className={
              "relative inline-block px-3 py-1 font-semibold leading-tight " +
              (isVerified ? "text-green-900" : "text-red-900")
            }
          >
            <span
              aria-hidden
              className={
                "absolute inset-0 opacity-50 rounded-full " +
                (isVerified ? "bg-green-200" : "bg-red-200")
              }
            ></span>
            <span className="relative">
              {isVerified ? "Verified" : "Not Verified"}
            </span>
          </span>
        </ListViewCol>

        <ListViewCol>
          <span
            className={
              "relative inline-block px-3 py-1 font-semibold leading-tight " +
              (isActive ? "text-green-900" : "text-red-900")
            }
          >
            <span
              aria-hidden
              className={
                "absolute inset-0 opacity-50 rounded-full " +
                (isActive ? "bg-green-200" : "bg-red-200")
              }
            ></span>
            <span className="relative">{isActive ? "Active" : "Inactive"}</span>
          </span>
        </ListViewCol>
      </ListViewRow>
    );
  };

  // Component Render
  return (
    <ListViewContainer
      {...{
        isSuccess: status === "success",
        isLoading: status === "loading",
        isError: status === "error",
        refetch,
        header: (
          <>
            <ListViewAddButton onClick={goToAdd}>
              Add Customer
            </ListViewAddButton>

            <ListViewHeader
              {...{
                perPage,
                handlePerPageChange,
                search,
                handleSearchChange,
                filters: [
                  {
                    state: filter.status,
                    handleStateChange: handleStatusChange,
                    values: {
                      ALL: "All Statuses",
                      Active: "Active",
                      Inactive: "Inactive",
                    },
                  },
                  {
                    state: filter.verified,
                    handleStateChange: handleVerifiedChange,
                    values: {
                      ALL: "All Verifications",
                      Verified: "Verified",
                      "Not Verified": "Not Verified",
                    },
                  },
                ],
              }}
            />
          </>
        ),
      }}
    >
      <ListView {...listViewParams}>
        {viewData?.map(
          ({
            id,
            firstName,
            lastName,
            email,
            mobileCountryCode,
            mobileNumber,
            isVerified,
            isActive,
          }) => (
            <RowComponent
              {...{
                id,
                firstName,
                lastName,
                email,
                mobileCountryCode,
                mobileNumber,
                isVerified,
                isActive,
              }}
            />
          )
        )}
      </ListView>
    </ListViewContainer>
  );
};

Customers.propTypes = {
  /**
   *
   */
};

export default Customers;
